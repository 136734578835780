import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-course-overview-standards',
  templateUrl: './course-overview-standards.component.html',
  styleUrls: ['./course-overview-standards.component.css']
})
export class CourseOverviewStandardsComponent implements OnInit {
  @Input() standards: any;
  constructor() { }

  ngOnInit(): void {
  }

}
