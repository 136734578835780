import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-homecarousel',
  templateUrl: './homecarousel.component.html',
  styleUrls: ['./homecarousel.component.css']
})
export class HomecarouselComponent implements OnInit {
  tenantInfo:any = tenantInfo;
  showDialogReg = false;
  token:boolean=false;
  userDetail: any;

  constructor(private userSvc:UserService) { }

  ngOnInit(): void {

    this.userDetail=JSON.parse(localStorage.getItem('profilesummaryData'))
    this.userSvc.getIsLogin().subscribe(data => {
        this.token = data;

    })

    if(localStorage.getItem('token')){
      this.token=true

    }

  }

  
  gotoRegister(){
  }
}
