import { Component, OnInit, Input, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { TenantId } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import intlTelInput from 'intl-tel-input';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],

})
export class EditProfileComponent implements OnInit, AfterViewInit {
  @Input() name: string;
  @ViewChild('sigPad') sigPad;
  @ViewChild('fc') form: any;

  sigPadElement :any;
  context : any;
  isDrawing = false;
  isSigned = false;
  img = ""
  details:any;
  @Input() profileDetails: any;

  studentId = "";
  my = {
    first_name:"",
    last_name:"",
    mobile:"",
    email:"",
    nationality:{
      value:""
    },
    ethnic_origin:{
      value:""
    },
    title:"",
    land_line:"",
    martial_status:"",
    gender:"",
    date_of_birth:"",
    mailing_address:{
      line1:"",
      country:"",
      city:"",
      zip:""
    },
    Signature:"",
    country:"",
    profile_picture:""
  }
  todayDate : any;

  NationalityList = [];
  CountryList = [];
  EthnicOriginList = [];

  DropdownsData = [];

  ChangePassword = {
    CurrentPassword: "",
    NewPassword: "",
    ConfirmPassword: ""
  }
  Signature = "";
  signtureImage = APP_URLS.FILE_PATH_LINODE + 'signature/';
  path: string;
  input: any;
  countryObj = {
    dialCode : ''
  };
  iti: any;
  countryCode = '';

  constructor(public service: MyProfileService, private toastr: ToastrService, private userSvc: UserService, private route: Router) {
    this.path = this.route.url.toString()


  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
 }

  ngOnInit(): void {
    this.details = JSON.parse(JSON.stringify(this.profileDetails));
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id

    if( this.details.gender && this.details.gender == 'Not available' ){
      this.details.gender = '';
    }
    if( this.details.martial_status && this.details.martial_status == 'Not available' ){
      this.details.martial_status = '';
    }

    if( this.details.land_line && this.details.land_line == 'Not available' ){
      this.details.land_line = '';
    }

    if( this.details.nationality && this.details.nationality.value == 'Select' ){
      this.details.nationality.value = '';
    }

    if( this.details.ethnic_origin && this.details.ethnic_origin.value == 'Select' ){
      this.details.ethnic_origin.value = '';
    }

    this.countryCode = this.details?.mobile.substring(
      this.details?.mobile.lastIndexOf("+") + 1,
      this.details?.mobile.lastIndexOf("-") ,
    );

    if(this.countryCode == ''){
      this.countryCode = '44';
      this.details.mobile =  '+' + this.countryCode + '-' + this.details?.mobile

    }

    this.getDropdowns();
  }

  ngAfterViewInit(): void {
    this.input = document.querySelector("#mobile");
    this.iti = intlTelInput(this.input);
  }

  @HostListener("document:countrychange", ["$event"])
	getCountry(e) {
    this.countryObj = this.iti.getSelectedCountryData();
	}

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    this.isDrawing = true;
    this.isSigned = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  clear() {
    if( this.context ){
      this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
      this.context.beginPath();
      this.isSigned = false;
    }
  }

  save() {
    this.img = this.sigPadElement.toDataURL("image/png");
  }

  keyPress(event: any) {
    if (event.keyCode == 32 ) {
      event.preventDefault();
    }
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getDropdowns() {
    let url = APP_URLS.ProfileDropdown;
    this.service.getCall(url).subscribe((res: any) => {
      this.DropdownsData = res.data
      res.data.forEach(x => {
        if (x.type == "Nationality") {
          this.NationalityList.push(x)
        }
        if (x.type == "Country") {
          this.CountryList.push(x)
        }
        if (x.type == "EthnicOrigin") {
          this.EthnicOriginList.push(x)
        }
      })
    })
  }

  dosignature() {
    if( this.isSigned ){
      this.img = this.sigPadElement.toDataURL("image/png");
      if( this.img != 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAABkCAYAAAA8AQ3AAAAC9ElEQVR4Xu3UAQkAAAwCwdm/9HI83BLIOdw5AgQIRAQWySkmAQIEzmB5AgIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIPGZAAGU3BBs5AAAAAElFTkSuQmCC'){
        let url = APP_URLS.DoSignature
        var Payload = {
          signature: this.img
        }
        this.service.postCall(url, Payload).subscribe((res: any) => {
          if (res.respCode) {
            if (res.details) {
              this.Signature = res.details.siganture;
              this.userSvc.showSuccess("Your signature is saved successfully")
            }
            this.update()
          }
          if (res.errorCode) {
            this.userSvc.showError(res.errorMessage)
          }
        })
      } else {
        this.userSvc.showError('Please draw your signature');
      }

    }
  }

  onChangeFunForEthnicOrg( val ){
    for( var i = 0; i < this.EthnicOriginList.length; i++ ){
      if( this.EthnicOriginList[i].value == val ){
        this.details.ethnic_origin.value = this.EthnicOriginList[i].value;
        this.details.ethnic_origin.key = this.EthnicOriginList[i].key;
        break;
      }
    }
  }
  async update() {

    if(this.countryObj?.dialCode !== ''){
      this.countryCode = ( this.countryObj?.dialCode != 'undefined' ) ? this.countryObj?.dialCode : this.countryCode
      console.log(this.countryObj);
    }

    var number = this.details.mobile.substring(
      this.details.mobile.lastIndexOf("-") + 1,
    );

    if(number.length < 5 ||  number.length > 15 ){
      this.userSvc.showError("Mobile number Minimum 5 Numbers and Maximum 15 Numbers ");
      return;
    }

    let url = APP_URLS.profileDetails + this.studentId;
    let Payload = {
      city: this.details.mailing_address.city,
      title:this.details.title,
      country: this.details.country,
      date_of_birth: this.details.date_of_birth,
      email: this.details.email,
      ethnic_origin: this.details.ethnic_origin,
      first_name: this.details.first_name,
      gender: this.details.gender,
      land_line: this.details.land_line,
      last_name: this.details.last_name,
      line1: this.details.billing_address.line1,
      mailing_address: { line1: this.details.mailing_address.line1, city: this.details.mailing_address.city, country: this.details.country, zip: this.details.mailing_address.zip },
      martial_status: this.details.martial_status,
      middle_name: this.details.middle_name,
      mobile: '+' + this.countryCode +'-'+ number,
      nationality: this.details.nationality,
      same: true,
      tenantId: TenantId,
      zip: this.details.mailing_address.zip,
      Signature: this.Signature || this.details.Signature
    }

    if(/^[0-9+-]+$/.test(Payload.mobile) == false){
      this.userSvc.showError("Mobile number is not valid");
				return;
    }

    function validateEmail(email) {
      const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return re.test(email);
    }


    if (Payload.email !== '') {
      if(!validateEmail(Payload.email)){
				this.userSvc.showError("Email is not valid");
				return;
			}
		}

   await this.service.putCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        this.userSvc.showSuccess("Your profile updated successfully")
        this.service.seteditprofile('dummy')
        this.service.setPopup(false);

      }
      if (res.errorCode) {
        this.userSvc.showError(res.errorMessage)
      }

    })
  }

  changePassword(form:any) {

    if (this.ChangePassword.NewPassword == '' ||  this.ChangePassword.CurrentPassword == "" || this.ChangePassword.ConfirmPassword == "") {
      this.userSvc.showError("Please Enter All the Fields")
      return;
    }

    if (this.ChangePassword.NewPassword != this.ChangePassword.ConfirmPassword) {
      this.userSvc.showError("Your new password and confirmation password should match")
      return;
    }
if(this.ChangePassword.NewPassword !="" && this.ChangePassword.ConfirmPassword !="" && this.ChangePassword.CurrentPassword!=""){
    let url = APP_URLS.v1.changePassword;
    let Payload =
    {
      currentPassword: this.ChangePassword.CurrentPassword,
      newPassword: this.ChangePassword.NewPassword,
      tenantId: TenantId,
      text: "Change Password",
      verifyPassword: this.ChangePassword.ConfirmPassword
    }
    this.service.putCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        this.userSvc.showSuccess(res.respMessage);
        this.ChangePassword.NewPassword = ""
        this.ChangePassword.CurrentPassword = ""
        this.ChangePassword.ConfirmPassword = ""
        this.service.seteditprofile("dummy");
        this.service.setPopup(false);
        form.resetForm();
      }
      if (res.errorCode) {
        this.userSvc.showError(res.respMessage)
      }
    })
  }

  }
  next(){
    this.update()
    setTimeout(()=>{
      this.service.setTab({ tabName : "Qualification", isRefreshPermission : false })
      this.service.setPopup(false);
     }, 3000);

  }

  stopUserInput(){
    return false;
  }

  clearTab(type){
    if( type == 'password' ){
      this.form.resetForm();
    } else if( type == 'signature' ){
      if( ! this.Signature )
        this.clear();
    }
    this.details = JSON.parse(JSON.stringify(this.profileDetails));
  }
}
