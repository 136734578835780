import { Component, OnInit, ViewChild } from '@angular/core';
import { APP_URLS } from 'src/app/Models/url';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TenantId } from '../../Models/url';
@Component({
  selector: 'app-contact-register',
  templateUrl: './contact-register.component.html',
  styleUrls: ['./contact-register.component.css']
})
export class ContactRegisterComponent implements OnInit {

  @ViewChild('f') form: any;

  contact = {
    email: '',
    name: '',
    subject: '',
    message: ''
  }
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/;

  constructor(
    private userSvc: UserService,
    private toastr: ToastrService,
    private cms: CommonService,) { }

  ngOnInit(): void {
  }

  contactus() {
    let url = APP_URLS.contactus
    let payload = {
      email: this.contact.email,
      first_name: this.contact.name,
      message: this.contact.message,
      subject: this.contact.subject,
      tenantId: TenantId,
      from_place: 'contact' // static
    }

    this.userSvc.postCall(url, payload).subscribe(
      (res: any) => {
        if (res.respCode == 201) {
          // this.cms.loaderSet(false);
          this.userSvc.showSuccess(res.respMessage);
          this.form.resetForm();
        }
        else {
          // this.cms.loaderSet(false);
          this.userSvc.showError(res.respMessage)
        }
      },
      (error) => {
        // this.cms.loaderSet(false);
        this.userSvc.showError('contact Failed')
      })

  }

}
