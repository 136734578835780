import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.css']
})
export class FeeComponent implements OnInit {

  studentId = '';
  feefinance:any;
  subitBtnDisable : boolean = true;

  listOptions = [
    { value: "Student loans company", name: "Student loans company" },
    { value: "Self-funded", name: "Self-funded" },
    { value: "Employer/Company funded", name: "Employer/Company funded" }
  ];


  constructor(public service: MyProfileService, private toastr: ToastrService, private userSvc: UserService) { }

  ngOnInit(): void {
    this.service.setTab({ tabName : "Fee", isRefreshPermission : false })
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id
    this.getData();
  }

  getData() {
    // debugger
    let url = APP_URLS.getstudentfeefinance + this.studentId;
    this.service.getCall(url).subscribe((res: any) => {
      if(res.details!=null && res.details.feeOfDetails!=null){
      this.feefinance = res.details.feeOfDetails
      }else{
        this.feefinance = "Select"
      }
    })
  }

  onChangeEvent(){
    if( this.feefinance && this.feefinance != 'Select' ){
      this.subitBtnDisable = false;
    }
  }

  async save( action : string ) {
    let url = APP_URLS.student_addOrUpdatestudentfeefinance + this.studentId;
    let payload = {
      updateData: {
        students: this.studentId,
        feeOfDetails: this.feefinance
      }
    }
   await this.service.putCall(url, payload).subscribe((res: any) => {
      if(res.respCode) {
        this.userSvc.showSuccess(res.respMessage)
        if( action == 'save')
          this.service.setTab({ tabName : "Checklist", isRefreshPermission : true })
      }
      if(res.errorCode){
        this.userSvc.showError('Try Again')
      }


    })
  }

  next(){
    this.save('saveandnext')
    this.service.setTab({ tabName : "Checklist", isRefreshPermission : true })
    this.service.setPopup(false);  
  }

}
