import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TenantId } from '../Models/url';
@Injectable({
  providedIn: 'root'
})
export class CertificatesService {


  token = "";



  config = {
    base_url: "http://localhost:3700/",
    host: "localhost",
    serve: "172.104.186.153",
    port: 3000,
    serverUrlCors: "http://localhost:3700/cors/",
    serverUrl: "http://localhost:3000/api/",
    serverUrlhttps: "http://localhost:3000/api/",
    socialUrl: "http://live.test.co.uk/api/",
    imagePathOld: "http://172.104.186.153/tenantServer/modules/upload",
    imagePath: "http://localhost:3000",
    tenantConfig:
    {
      tenantId: TenantId,
      url: "http://54.147.203.20:3001/#/",
      company: "ELearning tool",
      logo: "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"
    }
  }
  constructor(private http: HttpClient,private toastr: ToastrService,) { 
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
  }

  showSuccess(message) {
    this.toastr.success(message)
  }

  showError(message) {
    this.toastr.error(message)
  }

  showWarning(message){
    this.toastr.warning(message)
  }
  getCall(url) {
    return this.http.get(url)
  }

  getCallwithHeader(url) {
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "'+TenantId+'","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }
    return this.http.get(url, {headers})
  }



  postCall(url,data){
    return this.http.post(url,data);
  }
  putCall(url,data){
    return this.http.put(url,data);
  }

  postCallwithHeader(url, payload) {
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "'+TenantId+'","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }
    return this.http.post(url, payload,{headers})
  }

  putCallwithHeader(url,data){
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "'+TenantId+'","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }
    return this.http.put(url,data,{headers})
  }

}
