import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutcarousel',
  templateUrl: './aboutcarousel.component.html',
  styleUrls: ['./aboutcarousel.component.css']
})
export class AboutcarouselComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
