import { Component, OnInit,Output, OnDestroy,ViewChild,ElementRef,EventEmitter  } from '@angular/core';

import { APP_URLS } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { FilterPipe } from '../../Models/filter.pipe';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-emp-my-task',
  templateUrl: './emp-my-task.component.html',
  styleUrls: ['./emp-my-task.component.css'],
  providers:[FilterPipe]
})
export class EmpMyTaskComponent implements OnInit {

  isLoading = false;
  taskDetails = [];
  singletaskDetail: any;
  isTaskInfo : boolean = false;
  taskPopupOpen = 'none';
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;

  totalTask = 0;
  page = 1;
  countPagination = 25;

  dateFilter = '';
  allfieldsearch = '';

  constructor( public service: MyProfileService, private FilterSearch : FilterPipe, private sanitizer: DomSanitizer  ) { }

  ngOnInit(): void {
    this.getTaskDetails()
  }

  changePage(e){
    if(!e){
      e = 1;
    }
    this.page = e;
    this.getTaskDetails();
  }

  dateChange(e: any){
    this.page= 1;
    this.countPagination = 25
    this.dateFilter = e.target.value;
    this.getTaskDetails();
  }

  searchKey(e: any): void{
    this.page= 1;
    this.countPagination = 25
    this.allfieldsearch = e.target.value;
    this.getTaskDetails();
  }

  getTaskDetails() {
    this.taskDetails = [];
    this.isLoading = true;
    const filter = JSON.stringify({
      page: this.page,
      limit: this.countPagination
    })
    let url = APP_URLS.v1.myTaskStudent + `?filter=${filter}` + '&selectDateFilter='+ this.dateFilter + '&allfieldsearch='+this.allfieldsearch;
    this.service.getCallWithHeaders(url)
    .subscribe((res: any) => {
      this.taskDetails = res.res;

      this.taskDetails.forEach((y, i) => {
        y['taskIndex'] = i + 1;
        y['description_html'] = this.sanitizer.bypassSecurityTrustHtml(y.description)
      })

      this.totalTask = ( this.page == 1 ) ? res?.pagination.totalRecords : this.totalTask;
      this.isLoading = false;
    },err=>{
    })
  }

  taskViewOpen(data: any){
    this.taskPopupOpen = 'block';
    this.singletaskDetail = data;
  }

  taskPopupClose(): void {
    this.taskPopupOpen = 'none';
    this.singletaskDetail = null;
}
}
