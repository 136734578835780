import {
	Component,
	OnInit,
	Output,
	OnDestroy,
	ViewChild,
	ElementRef,
	EventEmitter,
} from "@angular/core";

import { APP_URLS } from "../../Models/url";
import { MyProfileService } from "../../services/my-profile.service";
import { UserService } from "../../services/user.service";
import { FilterPipe } from "../../Models/filter.pipe";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
	selector: "app-emp-my-project",
	templateUrl: "./employer-my-project.component.html",
	styleUrls: ["./employer-my-project.component.css"],
	providers: [FilterPipe],
})
export class EmpMyProjectComponent implements OnInit {
	isLoading = false;
	projectDetails = [];
	singleProjectDetail: any;
	isProjectInfo: boolean = false;
	projectPopupOpen = "none";
	fileSize: any;
	fileName: any;
	FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;

	totalItem = 0;
	page = 1;
	countPagination = 10;

	constructor(
		public service: MyProfileService,
		private FilterSearch: FilterPipe,
		private toast: UserService,
		private sanitizer: DomSanitizer
	) {}

	ngOnInit(): void {
		this.getProjectDetails();
	}

	changePage(e) {
		if (!e) {
			e = 1;
		}
		this.page = e;
		this.getProjectDetails();
	}

	getProjectDetails() {
		this.isLoading = true;
		this.projectDetails = [];
		let url =
			APP_URLS.v1.myProjects +
			'?filter={"page":' +
			this.page +
			',"limit":' +
			this.countPagination +
			"}";
		this.service.getCallWithHeaders(url).subscribe(
			(res: any) => {
				this.projectDetails = res.findRes;
				this.projectDetails.forEach((y, i) => {
					var desc = "";
					desc = y.projectDescription.replace(/<[^>]*>?/gm, "");
					y["projectDescription_text"] = desc.replace(/&nbsp;/g, " ");
					y[
						"projectDescription_html"
					] = this.sanitizer.bypassSecurityTrustHtml(
						y.projectDescription
					);
				});

        this.totalItem = ( this.page == 1 ) ? res?.pagination.totalRecords : this.totalItem;
        this.isLoading = false;
			},
			(err) => {}
		);
	}

	projectViewOpen(data: any) {
		this.projectPopupOpen = "block";
		this.fileName = "";
		this.singleProjectDetail = data;
	}

	projectPopupClose(): void {
		this.projectPopupOpen = "none";
		this.singleProjectDetail = {};
	}
}
