import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-additional-documents',
  templateUrl: './additional-documents.component.html',
  styleUrls: ['./additional-documents.component.css']
})
export class AdditionalDocumentsComponent implements OnInit {
  p = 1;
  countPagination = 10;
  showDialogDoc:boolean=false;
  studentId: any;
  additional=[];
  arrayData=[];
  isLoading:boolean = true;
  isNoAddDoc:boolean = false;
  constructor(private profileSvc: MyProfileService,private toast:UserService) { }
  FILE_PATH_LINODE=APP_URLS.FILE_PATH_LINODE
  
  ngOnInit(): void {
    this.profileSvc.setTab({ tabName : "Additional", isRefreshPermission : false })
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.getEmpHistory();
  }

  changePage(e){
    if(!e){
      e=1
    }
    this.p=e;
  }
  
  parentMethod(data){
    if(data){
      this.additional=[];
      this.isNoAddDoc = false;
      this.showDialogDoc = false;
      this.getEmpHistory();
    }
  }

  getEmpHistory(){
    this.isLoading = true;
    let url = APP_URLS.getQualificationandEmployementandPersonl+`{"key":"attributes.context_id","value":"`+this.studentId+`","type":"eq"}]}`
    this.profileSvc.getCall(url)
    .subscribe((data:any)=>{
      this.arrayData=data.events;
      this.arrayData.forEach(y => {
        if(y.attributes.formname=="additionaldocuments"){
          this.additional.push(y.attributes)
        }
      });
      this.isLoading = false;
      this.isNoAddDoc = this.additional.length ? false : true;
      
    })
  }

  download(ziplink){
  	let link = APP_URLS.imagePath+ziplink;
  }
}
