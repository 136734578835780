import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service'
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { TenantId } from '../../Models/url';
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {

  ID: string;
  AllChapters=[];
  chaptersData=[];
  Chapters=[];
  quizdata = [];
  FinalChaptersArray=[];
  parentAccordion: any;
  selectedexam: any;
  isNoQuizData:boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc:MyProfileService,
    private cms: CommonService,
   ) {
    this.ID =localStorage.getItem('courseId')
  }

  ngOnInit(): void {
    this.getquizData();
  }

  async getquizData() {

    let url = APP_URLS.newchapters + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"}]}'
    await this.service.getCallwithHeader(url).subscribe(
      (res: any) => {
      this.parentAccordion = res.independent[0].name
      let data = res.independent[0].child[0].quiz
      data.forEach((x,i)=>{
        x['accordion'] = '#quizaccordion' + i
        x['accordionID'] = 'quizaccordion' + i
        this.quizdata.push(x);
      })
      this.isNoQuizData = ( this.quizdata.length ) ? false : true;
    })
  }
  openTest(data){
    this.selectedexam  = data
    this.service.setopenexam(data);
  }
}
