import { Component, OnInit, Input } from '@angular/core';
import { APP_URLS } from '../../../Models/url';
import { CourseService } from '../../../services/course.service';
import { UserService } from '../../../services/user.service';
import { CommonService } from '../../../services/common.service'
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-course-feedback-model',
  templateUrl: './course-feedback-model.component.html',
  styleUrls: ['./course-feedback-model.component.css']
})
export class CourseFeedbackModelComponent implements OnInit {
  ID: any;
  studentId: any
  rateoptions = [];
  queries = []
  courserating: number[] = [1, 2, 3, 4, 5];
  staffrating: number[] = [1, 2, 3, 4, 5];
  feedback_0: number[] = [1, 2, 3, 4, 5];
  queriesresponse = {
    aboutcourse: "",
    aboutstaff: "",
    courserating: "",
    staffrating: "",
  }
  isfeedbackDetail = [];

  feedback:any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private cms: CommonService,
  ) { 
    this.service.getFeedback().subscribe(data=>{
      if(data){
        this.feedback = data
this.ngOnInit()
      }
      
    })
  }

  ngOnInit(): void {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.ID = localStorage.getItem('courseId')
    if (this.feedback) {
      this.feedback.isfeedback_det.forEach(x => {
        this.rateoptions.push(x.rateoptions)
        this.queries.push(x.queries)
        this.isfeedbackDetail.push(x.isfeedbackDetail)
      });
    }
      if (this.isfeedbackDetail.length > 0) {
        this.isfeedbackDetail[0].forEach((x) => {
          if (x.queriesresponse.length > 0) {
            x.queriesresponse.forEach((y, index) => {
              this.queriesresponse.aboutcourse = y.aboutcourse
              this.queriesresponse.aboutstaff = y.aboutstaff
              this.queriesresponse.courserating = y.courserating
              this.queriesresponse.staffrating = y.staffrating
              if(this.queries[0].length>0){
                this.queries[0].forEach((z, i) => {
                    let value="feedback_"+i
                     Object.keys(y).filter(k=>{
                       if(k==value){
                        z['feedback'] = y[k]
                       }
                     }
                     );
                })
              }
             
            })
            }           
        });
    }
  }
 
  feedBackStar(star, index) {
    this.queriesresponse["feedback_" + index] = star;
    if(this.queries.length>0){
      this.queries[0].forEach((x, i) => {
        if (index == i) {
          x['feedback'] = star
        }
      })
    }
  }

  staffStar(star) {
    this.queriesresponse.staffrating = star;

  }
  courseStar(star) {
    this.queriesresponse.courserating = star;

  }
  submitfeedback() {
        
    // this.cms.loaderSet(true);
    var payload = {
      student_id: this.studentId,
      feedback_id: this.feedback.isfeedback_det[0]._id,
      assigned_courses: this.ID,
      assigned_chapter: this.feedback._id,
      parentuuid: this.feedback.parent,
      queriesresponse: this.queriesresponse
    }
    let url = APP_URLS.submitFeedbackModel 
    this.service.postCallwithHeaders(url, payload)
      .subscribe((res: any) => {
        // this.cms.loaderSet(false);
        if(res.respCode== 201){
          this.userSvc.showSuccess(res.respMessage)
        }
        else{
          this.userSvc.showError(res.respMessage)
        }       
      },
      err => {                       
        // this.cms.loaderSet(false);
        this.userSvc.showError('Failed')
      })
  }

  






}
