import { Component, OnInit ,ViewChild,ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../../Models/url';
import { CourseService } from '../../../services/course.service';
import { UserService } from '../../../services/user.service';
import { MyProfileService } from '../../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-personal-statement-modal',
  templateUrl: './add-personal-statement-modal.component.html',
  styleUrls: ['./add-personal-statement-modal.component.css']
})
export class AddPersonalStatementModalComponent implements OnInit {
  @ViewChild('takeInputPer', {static: false})
  InputVar: ElementRef; 
  
  waitingFlag:boolean=false
 
  fileToUpload: any;
  fileName: any;
  fileSize: any;
  studentId: any;
  personalStatment={
    description : "",
    is_sop:false
  }
  description = "";

  is_sop: Boolean = false;
  PersonalStatements = [];

  constructor(private profileSvc: MyProfileService, private toast: UserService) { }

  ngOnInit(): void {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id
    this.PersonalStatements=[];
    this.getProfileDetails();
    this.profileSvc.subClearAction().subscribe((status:any)=>{
      if( this.InputVar )
        this.InputVar.nativeElement.value = ""; 
      this.fileName = "";
      this.personalStatment.description = ""
    })
  }
 
  reset(){ 
    if( this.InputVar )
      this.InputVar.nativeElement.value = ""; 
  } 

  isUploadDoc(){
    if( this.personalStatment.is_sop ){
      this.personalStatment.description = '';
    } else {
      this.fileName = '';
    }
  }

  handleFileInput(files) {
    this.waitingFlag = true
    this.fileSize = files[0].size
    const formData = new FormData();
    formData.append('images', files[0]);
    var url = APP_URLS.fileUpload + '&frmSrc=per_stmnt'
    this.profileSvc.postCallImg(url, formData)
      .subscribe((data: any) => {
        this.waitingFlag = false;
        if(data.errorMessage){
          this.toast.showError(data.errorMessage)
        } else if(data.message){
          this.fileName=""
          this.toast.showError(data.message)
        } else {
          if( data.length )
            this.fileName = data[0].name
          this.toast.showSuccess('Your chosen file is attached successfully. Now click on add or add & next button')
        }       
       
      }, err => {
        this.waitingFlag=false
        this.fileName=""
        this.toast.showError(err.errorMessage)
      })
  }

  payload: any;

  async add() {

    let url = APP_URLS.profileDetails + this.studentId;

    if (this.personalStatment.is_sop == true) {
      if(this.fileName == undefined || this.fileName == ""){
        this.toast.showError('Please select proper Document');
        return;
      }
      this.payload = {
        personal_statements: [
          {
            created_from: "Website",
            description: "",
            is_sop: this.personalStatment.is_sop,
            operation: 0,
            sop_attachment: this.fileName,
            statement: "SD"
          }
        ]
      };
    }

    else if (this.personalStatment.is_sop == false) {
      if(this.personalStatment.description == ""){
        this.toast.showError('Please Enter Description');
        return;
      }
      this.payload = {
        personal_statements: [
          {
            created_from: "Website",
            description: this.personalStatment.description,
            operation: 0,
            statement: "SD"
          }
        ]
      };
    }
 
   await this.profileSvc.putCall(url, this.payload)
      .subscribe((data: any) => {
        if (data.respCode) {
          if (this.personalStatment.is_sop == false) {
            this.toast.showSuccess('Your personal statement is added succcessfully');
          } else {
            this.toast.showSuccess('Your attached file is uploaded successfully');
          }
          
          this.profileSvc.setTab({ tabName : "Personal", isRefreshPermission : true })
          this.personalStatment.description="";
          this.fileName=""
          this.getProfileDetails();
          this.profileSvc.setPopup(false); 
          this.reset();
        }
        if (data.errorCode) {
          
          this.toast.showError('Try Again')
          this.profileSvc.setPopup(false);
        }

      }, err => {
        this.toast.showError(err.errorMessage)
      })
  }

  getProfileDetails() {
    let url = APP_URLS.profileDetails+this.studentId;
   
    this.profileSvc.getCall(url).subscribe((res: any) => {
      this.PersonalStatements = res.details.personal_statements 
    })
  }

  next(){
    // this.toast.showSuccess('upload successful');
    this.add()
    setTimeout(()=>{       
      this.profileSvc.setTab({ tabName : "Additional", isRefreshPermission : false })
      // this.profileSvc.setPopup(false);  
     }, 3000);
    // this.profileSvc.setTab("Additional")
    // this.profileSvc.setPopup(false);  
  }
}
