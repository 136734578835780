import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';
declare let $: any;
@Component({
  selector: 'app-home-cyber-security',
  templateUrl: './home-cyber-security.component.html',
  styleUrls: ['./home-cyber-security.component.css']
})
export class HomeCyberSecurityComponent implements OnInit {

  tenantInfo: any = tenantInfo;

  constructor() { }

  ngOnInit(): void {
    $('.text-owl-slide').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      autoplay: false,
      responsive: {
          0: {
              items: 1
          },
          450: {
              items: 1
          },
          600: {
              items: 1
          },
          1000: {
              items: 1
          }
      }

  });
  $( function () {
    $( ".text-owl-slide .owl-next" ).addClass( "fas fa-chevron-right" );
    $( ".text-owl-slide .owl-prev" ).addClass( "fas fa-chevron-left" );
} );
  }

}
