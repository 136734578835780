import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service'
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { TenantId } from '../../Models/url';
@Component({
  selector: 'app-course-download',
  templateUrl: './course-download.component.html',
  styleUrls: ['./course-download.component.css']
})
export class CourseDownloadComponent implements OnInit {
  ID: string;
  AllChapters=[];
  chaptersData=[];

  constructor(private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc:MyProfileService,
    private cms: CommonService,
   ) {
    this.ID =localStorage.getItem('courseId')
    
  }

  ngOnInit(): void {
    this.getDataDownload();
  }
  getDataDownload() {
      let url = APP_URLS.chapters+ '{"sortfield":"sort_position","direction":"asc","criteria":[{"key":"course","value":"'+this.ID+'","type":"eq"},{"key":"tenantId","value":"'+TenantId+'","type":"eq"}]}';
      this.userSvc.getCall(url)
      .subscribe((res: any) => {
       
         this.AllChapters = res.chapters;
         this.chaptersData=[]
         if(this.AllChapters && this.AllChapters.length > 0){
         }
      
    })
  
  }
}
