import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { TrainingService } from '../../services/training.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-all-trainings',
  templateUrl: './all-trainings.component.html',
  styleUrls: ['./all-trainings.component.css']
})
export class AllTrainingsComponent implements OnInit {
  FavouriteTrainingsList=[]
  AppliedTrainingList=[]
  AllTrainingsList=[]
  studentId:any
  constructor(public service: TrainingService,
    private router: Router,
    private route: ActivatedRoute) { 

    }


   ngOnInit(){
     
    this.studentId=JSON.parse(localStorage.getItem('userDetails'))._id
    this.getTrainingList()
    this.getAppliedTrainingList();
    
  }
  async getTrainingList () {
    let url = APP_URLS.FavourateTraining;
   await this.service.getCallWithHeaders(url).subscribe((res: any) => {
      res.favorites.forEach(x=>{
        if(x.course.applied == false){
          this.FavouriteTrainingsList.push(x)
        }
      })
      
    })
    
  }
  async getAppliedTrainingList() {
    let url = APP_URLS.AppliedTraining+'{"id":'+`"`+this.studentId+`"`+',"type":"student"}'
   await this.service.getCallWithHeaders(url).subscribe((res: any) => {
      this.AppliedTrainingList = res.details
      this.AllTrainingsList = this.FavouriteTrainingsList.concat(this.AppliedTrainingList);
     
    })
  }

  openCourse(Training){
    localStorage.setItem('courseId',Training.course._id)
    localStorage.setItem('TrainingName',Training.course.name)
  this.router.navigate(['/Mycourses/',Training.course._id])
  }


}
