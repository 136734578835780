import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service'
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { MyProfileService } from '../../services/my-profile.service';
import { TenantId } from '../../Models/url';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-aboutcourse',
  templateUrl: './aboutcourse.component.html',
  styleUrls: ['./aboutcourse.component.css']
})
export class AboutcourseComponent implements OnInit {
  studentId: any;
  ID: string;
  testCourse: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc:MyProfileService,
    private cms: CommonService,
    private sanitizer: DomSanitizer
   ) {
    this.ID =localStorage.getItem('courseId')

  }

  ngOnInit(): void {
     this.studentId= JSON.parse(localStorage.getItem('userDetails'))._id;
   
    this.aboutCourse();
  }
  aboutCourse(){
    // this.cms.loaderSet(true); 
    let url = APP_URLS.aboutCourse+ '[{"key":"tenantId","value":"'+TenantId+'","type":"eq"},{"key":"_id","value":"'+this.ID+'","type":"eq"}]}';
    
   
    this.service.getCallwithHeader(url).subscribe(
      (res: any) => {
        // this.cms.loaderSet(false); 
       
       this.testCourse=res.courses
      
       this.testCourse.forEach(x=>{
         x['Dynamichtml'] = this.sanitizer.bypassSecurityTrustHtml(x.aboutcourse);
       })
      
      },
      err => { 
        // this.cms.loaderSet(false);                
      })
  }
}
