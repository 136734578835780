import { Component, OnInit, OnDestroy, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service'
import { TenantId } from '../../Models/url';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css']
})
export class SetpasswordComponent implements OnInit {
  selectedId = "";
  pwdKey: any;
  studentId: any;
  reset={
    newpwd:"",
    verifypwd:""
  }
  constructor(private router: Router,
    
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,) { }
 
  ngOnInit() {
  
    this.route.paramMap.subscribe(
      params => {
        this.selectedId = params.get('id');
   
      }
    )
    this.route.queryParams
      .subscribe(params => {
    
        this.pwdKey = params.k
      }
      );
  }

  setPassword() {
    if(this.reset.newpwd!=this.reset.verifypwd){
      this.userSvc.showError('Password and Confirm Password Should Match');
      return;
    }
    else{

let url = APP_URLS.resetPassword +this.selectedId 
    let payload = {
      forgotpw_key: this.pwdKey ,
      id: this.selectedId ,
      newPassword: this.reset.newpwd,
      verifyPassword: this.reset.verifypwd,
      tenantId: `"`+TenantId+`"`,

    }

    this.userSvc.putCall(url,payload)
    .subscribe((res:any)=>{
   
      
       this.userSvc.showSuccess(res.respMessage);
       this.router.navigate(['/home'])
    },err=>{
   
       this.userSvc.showError(err.message)
    })
    
    
    }
    
  }

  
  

}
