import { Component, OnInit, Input, ViewChild, HostListener, APP_ID, ElementRef } from '@angular/core';
import { APP_URLS } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from 'src/app/services/user.service';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { TenantId } from '../../Models/url';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import * as jspdf from 'jspdf';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { ProfileDetails } from 'src/app/Models/general.model';
declare let $: any;

@Component({
  selector: 'app-employer-action-center',
  templateUrl: './employer-action-center.component.html',
  styleUrls: ['./employer-action-center.component.css']
})
export class EmployerActionCenterComponent implements OnInit {

  @ViewChild('content') content: ElementRef;
  @ViewChild('content2') content2: ElementRef;
  agreementDocumentDeatils = [];
  isNoAgreement: boolean = false; // use to enable no agreement message
  isShow: Boolean = false;
  isShowError: Boolean = false;

  @ViewChild('sigPad') sigPad;

  signtureImage=APP_URLS.imagePath+'signature/'
  sigPadElement: any;
  context: any;
  isDrawing = false;
  img = "";
  addSign = "";

  agreementDeatils = {
    isClicked: false,
    isSignPending: null,
    signRequestedDate: "",
    AgreementDate: "",
    signedDate: null,
    students: "",
    tenantLogo: "",
    _id: "",
    EmployerName: "",
  };

  AddMilestonesobj = {
    Milestone: []
  };
  RolesAndResponcebilitiesobj = {
    RolesAndResponcebilities: []
  };
  RecommendedDurationobj = {
    RecommendedDuration: []
  };
  TotalNegotiatedPriceObj = {
    totalNegotiatedPrice: [],
    students: ""
  };

  profileDetails: ProfileDetails

  evidencetoSupportAPLobj = {
    AttachEvidence: "",
    DateOfSignature: "",
    HowHaveYouEvidencedAPL: "",
    LevelAPL: "",
    NameofDirector: "",
    NameofStandardFramework: "",
    Signature: "",
    TNPminusAPL: "",
    ValidationAndApprovedBy: "",
    WhereThisEvidencekept: "",
    calculationsToReduceStandardFramework: "",
    students: "",
    _id: ""
  };

  StandardWeeklyWorkHoursobj = {
    StandardWeeklyWorkHours: [],
    students: ""
  }

  signaturepopupopen = 'none'

  all_ConsentsObj = {
    LearningOpportunities: Boolean,
    PreferredMethodsOfContact: "",
    PermissionForABA: Boolean,
    HE_TermsAndConditions: Boolean,
    DisciplinaryMatters: Boolean,
    AcademicProgress: Boolean
  };
  studentPersonalObj = {
    DoNotContactedAboutCourses: Boolean,
    DoNotContactedSurveys: Boolean,
    DoNotContactedPost: Boolean,
    DoNotContactedphone: Boolean,
    DoNotContactedEmail: Boolean
  };
  changeLogObj = {
    changelog: []
  };
  studentList = {};
  basicAssesmentObj = {
    careeraspirations: "",
    ApprenticeshipEnhanceCareerProgression: "",
    DigitalMarketingapprenticeship: ""
  }

  CommitmentStatementObj = {
    Apprentice: "",
    ApprenticeStandard: "",
    CommitmentStatement: "",
    DateOfBirth: "",
    DutiesAsEmployee: "",
    EndDate: "",
    HoursOfWork: "",
    Level: "",
    NameOfApprenticeship: "",
    NationalInsuranceNumber: "",
    SeeILPStored: "",
    StartDate: "",
    TheApprenticeJob: "",
    TheApprenticeName: "",
    UniqueLearnerNumber: "",
    students: "",
    _id: ""
  };

  ApprenticeshipAgreementChecklistObj = {
    ApprenticesName: Boolean,
    DirectorSignature: "",
    EmployerBusinessName: null,
    EmploymentEndDate: null,
    EmploymentStartDate: null,
    FixedTermContract: null,
    HolidayEntitlement: null,
    HoursOfWork: null,
    LengthOfProbationary: null,
    LocationAndPlace: null,
    NameOfDirector: "",
    NameOfDirectorDate: null,
    NameOfEmployer: "",
    NameOfEmployerDate: null,
    NameOfSupervisor: null,
    SalaryStated: null,
    SignatureOfEmployer: "",
    SignedByEmployer: null,
    StatutorySick: null,
    students: "",
    _id: ""
  }

  constructor( public service: MyProfileService, private userSvc: UserService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.service.setSidemenuTab("action");
    this.getAgreementDetails();
    this.getTenantSettings();
  }

  closeError() {
    this.isShowError = false;
    this.isShow = false;
  }

  signaturePopupopen() {
    this.signaturepopupopen = 'block'
  }

  signaturePopupClose() {
    this.signaturepopupopen = 'none'
  }

  getAgreementDetails() {

    let url = APP_URLS.v1.getEmployerAgreement;
    this.service.getCallWithHeaders(url).subscribe((res: any) => {
      this.getProfileDetails();
      if (res.details) {
        this.agreementDocumentDeatils = res.details;
        this.agreementDocumentDeatils.sort((a, b) => {
          return <any>new Date(b.created) - <any>new Date(a.created);
        });
        this.isNoAgreement = (this.agreementDocumentDeatils.length ) ? false : true; //need to check if agreement is available/not
      }
    })
  }

  OpenAgreement(data) {
    if (data.signedDate) {
      this.isShow = true;
    }
    if (data.signedDate == null) {
      this.isShowError = true;
      this.isShow = true;
    }

    this.agreementDeatils = data;
    this.agreementDeatils.isClicked = true
  }


  makePdf(data) {
    let filename = data;
    if(filename){
      var link = document.createElement("a");
      link.setAttribute('download', filename);
      link.setAttribute('target','_blank');
      link.href = APP_URLS.FILE_PATH_LINODE + 'convertingtopdf/' + filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

  }


  clear() {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
  }

  save() {
    this.img = this.sigPadElement.toDataURL("image/png");
  }

  Signature = "";

  dosignature() {
    this.img = this.sigPadElement.toDataURL("image/png");
    let url = APP_URLS.DoSignature
    var Payload = {
      signature: this.img
    }
    this.service.postCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        if (res.details) {
          this.Signature = res.details.siganture;
          this.userSvc.showSuccess("Signed Successfully")
        }
        this.updateProfileDetails()
        this.signaturePopupClose()
      }
      if (res.errorCode) {
        this.userSvc.showError(res.errorMessage)
      }
    })
  }

  updateProfileDetails() {
    let url = APP_URLS.v1.userDetails + '?apifrom=profile_summary';
      this.service.getCallWithHeaders(url)
      .subscribe((res: any) => {
      if (this.Signature) {
        let url = APP_URLS.v1.userDetails + '?apifrom=profile_summary';
        let Payload = {
          company_address: res.details.company_address,
          alternate_email:res.details.alternate_email,
          company_name: res.details.company_name,
          email: res.details.email,
          name: res.details.name,
          note: res.details.note,
          phone: res.details.phone,
          purpose: res.details.purpose,
          skype_id: res.details.skype_id,
          website: res.details.website,
          business_type: res.details.business_type,
          addresses: res.details.addresses,
          signature: this.Signature
        }

        this.service.putCall(url, Payload).subscribe((res: any) => {
          if (res.respCode) {
            this.userSvc.showSuccess("Your profile updated successfully")
            this.service.seteditprofile('dummy')
            this.profileDetails = res.details
            this.service.setPopup(false);
          }
          if (res.errorCode) {
            this.userSvc.showError(res.errorMessage)
          }

        })
      }
    })
  }

  tenantSettingsData:any;
  async getTenantSettings() {
    let url = APP_URLS.tenantSettings
    await this.service.getCallWithHeaders(url).subscribe((res: any) => {
       this.tenantSettingsData = res.details[0];
    })
  }

  getProfileDetails() {
    let url = APP_URLS.v1.userDetails + '?apifrom=profile_summary';
      this.service.getCallWithHeaders(url)
      .subscribe((res: any) => {
        this.profileDetails = res.userDetails
    })
  }

  download() {
    var id

    var data = document.getElementById('offerletter');
    html2canvas(data).then(canvas => {
      var imgWidth = 180;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('newPDF.pdf');
    });
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  @HostListener('document:mouseup', ['$event'])

  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (!this.context) {
      this.sigPadElement = this.sigPad.nativeElement;
      this.context = this.sigPadElement.getContext('2d');
      this.context.strokeStyle = '#3742fa';
    }
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  close(): void{}

  SignAgreement(): void{}

}
