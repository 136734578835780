import { Component, OnInit } from '@angular/core';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-checklist-documents',
  templateUrl: './checklist-documents.component.html',
  styleUrls: ['./checklist-documents.component.css']
})
export class ChecklistDocumentsComponent implements OnInit {

  tenantInfo: any = tenantInfo;

  studentId = "";

  acceptStatement: false;
  statement: true;
  trueInformation: false;
  disableSubmitBtn : boolean = true;

  constructor(public service: MyProfileService, private toastr: ToastrService,
    private userSvc: UserService) { }

  ngOnInit(): void {
    this.service.setTab({ tabName : "Checklist", isRefreshPermission : false })
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id
    this.getData();
  }

  onChangeEvent(){
    if( this.acceptStatement || this.statement || this.trueInformation ){
      this.disableSubmitBtn = false;
    } else {
      this.disableSubmitBtn = true;
    }
  }

  getData() {
    let url = APP_URLS.students_getChecklistDeclarations + this.studentId;
    this.service.getCall(url).subscribe((res: any) => {
      if (res.respCode) {
        if( res.details.length>0){
          this.acceptStatement = res.details[0].acceptStatement
          this.statement = res.details[0].statement
          this.trueInformation = res.details[0].trueInformation
        }
      }
    })
  }

  submit(){
    let url = APP_URLS.student_addOrUpdateChecklistDeclarations + this.studentId;
    var updateDatais = {
      acceptStatement: this.acceptStatement,
      trueInformation: this.trueInformation,
      statement:  this.statement,
      students:this.studentId
    }

    let payload = {
      updateData: updateDatais,
      students : this.studentId
      }

    this.service.putCall(url, payload).subscribe((res: any) => {
      if(res.respCode) {
        this.userSvc.showSuccess(res.respMessage)
      }
      if(res.errorCode){
        this.userSvc.showError('Try Again')
      }
    })
  }

}
