import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit {

  tenantInfo: any = tenantInfo;

  constructor() { }

  ngOnInit(): void {
  }

}
