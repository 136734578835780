import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { Router } from '@angular/router';
import { APP_URLS } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-student-wallet',
  templateUrl: './student-wallet.component.html',
  styleUrls: ['./student-wallet.component.css']
})
export class StudentWalletComponent implements OnInit {
  isWallet = true;
	isCurrency = true;
  isLoading = false;
  walletDetails:any[] = [];
  earnredAmt = 0;
  totalItem = 0;
  countPagination = 10;
  page = 1;
  settingsDetails = {
		myWorkCurrency : ''
	};

  constructor(public service: MyProfileService, private route: Router, private toast: UserService) { }

  ngOnInit(): void {
    var settingsData = localStorage.getItem('settingsData');
		if( settingsData != undefined && typeof settingsData == 'string' ){
			this.settingsDetails = JSON.parse(settingsData);
			if( this.settingsDetails != null ){
				this.isWallet = ( this.settingsDetails?.myWorkCurrency == 'BP' ) ? false : true;
				this.isCurrency = ( this.settingsDetails?.myWorkCurrency == 'BP' ) ? false : true;
			}
		} else {
			this.getDropdowns();
		}

    this.getWalletDetails();
  }

  getDropdowns() {
		let url = APP_URLS.v1.settingsData;
		this.service.getCall(url).subscribe((res: any) => {
			this.isWallet = ( res?.myWorkCurrency == 'BP' ) ? false : true;
			this.isCurrency = ( res?.myWorkCurrency == 'BP' ) ? false : true;
		});
	}
  
  navigate(): void{
    if(localStorage.getItem('KYC_COMPLETE') === 'true'){
      this.route.navigate(["student-my-work/student-withdraw"]);
    }else{
      this.toast.showError("Need to Update KYC");
    }
  }

  changePage(e: any): void{
    document.documentElement.scrollTop = 0;
  }

  getWalletDetails() {
    this.isLoading = true;
    this.walletDetails = [];
    let url = APP_URLS.v1.wallet + '?filter={"page":'+ this.page + ',"limit":'+ this.countPagination+'}';
    this.service.getCallWithHeaders(url)
    .subscribe((res: any) => {

      this.walletDetails = res?.walletList;
      this.earnredAmt = res?.amt?.walletAmt
      this.totalItem = ( this.page == 1 ) ? res?.pagination.totalRecords : this.totalItem;
      this.isLoading = false;
    });
  }


}
