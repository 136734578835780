import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { CertificatesService } from '../../services/certificates.service';

@Component({
  selector: 'app-my-certificates',
  templateUrl: './my-certificates.component.html',
  styleUrls: ['./my-certificates.component.css']
})
export class MyCertificatesComponent implements OnInit {

  myCertificatesList=[]
  constructor(public service: CertificatesService) { }
  
  ngOnInit(){
    this.getMyCertificatesList()
  }
  getMyCertificatesList() {
    let url = APP_URLS.MyCertificates;
    this.service.getCall(url).subscribe((res: any) => {
      this.myCertificatesList = res
    })
  }

}
