import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-offthejob-modal',
  templateUrl: './edit-offthejob-modal.component.html',
  styleUrls: ['./edit-offthejob-modal.component.css']
})
export class EditOffthejobModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
