import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, TenantId } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit, OnDestroy {
  courselist = [];
  showDialogReg = false;
  coursetype = "";
  student_Id: any;
  activeToken: boolean = false;

  constructor(private router: Router, public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private cms: CommonService) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => {
    //   return false;
    // }

    if (localStorage.getItem('token')) {
      this.activeToken = true
    }
    else {
      this.activeToken = false
    }



    this.coursetype = localStorage.getItem('courseType')
    this.getList();
    route.params.subscribe(val => {
      this.ngOnInit();
    })
  }

  ngOnInit() {
    this.getList();
    this.coursetype = localStorage.getItem('courseType');
    let data = JSON.parse(localStorage.getItem('userDetails'))
    if (data) {
      this.student_Id = JSON.parse(localStorage.getItem('userDetails'))._id;
    }
  }

  getList() {
    this.cms.loaderSet(true);
    let url = APP_URLS.CourscesList + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course_type","value":"' + this.coursetype + '","type":"eq"}]}'
    if (this.student_Id) {
      this.userSvc.getCallwithHeaders(url)
      .subscribe(
        (res: any) => {
          this.cms.loaderSet(false);
          this.courselist = res.all
        },
        err => {
          this.cms.loaderSet(false);
        })
    }
    else {
      this.userSvc.getCall(url).subscribe(
        (res: any) => {
          this.cms.loaderSet(false);
          this.courselist = res.all
        },
        err => {
          this.cms.loaderSet(false);
        })
    }
  }

  ngOnDestroy() {
    localStorage.removeItem('coursetype')
  }

  openIndividulaCourse(course) {
    localStorage.setItem('Individualcourse', course.slug,)
    this.router.navigate(['/' + course.slug])
  }

  AddFav(data) {

    let url = APP_URLS.applyAndFavourate + data._id + '?favorite=true';
    if (this.student_Id) {
      this.service.putCall(url, {})
        .subscribe((data: any) => {

          this.userSvc.showSuccess(data.respMessage);
          this.getList()
        }, err => {

        })
    } else {
      this.userSvc.showError('Please register or login to continue');

      this.showDialogReg = true;
    }

  }

  AddApply(data) {
     this.cms.loaderSet(true);
    let url = APP_URLS.applyAndFavourate + data._id + '?applycourse=true';

    if (this.student_Id) {
      this.service.putCall(url, {})
        .subscribe((data: any) => {
           this.cms.loaderSet(false);
          this.userSvc.showSuccess(data.respMessage);
          this.getList()
        }, err => {
           this.cms.loaderSet(false);
        })
    } else {
      this.userSvc.showError('Please register or login to continue');
      this.cms.loaderSet(false);
      this.showDialogReg = true;
    }

  }
}
