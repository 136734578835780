import { Component, OnInit, OnDestroy, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { ModaldialogComponent } from '../../pages/modaldialog/modaldialog.component'
import { CommonService } from '../../services/common.service'
import { TenantId } from '../../Models/url';
import {MyProfileService} from '../../services/my-profile.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  forget = {
    email: '',
    
  }
  constructor(private router: Router,
    private modalCom: ModaldialogComponent,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,
    private profileSvc:MyProfileService,
    private cms: CommonService) { }

  ngOnInit(): void {
  }

submit(){
  let url = APP_URLS.forGotPassword+this.forget.email+'&tenantId='+TenantId;
  this.userSvc.getCall(url)
  .subscribe((data:any)=>{
    if(data.respCode){
    this.userSvc.showSuccess(data.respMessage);
    this.profileSvc.setPopup(false);
    }
    else{
this.userSvc.showError(data.respMessage)

    }
  })
}

}
