import {
  Component, OnInit, ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { CommonService } from '../../services/common.service';
import { Subscription } from 'rxjs';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};



@Component({
  selector: 'app-book-interview',
  templateUrl: './book-interview.component.html',
  styleUrls: ['./book-interview.component.css']
})
export class BookInterviewComponent implements OnInit {
  p: number = 1;
  showDialogComplaint: boolean = false;


  bookInterview = {
    reminder_date: moment().format("YYYY-MM-DD"),
    reminder_time: "",
    message: "",
    reminder: ""
  }
  listOptions = [
    { value: "5 mins", name: "5 mins" },
    { value: "10 mins", name: "10 mins" },
    { value: "15 mins", name: "15 mins" }
  ];

  studentId: '';
  UserDetails: any;

  InterviewDetails = [];

  calenderResponce = "hello"

  dataLoaded: Promise<boolean>;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [{
    start: subDays(startOfDay(new Date()), 1),
    end: addDays(new Date(), 1),
    title: '',
    color: colors.red,
    actions: this.actions,
    allDay: true,
    resizable: {
      beforeStart: true,
      afterEnd: true,
    },
    draggable: true,
  },];

  activeDayIsOpen: boolean = false;
  detailRecivedSubscription: Subscription;

  isInterViewData:boolean = false;
  isLoading:boolean = false;

  constructor(private profileSvc: MyProfileService,
    private toast: UserService,
    private cms: CommonService) {
      this.detailRecivedSubscription = this.profileSvc.getbookappointment().subscribe(data => {
      if (data) {
        this.ngOnInit()
      }
    })

  }

  ngOnInit(): void {
    this.UserDetails = JSON.parse(localStorage.getItem('profilesummaryData'))


    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.getDetails()
  }

  ngOnDestroy() { 
    this.detailRecivedSubscription.unsubscribe();
  }

  setDate(data) {
    if (data == 'Today') {
      this.bookInterview.reminder_date = moment().format("YYYY-MM-DD");

    }
    if (data == 'Tomorrow') {
      const today = new Date()
      this.bookInterview.reminder_date = moment().add(1, 'days').format("YYYY-MM-DD");


    }
    if (data == 'DayAfterTomorrow') {
      const today = new Date()
      this.bookInterview.reminder_date = moment().add(2, 'days').format("YYYY-MM-DD");


    }
  }

  setTime(time) {

    this.bookInterview.reminder_time = time;
  }

  submit() {
    // this.cms.loaderSet(true);
    if (this.bookInterview.reminder == undefined && this.bookInterview.message == undefined && this.bookInterview.reminder_time == undefined) {
      // this.cms.loaderSet(false);
      this.toast.showError('Please Select Atleast one Field')
      return;
    }

    let url = APP_URLS.bookForInterview

    let payload = {
      attributes: {
        message: this.bookInterview.message,
        reminder: this.bookInterview.reminder,
        reminder_date: this.bookInterview.reminder_date,
        reminder_time: this.bookInterview.reminder_time
      }
    }

    this.profileSvc.postCall(url, payload)
      .subscribe((data: any) => {
        // this.cms.loaderSet(false);
        if (data.respCode) {
          this.toast.showSuccess('Success');

          this.InterviewDetails = []
          this.getDetails();
          this.profileSvc.setPopup(false);
        }
        if (data.errorCode) {
          this.toast.showError('Try Again')
        }

      }, err => {
        // this.cms.loaderSet(false);
        this.toast.showError('Error')
      })


  }

  async getDetails() {
    this.InterviewDetails = []
    this.isLoading = true;
    let url = APP_URLS.bookForInterviewCalenderData
    await this.profileSvc.getCallWithHeaders(url).subscribe((res: any) => {
      if (res) {
        this.events = [];
        res.CalendarEvent_Arr.forEach((x, i) => {
          let date = x.start;
          let time = moment(x.start).format('h:mm')
          let obj1 = {
            start: new Date(date),
            title: x.title + ', Time: '+ x.reminder_time,
            color: colors.red,
            actions: this.actions,
            allDay: true,
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
           
          }

          this.events.push(obj1);

        })

        res.student_event.sort((val1, val2)=> {
          return new Date (val2.attributes.reminder_date).valueOf() - new Date(val1.attributes.reminder_date).valueOf()
        });

        res.student_event.forEach((x, i) => {
          let obj2 = {
            serialnum: i + 1,
            reminder_date: x.attributes.reminder_date,
            reminder_time: x.attributes.reminder_time,
            reminder_to_time: x.attributes.reminder_to_time,
            status: x.attributes.status,
            message: x.attributes.message,
            with_whom:( x.assigned_employee.length ) ? x.assigned_employee : []
          }

          this.InterviewDetails.push(obj2)
        })
      }
      this.isInterViewData = this.InterviewDetails.length ? false : true;
      this.isLoading = false;

    },
    err=>{
     this.isLoading = false;
    })

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


  submitevent() {


    let url = APP_URLS.bookForInterview

    let payload = {
      attributes: {
        message: this.bookInterview.message,
        reminder: this.bookInterview.reminder,
        reminder_date: this.bookInterview.reminder_date,
        reminder_time: this.bookInterview.reminder_time
      }

    }


    this.profileSvc.postCall(url, payload)
      .subscribe((data: any) => {
        // this.cms.loaderSet(false);
        if (data.respCode) {
          this.toast.showSuccess('Success');

          this.InterviewDetails = []
          this.getDetails();
        }
        if (data.errorCode) {
          this.toast.showError('Try Again')
        }

      }, err => {
        // this.cms.loaderSet(false);
        this.toast.showError('Error')
      })

  }

  openPopup() {
    this.showDialogComplaint = true;
  }

}
