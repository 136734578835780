import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { CommonService } from '../../services/common.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { TenantId } from '../../Models/url';
// import html2canvas from 'html2canvas';
// import * as jspdf from 'jspdf';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {LmsInnerComponent} from '../lms-inner/lms-inner.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lms-inner-examtype',
  templateUrl: './lms-inner-examtype.component.html',
  styleUrls: ['./lms-inner-examtype.component.css']
})
export class LmsInnerExamtypeComponent implements OnInit {
  @Input() ExamData: any;

  certificateforcourseid = "";
  studentId = "";
  selectcertificateData: any;
  startcount = 0;
  lastcount = 0;

  questionNumber = 0;
  start: boolean = false;
  QuestionAndAnswers = [];

  FinishedtheTest = false;
  test_type = "";

  test_time = {
    leftTime: 0
  }

  nextbtn = true

  verification = false;

  interval;

  timeout: boolean = false;

  resultaccordion = [];
  result: any;

  DisplayName = "";

  AnswerlaterQuistions = [];

  showAnswer: boolean = false;
  isAnslaterView:boolean = true;
  showStartButton: boolean = true;
  chapter_Id: any;
  ID: any;

  certificateTemplate: any;
  description: any; 
  tenantSettingsData:any;
  detailRecivedSubscription: Subscription;
  showResultSummaryDialog: boolean = false;
  courseIdFromRoute:any;

  constructor(
    private toastr: ToastrService,
    public profileservice: MyProfileService,
    public service: CourseService,
    private userSvc: UserService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public LmsInnerComponent: LmsInnerComponent
  ) {
    this.getTenantSettings();

    var routeData = this.activeRoute.snapshot.params.id;
    var routeArry = routeData.split('-');
    if( routeArry.length ){
      this.courseIdFromRoute = routeArry[0];
    }

    this.detailRecivedSubscription = this.service.getExamtype().subscribe(data => {
      if (data) {
        this.ExamData = data;
        this.ngOnInit()
      }
    })

  }

  ngOnDestroy() { 
    this.detailRecivedSubscription.unsubscribe();
  }

  resultSummary:any;
  showResultSummary( resultData : any ){
    this.resultSummary  = [];
    window.scroll(0,0);
    this.resultSummary = resultData[this.test_type];
    this.showResultSummaryDialog = !this.showResultSummaryDialog;
  }

  ngOnInit(): void {
    this.begin()
    
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.DisplayName = JSON.parse(localStorage.getItem('profilesummaryData')).displayName;
    this.ID = localStorage.getItem('courseId')
    this.certificateforcourseid = localStorage.getItem('certificateForCourseId');
    this.chapter_Id = localStorage.getItem('chapterID');

    this.getSelectCertificate('start');
    // this.getResult();

  }

  async getTenantSettings() {
    let url = APP_URLS.tenantSettings
    await this.profileservice.getCallWithHeaders(url).subscribe((res: any) => {
       this.tenantSettingsData = res.details[0];
    })
  }

  begin(){
    this.quistions = [];
    this.answers = [];
    this.startcount = 0;
    this.lastcount = 0;
    this.questionNumber = 0;
    this.timeout = false
    this.start = false;
    this.nextbtn = true
    this.verification = false;
    this.FinishedtheTest = false;
  }

 
  getResult() {

    let url;
    if( this.courseIdFromRoute != '' ){
      url = APP_URLS.lmsInner + this.selectcertificateData.uuid + '&course=' + this.courseIdFromRoute +'&uuidonly=yes'
    } else {
      url = APP_URLS.lmsInner + this.selectcertificateData.uuid + '&uuidonly=yes'
    }

    this.service.getCall(url).subscribe((res: any) => {
      if( typeof res.chapters_a != 'undefined' ){
        this.ExamData = res.chapters_a[0];
        this.ExamData.report.sort((a, b) => {
          return <any>new Date(b.test_submitted_date) - <any>new Date(a.test_submitted_date);
        });
        this.resultaccordion = [];
        this.result = this.ExamData.report[0];
        this.ExamData.report.forEach((x, i) => {
          x['accordion'] = '#examaccordion' + i
          x['accordionID'] = 'examaccordion' + i
          this.resultaccordion.push(x);
        })
      }
    });
  }

  resendRequestCertificate(){
    this.userSvc.showSuccess('Your request for the certificate is already sent and waiting for the approval from the course department. For further information, you may contact your admin');
    // this.getResult();
  }

  //   getResult() {

  //     let url = APP_URLS.getResult + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"student","value":"' + this.studentId + '","type":"eq"},{"key":"coursetest","value":"' + this.certificateforcourseid + '","type":"eq"},{"key":"test_submitted_date","value":"","type":"ne"}]}&selectfields=_id,pass_mark,certificate_request,certificate_status,certificate_approval,iscertificate';
  //     this.service.getCall(url).subscribe((res: any) => {

  //       this.resultaccordion = res.details;
  //       this.result = res.details[0];


  //       if(this.result){
  //         if(this.result.test_type == 'test' || this.result.test_type  == 'qatest'){
  //           if(this.result.examStatus == 'Under Review' || this.result.examStatus == 'Accepted'){
  //             // hide
  //             this.showStartButton = false
  //           } else {
  //             // show
  //             this.showStartButton = true
  //           }
  //         }

  //         if(this.result.test_type  == 'quiz'){
  //           if(this.result.examStatus == 'Under Review'){
  //             // hide
  //             this.showStartButton = false
  //           } else {
  //             // show
  //             this.showStartButton = true
  //           }
  //         }
  //       }

  //     })

  //   }

  retest() {
    this.quistions = [];
    this.answers = [];
    this.startcount = 0;
    this.lastcount = 0;
    this.questionNumber = 0;
    this.timeout = false
    this.start = false;
    this.nextbtn = true
    this.verification = false;
    this.FinishedtheTest = false;
    this.isAnslaterView = true;
    this.showAnswer = false;
    this.getResult();
    this.getSelectCertificate('retest');
  }


  startClick() {
    this.start = true;
    this.questionNumber = 0;
    this.startcount = 1;
    this.isAnslaterView = true;
    this.showAnswer = false;
    this.test_time.leftTime = (this.selectcertificateData.test_time) * 60;
    if(this.test_type != "quiz"){
      this.timer(); 
    }
  }

  timer() {
    this.interval = setInterval(() => {
      this.timeout = true;
      this.timeoutfinish()
    }, parseInt(this.selectcertificateData.test_time) * 60000)
  }


  RadioClick(data, val, i, mainindex) {
    this.selectcertificateData.student_answer[val.indexval] = data.key
    val.options_keyval[i]['cheked'] = true;
  }

  website = [];

  onCheckboxChange(e, val, data, i, mainindex) {

    if (e.target.checked) {
      this.website.push(e.target.value);
      val.options_keyval[i]['cheked'] = true;
    } else {
      var index = this.website.findIndex(x => x === e.target.value);
      val.options_keyval[index]['cheked'] = false;
      this.website.splice(index, 1);
    }
    this.website.sort();
    val.answerarry = this.website
    let answer = this.website.toString()
    this.selectcertificateData.student_answer[val.indexval] = answer

  }

  getSelectCertificate(type : any) {

    this.selectcertificateData = this.ExamData;
    this.description =  this.sanitizer.bypassSecurityTrustHtml(this.ExamData.description)
    this.test_type = this.ExamData.chapter_type;
    this.lastcount = this.ExamData.question.length;
    this.answers = this.ExamData.student_answer
    this.ExamData.question.forEach((x, i) => {
      if( x ){
        x['indexval'] = i
        this.quistions.push(x);
      }
    })

    if(this.quistions.length == 1){
      this.nextbtn = false;
    }
    
    this.ExamData.report.sort((a, b) => {
      return <any>new Date(b.test_submitted_date) - <any>new Date(a.test_submitted_date);
    });
    this.resultaccordion = [];
    this.result = this.ExamData.report[0];
    
    this.ExamData.report.forEach((x, i) => {
      x['accordion'] = '#examaccordion' + i
      x['accordionID'] = 'examaccordion' + i
      this.resultaccordion.push(x);
    })

    if( type == 'retest')
      this.startClick()
  }
  
  getCertificate(data){   
    let url = APP_URLS.certificateTemplate;
    this.service.getCallwithHeader(url).subscribe((res: any) => {
      var snippet = document.createElement("div");
      snippet.innerHTML = res.resTemp.template_text_view;
      Array.from(snippet.querySelectorAll('img')).forEach(function (ele) {

        var text = ele.src
        var text2=ele.src
        let logo = tenantInfo.logoUrl;
        let backgroundlogo = '../../../assets/images/certificatebg.png'
        let val = location.origin+"/[sitelogo]"
        let val2 = location.origin+"/[backgroundlogo]"
        if (ele.src == val) {
          text = text.replace(text, logo)
          ele.src = text

        }
        if (ele.src == val2) {
          text = text.replace(text2, backgroundlogo)
          ele.src = text

        }

      });
      Array.from(snippet.querySelectorAll('p')).forEach(function (ele) {
        var text = ele.textContent
        var text2 = ele.textContent
        var name = JSON.parse(localStorage.getItem('profilesummaryData')).displayName;
        const date = new Date()
        if (ele.textContent == "[studentname]") {
          text = text.replace(text, name)
          ele.textContent = text

        }
        if (ele.textContent == "[taskname] test" ) {
          text = text.replace(text2, data.name)
          ele.textContent = text

        }
      });
      Array.from(snippet.querySelectorAll('td')).forEach(function (ele) {
        var text3 = ele.textContent
        var text = ele.textContent
        const date = moment().format("YYYY-MM-DD")
        if (ele.textContent == "[date]") {
          text = text.replace(text3, date)
          ele.textContent = text
        }

      });
      res.resTemp.template_text_view = snippet.innerHTML
      this.certificateTemplate = this.sanitizer.bypassSecurityTrustHtml(res.resTemp.template_text_view);
    })
  }

  quistions = [];
  answers = [];

  nextQuestion(question, questionNumbernumber) {

    if (question.question_type == "radio" || question.question_type == "T/F") {
      if (this.selectcertificateData.student_answer[question.indexval] == "") {
        if( this.test_type == 'test'){
          this.service.showError("Select a answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question");
         } else {
           this.service.showError('Select a answer for this question and then go to next question (or) You can click on the "See Answer" button, see answer and mark it then goto next question');
         }
        return;
      }
    }

    if (question.question_type == "multiple") {
      if (this.selectcertificateData.student_answer[question.indexval] == "" || this.selectcertificateData.student_answer[question.indexval].length < 2) {
        this.service.showError("Select more than one answer and then go to next question");
        return;
      }
    }
    if( this.test_type == 'qatest'){
      if(this.selectcertificateData.student_answer[question.indexval]== "") {
        this.service.showError("Answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question");
        return;
      }
     }
    this.showAnswer = false;

    if (this.questionNumber < this.quistions.length - 1) {
      this.questionNumber = this.questionNumber + 1;
      this.startcount = this.startcount + 1;

    }
    this.website = [];
    if (this.questionNumber == this.quistions.length - 1) {
      this.nextbtn = false
    }
  }


  previouesQuestion(question) {

    if (this.questionNumber == this.quistions.length - 1) {
      this.nextbtn = true
    }

    if (this.questionNumber > 0 && this.verification == false && this.startcount > 0) {
      this.questionNumber = this.questionNumber - 1;
      this.startcount = this.startcount - 1;
    }
    if (this.verification == true) {
      this.questionNumber = this.quistions.length - 1;
      this.startcount = this.quistions.length;
      this.verification = false
    }
    if( this.selectcertificateData.student_answer[question.indexval - 1] ){
      this.website = this.selectcertificateData.student_answer[question.indexval - 1].split(',');
    }
  }

  Finish(data, frmContext) {
    var isAnyNotAnswer = false;
    if( frmContext == 'finishBtn' ){
      if( this.selectcertificateData.student_answer[data.indexval] == ''){
        isAnyNotAnswer = true;
      }
    }
    if(isAnyNotAnswer){
      if( this.test_type == 'qatest'){
        this.service.showError("Answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question")
      } else if( this.test_type == 'quiz'){
        this.service.showError("Select a answer for this question and then go to next question (or) You can click on the 'See Answer' button, see answer and mark it then goto next question")
      } else {
        this.service.showError("Select a answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question")
      }
    } else {
      var Temp_AnswerLaterQuestion = JSON.parse(JSON.stringify(this.AnswerlaterQuistions));
      Temp_AnswerLaterQuestion.forEach((x) => {
        if( this.selectcertificateData.student_answer[x.indexval] != '' ){
          this.removeArrayValues(x.indexval);
        }
      })
      if( ! this.AnswerlaterQuistions.length ){
        this.quistions = this.selectcertificateData.question;
      }
      this.questionNumber = this.quistions.length
      this.startcount = this.quistions.length
      this.verification = true;
      this.isAnslaterView = false;
    }
  }

  timeoutfinish(){
    this.questionNumber = this.quistions.length
    this.startcount = this.quistions.length
    this.verification = true;
  }

  answerLaterClick(quistion, num) {
    this.website = [];
    this.selectcertificateData.student_answer[num] = ""
    quistion.options_keyval.forEach(x => {
      if (x.cheked && x.cheked == true) {
        x.cheked = false
      }
    })
    let alreadyinarray = this.AnswerlaterQuistions.find(y => y.question == quistion.question)

    if (alreadyinarray) {

    }
    else {
      this.AnswerlaterQuistions.push(quistion);
    }

    if (this.questionNumber < this.quistions.length - 1) {
      this.questionNumber = this.questionNumber + 1;
      this.startcount = this.startcount + 1;
    }
    if (this.nextbtn == false) {
      this.Finish(quistion,'answerLaterBtn');
    }

    if (this.questionNumber == this.quistions.length - 1) {
      this.nextbtn = false
    }
  }

  answerLaterQuestions() {
    this.showDialogNotAnswer = false;
    this.startcount = 1;
    this.lastcount = this.AnswerlaterQuistions.length;
    this.quistions = this.AnswerlaterQuistions;
    this.questionNumber = 0;
    this.verification = false;
    this.isAnslaterView = false;
    this.nextbtn = ( this.AnswerlaterQuistions.length > 1 ) ? true : false;
 
  }

  seeAnswer(data, num) {
    this.showAnswer = !this.showAnswer
  }
  payload = {};
  showDialogNotAnswer:boolean = false;
  showSubmitConfirmDialog : boolean = false;

  continueTest(){
    this.showDialogNotAnswer = false;
    this.showSubmitConfirmDialog = false;
  }

  removeArrayValues( index ){
    for( var i = 0; i < this.AnswerlaterQuistions.length; i++ ){
      if( this.AnswerlaterQuistions[i].indexval == index ){
        this.AnswerlaterQuistions.splice(i,1);
      }
    }
  }

  FinishTest() {
    var Temp_AnswerLaterQuestion = JSON.parse(JSON.stringify(this.AnswerlaterQuistions));
    Temp_AnswerLaterQuestion.forEach((x) => {
      if( this.selectcertificateData.student_answer[x.indexval] != '' ){
        this.removeArrayValues(x.indexval);
      }
    })
    window.scroll(0,0);
    if( this.AnswerlaterQuistions.length ){
      this.showDialogNotAnswer = true;
    } else {
      this.showSubmitConfirmDialog = true;
    }
  }

  gotoInitSection(){
    this.FinishedtheTest = false;
    this.verification = false;
    this.start = false;
    this.getResult();
  }

  submitTest(){
    let url = APP_URLS.lmsInnerSubmit;

    this.payload = {
      chapter: this.selectcertificateData._id,
      student: this.studentId,
      course: this.ID,
      exam_type: this.test_type,
      student_answer: this.selectcertificateData.student_answer,
    }

    this.service.putCall(url, this.payload).subscribe((res: any) => {
      if (res.respCode) {
        localStorage.setItem('uuid',this.selectcertificateData.uuid)
        if(typeof this.ExamData!='undefined'){
          this.service.setexam(this.ExamData.serialnum)
        }
        clearInterval(this.interval);
        //this.gotoInitSection();

        if(this.test_type == 'test'){
          this.userSvc.showSuccess('Your Test is submitted successfully. For the result see your test report below');
        } else if(this.test_type == 'qatest'){
          this.userSvc.showSuccess('Your Test is submitted successfully. Course team will review and send you the test report through email and notification');
        } else if(this.test_type == 'quiz'){
          this.userSvc.showSuccess('Your Quiz is submitted is successfully. For the result see your test report below.');
        }

        this.LmsInnerComponent.getLmsData();
      }
      else{
        this.service.showError(res.errorMessage) ;
      }
      this.showSubmitConfirmDialog = false;
    })
  }

    // requestCertificate(data) {
    //   let url = APP_URLS.Lmsassignments+data._id;
    //   let Payload = {
    //     certificate_request: true
    //   }
    //   this.service.putCall(url, Payload).subscribe((res: any) => {
    //     if (res.respCode) {
    //       this.service.showSuccess(res.respMessage)
    //       localStorage.setItem('uuid',this.selectcertificateData.uuid)
    //       this.service.setexam(this.ExamData.serialnum)
    //     }
    //     else{

    //     }

    //   })
    // }

  async requestCertificate(result){
    
    let url = APP_URLS.oldApi.CertificateRequest+result._id+'?target=assignment';
    let Payload = {
      certificate_request: true
    };
    await this.userSvc.putCallwithHeaders(url, Payload).subscribe((res: any) => {
      if(typeof res.toast != 'undefined'){
        if(res.toast.type == 'success'){
          this.userSvc.showSuccess(res.toast.message);
          result.certificate_request = true;
          result.certificate_approval = '';
        }
      }
      if(typeof res.errorMessage != 'undefined'){
        this.userSvc.showError(res.errorMessage);
      }
    });
  }

  data: any
  download() {
    let lmsData = JSON.parse(localStorage.getItem('lmsInnerData'))
    let courseheading = localStorage.getItem('courseheading')
    let courseName = localStorage.getItem('TrainingName');
    var certificateType = '';
    if( this.test_type == 'test'){
      certificateType = 'Chapter Completion Certificate - Test'
    } else if( this.test_type == 'qatest' ){
      certificateType = 'Chapter Completion Certificate - Test QA'
    }
    
    var obj = {
      studentname : this.DisplayName,
      course : courseName,
      testName : this.selectcertificateData.name,
      certificate_id : this.certificateforcourseid,
      updated : this.result.test_submitted_date,
      signature : this.tenantSettingsData.settings.signature,
      certificate_type : certificateType,
      termName : ( lmsData && lmsData.courseTermDetail && lmsData.courseTermDetail.length ) ? lmsData.courseTermDetail[0].name : '',
      chapterName : courseheading
    }
   
    localStorage.setItem('certificates',JSON.stringify(obj))
    
    this.router.navigate([]).then(result => {  window.open( `/opencertificate`, '_blank',); });

  }
}
