import { Component, OnInit } from '@angular/core';
import { MyProfileService } from 'src/app/services/my-profile.service';

@Component({
  selector: 'app-employer-notification',
  templateUrl: './employer-notification.component.html',
  styleUrls: ['./employer-notification.component.css']
})
export class EmployerNotificationComponent implements OnInit {

  constructor( public service: MyProfileService ) { }

  ngOnInit(): void {
    this.service.setSidemenuTab("notifications");
  }

}
