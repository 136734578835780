import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service'
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { TenantId } from '../../Models/url';
@Component({
  selector: 'app-qatest',
  templateUrl: './qatest.component.html',
  styleUrls: ['./qatest.component.css']
})
export class QatestComponent implements OnInit {
  ID: string;
  AllChapters=[];
  chaptersData=[];
  Chapters=[];
  qaEaxamData = [];
  FinalChaptersArray=[];
  parentAccordion: any;
  selectedexam: any;
  isNoQaTestData : boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc:MyProfileService,
    private cms: CommonService,
   ) {
    this.ID =localStorage.getItem('courseId')    
  }

  ngOnInit(): void {
    this.getqatestData();
  }
  
 async getqatestData() {

    let url = APP_URLS.newchapters + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"}]}'
    await this.service.getCallwithHeader(url).subscribe(
      (res: any) => {
        this.parentAccordion = res.independent[0].name
        let data = res.independent[0].child[0].qatest
        data.forEach((x,i)=>{
          x['accordion'] = '#qatestaccordion' + i
          x['accordionID'] = 'qatestaccordion' + i
          this.qaEaxamData.push(x);
        })
        this.isNoQaTestData =  ( this.qaEaxamData.length ) ? false : true;
    })
  }

  openTest(data){
    this.selectedexam  = data
    this.service.setopenexam(data);
  }
}
