import { Component, OnInit, Input } from '@angular/core';
import { MyProfileService } from '../../../services/my-profile.service';
@Component({
  selector: 'app-complaint-open',
  templateUrl: './complaint-open.component.html',
  styleUrls: ['./complaint-open.component.css']
})
export class ComplaintOpenComponent implements OnInit {
  studentDetails = []
  attachments = []
  constructor(
    private profileSvc: MyProfileService,
  ) { 
    
  }

  ngOnInit(): void {
    this.profileSvc.getDetails().subscribe(data => {
      if(data){
        this.studentDetails=data.threads
      }
      else{
        this.studentDetails=this.profileSvc.data
      }    
    })
  }

}
