import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service'
import { TenantId } from '../../Models/url';
import { FilterPipe } from '../../Models/filter.pipe';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.css'],
  providers:[FilterPipe]
})
export class ComplaintComponent implements OnInit {
  showDialogComplaint: boolean = false;
  inbox: boolean = true
  compose: boolean = false
  showDialogComplaintOpen: boolean = false;
  p = 1;
  countPagination = 10;
  search: string;
  complaintOpen = []
  studentId: any
  complaint = []
  complaintpopupopen = 'none';
  isComplaintInfo : boolean = false;
  List = [];
  ListTemp = [];
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE

  constructor(
    private profileSvc: MyProfileService, private toast: UserService,
    private FilterSearch : FilterPipe,
    private cms: CommonService) { }

  ngOnInit(): void {
    this.profileSvc.setSidemenuTab("complaint");
    // this.profileSvc.getComplaintCompose().subscribe(data => {
    //   this.complaint = [];
    //   this.ListTemp = [];
    //   this.getCompose();
    // })
    let data = JSON.parse(localStorage.getItem('userDetails'));
    if (data) {
      this.studentId = data._id;
    }
    // else {
    //   this.complaint = data;
    // }
    this.getCompose();
    this.InboxClick()
  }

  onChangeEvent(event: any) {
    this.ListTemp = this.FilterSearch.transform(this.complaint,event,'');
    this.ListTemp.forEach((y, i) => {
      y['ticketindex'] = i + 1;
    })
    this.isComplaintInfo = ( this.ListTemp.length ) ? false : true;
  }

  complaintPopupOpen(data) {
    this.complaintpopupopen = 'block';
    this.complaintOpen = data
  }

  complaintPopupClose() {
    this.complaintpopupopen = 'none'
  }
  InboxClick() {
    this.inbox = true;
    this.compose = false;
  }
  ComposeClick() {
    this.showDialogComplaint = !this.showDialogComplaint
    this.inbox = false;
    this.compose = true;

  }

  parentMethod(data){
    if(data){
      this.complaint=[];
      this.isComplaintInfo = false;
      this.getCompose();
    }
  }

  getCompose() {
    this.cms.loaderSet(true);
    let url = APP_URLS.supportTicket + `[{"key":"student","value":"` + this.studentId + `","type":"eq"},{"key":"tenantId","value":"` + TenantId + `","type":"eq"}]}`
    this.profileSvc.getCall(url)
      .subscribe((data: any) => {
        this.cms.loaderSet(false);
        if (data) {
          if (data.inboxes.length > 0) {
            this.complaint = data.inboxes.filter((x, i) => {
              //x['sno']=i+1
              if (x.inbox_type == "complaint") {
                return x
                // localStorage.setItem('complaintData', JSON.stringify(this.complaint))
              } else {

              }
            });
          }
        }
        this.setIndex();
      },
        err => {
           this.cms.loaderSet(false);

        })
  }
  
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.List = pageOfItems;
  }
  
  setIndex(){
    this.complaint.sort((a, b) => {
      return <any>new Date(b.created) - <any>new Date(a.created);
    });
    this.complaint.forEach((x,i)=>{
      x['ticketindex'] = i + 1
    })
    this.ListTemp = JSON.parse(JSON.stringify(this.complaint));
    this.isComplaintInfo = ( this.ListTemp.length ) ? false : true;
  }

  openComplent(data) {   
    this.complaintOpen = data.threads
    this.profileSvc.setDetails(data)
    this.profileSvc.setDetailsIs(data)
    this.showDialogComplaintOpen = !this.showDialogComplaintOpen
  }
  
  closeTicket(data) {
    let url = APP_URLS.tickentClose + data._id;
    let payload = { open_closed_thread: 'closed' }
    this.profileSvc.putCall(url, payload)
      .subscribe((data: any) => {
        this.getCompose();
        this.toast.showSuccess(data.respMessage);

      }, err => {
        
      })
  }
}
