import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-selectcourse',
  templateUrl: './selectcourse.component.html',
  styleUrls: ['./selectcourse.component.css']
})
export class SelectcourseComponent implements OnInit {
  tenantInfo:any = tenantInfo;
  selectCourseIdData: any;

  constructor(private router:Router,private route : ActivatedRoute,public service: CourseService,private toastr: ToastrService) { }

  ngOnInit(): void {
    this.selectCourseIdData=this.service.selectCourseByIdData;

  }
}
