import { Component, OnInit } from '@angular/core';
import { APP_URLS } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from "../../services/user.service";

@Component({
  selector: 'app-student-withdraw',
  templateUrl: './student-withdraw.component.html',
  styleUrls: ['./student-withdraw.component.css']
})
export class StudentWithdrawComponent implements OnInit {
  isLoading = false;
  withdrawDetails: any[] = [];

  totalItem = 0;
  countPagination = 10;
  page = 1;
  requestAmt = '';

  constructor(public service: MyProfileService, private toast: UserService) { }

  ngOnInit(): void {
    this.getWalletDetails();
    this.getWithdrawDetails();
  }

  changePage(e:any): void{}
  walletAmount = {
    walletAmt : 0,
    approvedWithdrawAmt: 0,
    waitingWithdrawAmt: 0
  }
  getWalletDetails() {
    this.walletAmount = {
      walletAmt : 0,
      approvedWithdrawAmt: 0,
      waitingWithdrawAmt: 0
    }
    let url = APP_URLS.v1.wallet + '?filter={"page":'+ this.page + ',"limit":'+ this.countPagination+'}';
    this.service.getCallWithHeaders(url)
    .subscribe((res: any) => {
      this.walletAmount = res.amt;
    });
    document.documentElement.scrollTop = 0;
  }

  requestAmout() {

    if( this.walletAmount?.walletAmt >= parseFloat(this.requestAmt) ){
      let url = APP_URLS.v1.requestWithdraw;
      this.service.postCallwithHeaders(url, { amount : this.requestAmt })
      .subscribe((res: any) => {
        this.requestAmt = '';
        this.getWalletDetails();
        this.getWithdrawDetails();
      });
    } else {
      this.toast.showError("Request amount is not greater than your wallet amount");
    }
  }

  getWithdrawDetails() {
    this.isLoading = true;
    this.withdrawDetails = [];
    let url = APP_URLS.v1.withdraw;
    this.service.getCallWithHeaders(url)
    .subscribe((res: any) => {
      if(res.respCode === 202){
        this.withdrawDetails = res.findRes;
        this.totalItem = ( this.page == 1 ) ? res?.pagination.totalRecords : this.totalItem;
      }
      this.isLoading = false;
    });
  }

}
