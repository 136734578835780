import { Component, OnInit } from '@angular/core';
import { CourseService } from '../../services/course.service';
import { APP_URLS } from '../../Models/url';
import { TenantId } from '../../Models/url';
import { UserService } from '../../services/user.service';
import { CommonService } from '../../services/common.service'
import { zIndex } from 'html2canvas/dist/types/css/property-descriptors/z-index';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router,ActivatedRoute,ActivatedRouteSnapshot } from '@angular/router';
import { Event, NavigationEnd} from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.css']
})
export class PlayComponent implements OnInit {
  eventSubscription: Subscription;
  ID: any;
  playData = [];
  studentId: any
  showCard: boolean = false
  playedcount = 0;
  yetToPlay = 0;
  totalplay=0
  sampleData = [];
  playCategories = [];
  nrSelect = "All";
  apporyettoplaycard = 'Yet to Play Card';
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE
  constructor(
    public service: CourseService,
    private userSvc: UserService,
    private cms: CommonService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
  ) {
    
   }
   
  ngOnInit(): void {
    
    this.ID = localStorage.getItem('courseId')
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.getPlayList('kill')
  }
  playAudio(){
    let audio = new Audio();
    audio.src = "../../../assets/SoundBible.mp3";
    audio.load();
    audio.play();
  }
  async getPlayList( type:any) {
    this.playedcount = 0;
    this.yetToPlay = 0;
   
    let url = APP_URLS.getPlay + this.ID + '?filter={"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}'

    await this.service.getCallwithHeader(url).subscribe((res: any) => {
     
      res.playcourses.forEach(x => {
        if (x.playcourse_audio) {
          x.playcourse_audio = this.sanitizer.bypassSecurityTrustHtml(x.playcourse_audio);
        }
        if (x.playcourse_video) {
          x.playcourse_video = this.sanitizer.bypassSecurityTrustHtml(x.playcourse_video);
        }
        if (x.playcourse_other) {
          x.playcourse_other = this.sanitizer.bypassSecurityTrustHtml(x.playcourse_other);
        }

      })
      this.playCategories = [...res.playcourses.reduce((mp, o) => {
        if (!mp.has(o.playcourse_level)) mp.set(o.playcourse_level, { ...o, count: 0 });       
          mp.get(o.playcourse_level).count++;     
        return mp;
      }, new Map).values()];
      
      
      this.playData = res.playcourses
      this.sampleData = res.playcourses
      res.playcourses.forEach(x => {
        if (x.completed_students.length > 0) {
         
          var filterData = x.completed_students.filter(z =>
            z.toString() == this.studentId.toString()
          )       
          if (filterData.length > 0) {
            x['showCard'] = false
          }
          else {
            x['showCard'] = true
          }
          x.completed_students.forEach(x => {
            if (x.toString() == this.studentId.toString()) {
              this.playedcount = this.playedcount + 1
            }
          });
          if(this.playCategories.length>0){
            this.playCategories.forEach(y=>{
              if(x.showCard==false && x.playcourse_level==y.playcourse_level){
                y.count--
              }
            })
          }
         
        }
        else {
          x['showCard'] = true
        }
      })
    
      this.yetToPlay =  res.playcourses.length - this.playedcount
      this.totalplay =  res.playcourses.length 

      if( type == 'kill'){
        this.categories('Yet to Play Card')
      }
    },
      err => {
        
      })
  }

  killPlay(id) {
    let url = APP_URLS.killPlay + id
    this.service.postCall(url, { studentid: this.studentId }).subscribe(
    (res: any) => {
      this.playAudio();
      this.userSvc.showSuccess(res.respMessage);
      this.getPlayList('kill');
    },
    err => {
    })
  }

  rePlay() {
    
    let url = APP_URLS.rePlay + this.ID
    this.service.postCall(url, { studentid: this.studentId }).subscribe(
      (res: any) => {
        this.userSvc.showSuccess(res.respMessage);
        this.getPlayList('replay');
      },
      err => {
      })
  }
  categories(data) {
    // *ngIf="playCourse.showCard==true"
    this.playData = [];
    if (data == 'All Cards') {
      this.apporyettoplaycard = data;
      this.playData = this.sampleData;
      this.nrSelect = "All"
    } else if (data == 'Yet to Play Card') {
      this.apporyettoplaycard = data;
      this.sampleData.forEach(x => {
        if (x.showCard==true) {
          this.playData.push(x)
        }
      })
      this.nrSelect = "All"
    }
    else {
      this.sampleData.forEach(x => {
        if (x.playcourse_level == data && (this.apporyettoplaycard=='All Cards' || (this.apporyettoplaycard=='Yet to Play Card' && x.showCard==true) ) ) {
          this.playData.push(x)
        }
      })
    }
    
  }
  openCourse(data){
    localStorage.setItem('uuid', data.uuid)
    localStorage.setItem('chapterID', data._id)
    localStorage.setItem('parentID', data._id)
    // localStorage.setItem('coursename', data.name)
    // localStorage.setItem("courseheading",data.name)
    // window.open("/Mychapter/"+data.course+'-'+data.uuid + '/'+ data.name, '_self');
    this.router.navigate(['/Mychapter/', data.course + "-" + data.uuid, data.name.replace(/\s/g, '-') , "?pou=parent"])
  }

  ngOnDestroy() {
    
  }
}
