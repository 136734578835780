import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-employee-history',
  templateUrl: './employee-history.component.html',
  styleUrls: ['./employee-history.component.css']
})
export class EmployeeHistoryComponent implements OnInit {

  tenantInfo: any = tenantInfo;
  p = 1;
  countPagination = 10;
  showDialogEmp:boolean=false;
  studentId: any;
  EmpData=[];
  arrayData=[];
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  isNoEmpHistryData:boolean = false;
  isLoading:boolean = true;
  constructor(private profileSvc: MyProfileService,private toast:UserService) { }

  ngOnInit(): void {
    this.profileSvc.setTab({ tabName : "Employement", isRefreshPermission : false })
    this.profileSvc.getPopup().subscribe(data=>{
      this.EmpData=[]
      this.isNoEmpHistryData = false;
      this.getEmpHistory();
    })
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.getEmpHistory();

  }
  changePage(e){
    if(!e){
      e=1;
    }
    this.p=e;
  }
  getEmpHistory(){
    this.isLoading = true;
    let url=APP_URLS.getQualificationandEmployementandPersonl+`{"key":"attributes.context_id","value":"`+this.studentId+`","type":"eq"}]}`
    this.profileSvc.getCall(url)
    .subscribe((data:any)=>{
      this.arrayData=data.events;
      this.arrayData.forEach(y => {
        if(y.attributes.formname=="employehistory"){
          this.EmpData.push(y.attributes)
        }
      });
      this.showDialogEmp = false;
      this.isLoading = false;
      this.isNoEmpHistryData = this.EmpData.length ? false : true;
    })
  }

}
