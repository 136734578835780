import {
	Component,
	OnInit,
	ViewChild,
	ElementRef
} from "@angular/core";

import { APP_URLS } from "../../Models/url";
import { MyProfileService } from "../../services/my-profile.service";
import { CommonService } from "../../services/common.service";
import { UserService } from "../../services/user.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ProfileDetails } from "src/app/Models/general.model";

@Component({
	selector: "app-employer-profile-summary",
	templateUrl: "./employer-profile-summary.component.html",
	styleUrls: ["./employer-profile-summary.component.css"],
})
export class AppEmployerProfileSummaryComponent implements OnInit {
	@ViewChild("file", { static: false })

	isLoading = false;
	InputVar: ElementRef;
	profileedit = "editFalg";
	studentId: any;
	profileDetails: ProfileDetails;
	profileEditDetails = {};
	waitingFlag: boolean = false;
	profilePicpopupopen = "none";
	showDialog: boolean = false;
	data: any;
	fileToUpload: any;
	fileName: any;
	fileSize: any;
	detailRecivedSubscription: Subscription;
	dialogSize = "max";

	constructor(
		public service: MyProfileService,
		public cms: CommonService,
		private toast: UserService,
		private route: Router
	) {
		this.detailRecivedSubscription = this.service
			.geteditprofile()
			.subscribe((data) => {
				if (data) {
					this.ngOnInit();
				}
			});
	}

	FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;

	ngOnInit(): void {
		// var path = this.route.url;
		// this.service.setSidemenuTab("profilesummary");

		this.studentId = JSON.parse(localStorage.getItem("userDetails"))._id;
		// let data = JSON.parse(localStorage.getItem('profilesummaryData'))
		this.getProfileDetails();
	}

	ngOnDestroy() {
		this.detailRecivedSubscription.unsubscribe();
	}

	picturepopupopen(data) {
		this.profilePicpopupopen = "block";
		this.data = data;
	}

	picturePopupClose() {
    document.getElementById('resetFile').click();
		this.profilePicpopupopen = "none";
	}

	/**
	 *
	 * @param files File upload process
	 *
	 */
	handleFileInput(files) {
		if (files.length) {
			this.waitingFlag = true;
			this.fileSize = files[0].size;

      if(this.fileSize > 5000000){
        this.toast.showError('File size is too large, please follow the instruction listed below on profile upload pop up.');
        this.waitingFlag = false;
        document.getElementById('resetFile').click();
        return;
      }

			var fileType = files[0].type;

      if (
				fileType != "image/png" &&
				fileType != "image/jpeg" &&
				fileType != "image/jpg"
			){
        this.toast.showError('File format is incorrect, please follow the instruction listed below on profile upload pop up.');
        this.waitingFlag = false;
        document.getElementById('resetFile').click();
        return;
      }

			if (
				fileType == "image/png" ||
				fileType == "image/jpeg" ||
				fileType == "image/jpg"
			) {
				const formData = new FormData();
				let url = APP_URLS.v1.profilePictureUpdate;
				formData.append("images", files[0]);
				this.service.postCall(url, formData).subscribe(
					(res: any) => {
						this.waitingFlag = false;
						if (res.errorMessage) {
							this.fileName = "";
							this.InputVar.nativeElement.value = "";
							this.toast.showError(res.errorMessage.message);
						} else if (res.details.profile_picture) {
							this.profileDetails.profile_picture =
								res.details.profile_picture;
							this.profileEditDetails = JSON.parse(
								JSON.stringify(this.profileDetails)
							);
							this.toast.showSuccess(
								"Profile picture uploaded successfully"
							);
              document.getElementById('resetFile').click();
							this.picturePopupClose();
						}
					},
					(err) => {
						this.waitingFlag = false;
						this.profileDetails.profile_picture = "";
						this.profileEditDetails = JSON.parse(
							JSON.stringify(this.profileDetails)
						);
						this.toast.showError("Unable to upload");
					}
				);
			} else {
				this.waitingFlag = false;
				this.fileName = "";
				this.InputVar.nativeElement.value = "";
				this.toast.showError("File format not allowed.");
			}
		}
	}

	getProfileDetails() {
		//this.cms.loaderSet(true);
    this.isLoading = true;
		let url = APP_URLS.v1.userDetails + "?apifrom=profile_summary";
		this.service.getCallWithHeaders(url).subscribe(
			(res: any) => {
				//this.cms.loaderSet(false);
				this.profileDetails = res.userDetails;
				this.showDialog = false;
				this.profileEditDetails = JSON.parse(
					JSON.stringify(this.profileDetails)
				);
				localStorage.setItem(
					"profilesummaryData",
					JSON.stringify(this.profileDetails)
				);
        this.isLoading = false;
			},
			(err) => {
				//this.cms.loaderSet(false);
			}
		);
	}
}
