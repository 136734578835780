import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Subject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  
  Loader: Subject<any>;

  constructor(public http: HttpClient) {
    this.Loader = new Subject<any>();
  }
 

  loaderSet(status: any) {
    this.Loader.next({ status: status });
  }
 
  LoaderGet() {
    return this.Loader.asObservable();
  }

 
}
