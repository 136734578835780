import { Component, OnInit,ViewChild, HostListener, APP_ID, ElementRef } from '@angular/core';
import * as jsPDF from 'jspdf';
@Component({
  selector: 'app-offer-letter',
  templateUrl: './offer-letter.component.html',
  styleUrls: ['./offer-letter.component.css']
})
export class OfferLetterComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
offerLetter:any
  constructor() { }

  ngOnInit(): void {
this.offerLetter=JSON.parse(localStorage.getItem('offerLetter'))
  }
  makePdf() {
    let doc = new jsPDF();
    doc.addHTML(this.content.nativeElement, function () {
      doc.save("obrz.pdf");
    });
  }

}
