import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogcarousel',
  templateUrl: './blogcarousel.component.html',
  styleUrls: ['./blogcarousel.component.css']
})
export class BlogcarouselComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
