import { Component, OnInit ,OnDestroy,Input} from '@angular/core';

@Component({
  selector: 'app-view-test-report-summary',
  templateUrl: './view-test-report-summary.component.html',
  styleUrls: ['./view-test-report-summary.component.css']
})
export class ViewTestReportSummaryComponent implements OnInit {

  @Input() resultSummary: any;

  constructor() { }

  ngOnInit(): void {
  }

}
