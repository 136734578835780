import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { tenantConfig } from 'src/app/Models/url'

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor() { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const time = new Date().getTime();

        var gaipp_token = localStorage.getItem('token')
        if(gaipp_token)
        {
            gaipp_token = 'Bearer ' + gaipp_token;
        }
        
        req = req.clone({
            setHeaders: {
                'Authorization':gaipp_token || "",
                tenantConfig: JSON.stringify(tenantConfig)
            }
        });
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {

            },
                (err) => {


                })
        );
    }
}
