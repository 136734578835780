import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ProgressionService {

  constructor(private http: HttpClient) { }

  getCall(url) {
    return this.http.get(url)
  }
 
}
