import { Component, OnInit, Input } from '@angular/core';
import { APP_URLS } from '../../../Models/url';
import { TenantId } from '../../../Models/url';
import { UserService } from '../../../services/user.service';
import { MyProfileService } from '../../../services/my-profile.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  feedBackData = {
    aboutcourse: "",
    aboutstaff: "",
    courserating: "",
    staffrating: ""

  }
  queries = []
  rateoptions = []
  feedback_0: number[] = [1, 2, 3, 4, 5];
  courserating: number[] = [1, 2, 3, 4, 5];
  staffrating: number[] = [1, 2, 3, 4, 5];
  studentId:any
  @Input() feedbackId: any;
  getFeedBackDetailsData: any;
  constructor(
    private profileSvc: MyProfileService,
    private toast: UserService,
    private userService: UserService
  ) {

  }

  ngOnInit(): void {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.getFeedBackDetails()

  }
  submitfeedback() {
    var payload = {
      aboutcourse: this.feedBackData.aboutcourse,
      aboutstaff: this.feedBackData.aboutstaff,
      courserating: this.feedBackData.courserating.toString(),
      staffrating: this.feedBackData.staffrating.toString(),
      tenantId: TenantId,
      feedback_0: "5"
    }

    let url = APP_URLS.updatedFeedback + localStorage.getItem('feedbackId')
    this.profileSvc.postCallwithHeaders(url, payload)
      .subscribe((res: any) => {


      })
  }
  courseStar(star) {
    this.feedBackData.courserating = star;

  }
  feedBackStar(star, index) {
    this.feedBackData["feedback_" + index] = star;
    if (this.getFeedBackDetailsData.queries.length > 0) {
      this.queries.forEach((x, i) => {
        if (index == i) {
          x['feedback'] = star
        }
      })
    }
  }

  staffStar(star) {
    this.feedBackData.staffrating = star;

  }


  getFeedBackDetails() {
     let url = APP_URLS.feedbackDetails + localStorage.getItem('feedbackId')
    this.userService.getCallwithHeaders(url)
      .subscribe((res: any) => {
        this.getFeedBackDetailsData = res.feedbacks;
        this.queries = res.feedbacks.queries
        this.rateoptions = res.feedbacks.rateoptions
      })
  }


}
