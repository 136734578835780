import { Component, OnInit } from '@angular/core';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CertificatesService } from '../../services/certificates.service';
import { MyProfileService } from '../../services/my-profile.service';
import { UserService } from '../../services/user.service';
import { TenantId } from '../../Models/url';
import { Router } from '@angular/router';
// import html2canvas from 'html2canvas';
// import * as jspdf from 'jspdf';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-select-certificate',
  templateUrl: './select-certificate.component.html',
  styleUrls: ['./select-certificate.component.css']
})
export class SelectCertificateComponent implements OnInit {

  certificateforcourseid = "";
  studentId = "";
  selectcertificateData: any;
  startcount = 0;
  lastcount = 0;

  questionNumber = 0;
  start: boolean = false;
  QuestionAndAnswers = [];

  FinishedtheTest = false;
  test_type = "";

  test_time = {
    leftTime: 0
  }

  nextbtn = true

  verification = false;

  interval;

  timeout: boolean = false;

  resultaccordion = [];
  result: any;
  DisplayName = "";

  AnswerlaterQuistions = [];

  showAnswer: boolean = false;

  showStartButton: boolean = true;

  certificateTemplate: any;
  tenantSettingsData:any;
  isAnslaterView:boolean = true;
  showResultSummaryDialog: boolean = false;
  constructor(
    public service: CertificatesService,
    public profileservice: MyProfileService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private userSvc: UserService,
    private cms: CommonService
  ) { }

  ngOnInit(): void {
    this.cms.loaderSet(true);
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.DisplayName = JSON.parse(localStorage.getItem('profilesummaryData')).displayName;

    this.certificateforcourseid = localStorage.getItem('certificateForCourseId')
    this.getSelectCertificate('start');
    // this.getResult();
    this.getTenantSettings();

  }
  resultSummary:any;
  showResultSummary( resultData : any ){
    this.resultSummary  = [];
    this.resultSummary = resultData[this.test_type];
    window.scroll(0,0);
    this.showResultSummaryDialog = !this.showResultSummaryDialog;
  }
  async getTenantSettings() {
    let url = APP_URLS.tenantSettings
    await this.profileservice.getCallWithHeaders(url).subscribe((res: any) => {
       this.tenantSettingsData = res.details[0];
    })
  }

  gotoInitSection(){
    this.FinishedtheTest = false;
    this.verification = false;
    this.start = false;
    // this.getResult();
  }

  // getResult(type:any) {
  //   let url = APP_URLS.getResult + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"student","value":"' + this.studentId + '","type":"eq"},{"key":"coursetest","value":"' + this.certificateforcourseid + '","type":"eq"},{"key":"test_submitted_date","value":"","type":"ne"}]}&selectfields=_id,pass_mark,certificate_request,certificate_status,certificate_approval,iscertificate';
  //   this.service.getCallwithHeader(url).subscribe((res: any) => {
  //     this.resultaccordion = res.details;
  //     this.result = res.details[0];
  //     if(this.result){
  //       if(this.result.test_type == 'test' || this.result.test_type  == 'qatest'){
  //         if(this.result.status == 'Under Review' || this.result.status == 'Accepted'){
  //           this.showStartButton = false
  //         } else {
  //           this.showStartButton = true
  //         }
  //       }
  //       if(this.result.test_type  == 'quiz'){
  //         this.showStartButton = true
  //       }
  //     }
  //     // if( type != 'submitTest')
  //     this.gotoInitSection();
  //   })
  // }

  retest() {
    this.quistions = [];
    this.answers = [];
    this.startcount = 0;
    this.timeout = false
    this.start = false;
    this.nextbtn = true
    this.verification = false;
    this.FinishedtheTest = false;
    this.isAnslaterView = true;
    this.showAnswer = false;
    // this.getResult();
    this.getSelectCertificate('retest');
  }


  startClick() {
    this.start = true;
    this.isAnslaterView = true;
    this.questionNumber = 0;
    this.showAnswer = false;
    this.startcount = 1;
    this.test_time.leftTime = (this.selectcertificateData.test_time) * 60;
     this.timer(); 
  }

  timer() {
    this.interval = setInterval(() => {
      
      this.timeout = true;
      this.timeoutfinish()
   
  }, parseInt(this.selectcertificateData.test_time) * 60000)
  }


  RadioClick(data, val, i, mainindex) {
    this.selectcertificateData.student_answer[val.indexval] = data.key
    val.options_keyval[i]['cheked'] = true;
  }

  website = [];
  selected_shuffled = [];

  onCheckboxChange(e, val, data, i, mainindex) {
    if (e.target.checked) {
      this.website.push(e.target.value);
      val.options_keyval[i]['cheked'] = true;
    } else {
      var index = this.website.findIndex(x => x === e.target.value);
      val.options_keyval[index]['cheked'] = false;
      this.website.splice(index, 1);
    }
   
    this.website.sort();
    val.answerarry = this.website
    let answer = this.website.toString()
    
    this.selectcertificateData.student_answer[val.indexval] = answer;

  }

  getSelectCertificate( type : any ) {
    
    let url = APP_URLS.coursetest + 'from=examinner&student=' + this.studentId + '&examid=' + this.certificateforcourseid + '&tenantId=' + TenantId
    

    this.service.getCallwithHeader(url)
      .subscribe((data: any) => {
        this.selectcertificateData = data.details[0];
        this.test_type = data.details[0].test_type;
        this.lastcount = this.selectcertificateData.question.length;
        this.selected_shuffled = this.selectcertificateData.selected_shuffled
        data.details[0].question.forEach((x,i)=>{
          if( x ){
            x['indexval'] = i
            if(x.questiondescription){
              x.questiondescription = this.sanitizer.bypassSecurityTrustHtml(x.questiondescription)
            }
            this.quistions.push(x)
          }
        })

        // this.resultaccordion = data.details[0].report[0].all;
        this.resultaccordion = [];
        if( data.details[0].report.length > 0 ){
          if( data.details[0].report[0].all ){
            data.details[0].report[0].all.sort((a, b) => {
              return <any>new Date(b.test_submitted_date) - <any>new Date(a.test_submitted_date);
            });
            data.details[0].report[0].all.forEach((x, i) => { 
              x['accordion'] = '#mytestaccordion' + i
              x['accordionID'] = 'mytestaccordion' + i;
              this.resultaccordion.push(x);
            })
          }
        this.result = data.details[0].report[0];
        }
        if(this.result){
          if(data.details[0].test_type == 'test' || data.details[0].test_type  == 'qatest'){
            if(this.result.status == 'Under Review' || this.result.status == 'Accepted'){ 
              this.showStartButton = false
            } else {
              this.showStartButton = true
            }
          }
          if(data.details[0].test_type  == 'quiz'){
            this.showStartButton = true
          }
        }
        if( type == 'retest'){
          this.startClick()
        } else if( type == 'submitTest'){
          this.gotoInitSection();
        }

        this.cms.loaderSet(false);
      },err=>{
        this.cms.loaderSet(false);
      })

  }


  getCertificate(data){    
    let url = APP_URLS.certificateTemplate;
     this.service.getCallwithHeader(url).subscribe((res: any) => {
       var snippet = document.createElement("div");
       snippet.innerHTML = res.resTemp.template_text_view;
      
       Array.from(snippet.querySelectorAll('img')).forEach(function (ele) {
         
         var text = ele.src 
         var text2=ele.src
         let logo = tenantInfo.logoUrl;
         let backgroundlogo = '../../../assets/images/certificatebg.png'
         let val = location.origin+"/[sitelogo]"
         let val2 = location.origin+"/[backgroundlogo]"
         if (ele.src == val) {
           text = text.replace(text, logo)
           ele.src = text
  
         }
         if (ele.src == val2) {
           text = text.replace(text2, backgroundlogo)
           ele.src = text
  
         }
         
       });
       Array.from(snippet.querySelectorAll('p')).forEach(function (ele) {
         var text = ele.textContent
         var text2 = ele.textContent
         var name = JSON.parse(localStorage.getItem('profilesummaryData')).displayName;
         const date = new Date()
         if (ele.textContent == "[studentname]") {
           text = text.replace(text, name)
           ele.textContent = text
  
         }
         if (ele.textContent == "[taskname] test" ) {
           text = text.replace(text2, data.name)
           ele.textContent = text
  
         }
       });
       Array.from(snippet.querySelectorAll('td')).forEach(function (ele) {
         var text3 = ele.textContent
         var text = ele.textContent
         const date = moment().format("YYYY-MM-DD")
         if (ele.textContent == "[date]") {
           text = text.replace(text3, date)
           ele.textContent = text
         }
  
       });
       res.resTemp.template_text_view = snippet.innerHTML
       this.certificateTemplate =  this.sanitizer.bypassSecurityTrustHtml(res.resTemp.template_text_view);
     })
    }

  quistions = [];
  answers = [];

  // shuffled = []

  // shuffle(array) {

  //   var random_question_limit = array.random_question_limit;

  //   var array_a = Array.from({ length: random_question_limit }, (v, k) => k * 1);

  //   this.shuffled = array_a.sort(function () { return .5 - Math.random() });
  //   for (let i = 0; i < this.shuffled.length; i++) {
  //     array.test[this.shuffled[i]].org_id = this.shuffled[i];
  //     this.quistions.push(array.test[this.shuffled[i]])
  //     array.test_answer[this.shuffled[i]].answer = array.test_answer[this.shuffled[i]].answer.toUpperCase();
  //     this.selectcertificateData.student_answer.push(array.test_answer[this.shuffled[i]])

  //   }
  //   this.studentSubmitTest()
  // }


  // studentSubmitTest() {
  //   var payload = {
  //     test: [],

  //   }

  //   for (let i = 0; i < this.quistions.length; i++) {
  //     payload.test[i] = {}
  //     payload.test[i] = this.quistions[i];

  //     payload.test[i].answer = this.selectcertificateData.student_answer[i].answer;

  //     payload.test[i].why_correct_answer = this.selectcertificateData.student_answer[i].why_correct_answer;

  //     payload.test[i].student_answer = "";
  //   }
  

  // }




  nextQuestion(question, questionNumbernumber) {

    if(question.question_type == "radio" || question.question_type == "T/F"){
     if(this.selectcertificateData.student_answer[question.indexval]== "") {
      if( this.test_type == 'test'){
       this.service.showError("Select a answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question");
      } else {
        this.service.showError('Select a answer for this question and then go to next question (or) You can click on the "See Answer" button, see answer and mark it then goto next question');
      }
       return;
     }
    }

    if(question.question_type == "multiple"){

      if(this.selectcertificateData.student_answer[question.indexval] != "" ) {
        var answerArray = this.selectcertificateData.student_answer[question.indexval].split(',');
        
        if( answerArray.length < 2){
          this.service.showError("Select more than one answer and then go to next question");
          return;
        }
      } else {
        this.service.showError("Select more than one answer and then go to next question");
        return;
      }
      // if(this.selectcertificateData.student_answer[question.indexval]== "" || this.selectcertificateData.student_answer[question.indexval].length > 1 ) {
      //   this.service.showError("Select more than one answer and then go to next question");
      //   return;
      // }
     }
     if( this.test_type == 'qatest'){
      if(this.selectcertificateData.student_answer[question.indexval]== "") {
        this.service.showError("Answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question");
        return;
      }
     }
    this.showAnswer = false;

    if (this.questionNumber < this.quistions.length - 1) {
      this.questionNumber = this.questionNumber + 1;
      this.startcount = this.startcount + 1;

    }
    this.website = [];
    if (this.questionNumber == this.quistions.length - 1) {
      this.nextbtn = false
    }
  }


  previouesQuestion(question) {

    if (this.questionNumber == this.quistions.length - 1) {
      this.nextbtn = true
    }

    if (this.questionNumber > 0 && this.verification == false && this.startcount > 0) {
      this.questionNumber = this.questionNumber - 1;
      this.startcount = this.startcount - 1;
    }

    if (this.verification == true) {
      this.questionNumber = this.quistions.length - 1;
      this.startcount = this.quistions.length;
      this.verification = false
    }
    
    if( this.selectcertificateData.student_answer[question.indexval - 1] ){
      this.website = this.selectcertificateData.student_answer[question.indexval - 1].split(',');
    }
  }

  Finish(data, frmContext) {
    var isAnyNotAnswer = false;
    if( frmContext == 'finishBtn' ){
      if( this.selectcertificateData.student_answer[data.indexval] == ''){
        isAnyNotAnswer = true;
      }
    }
    if(isAnyNotAnswer){
      if( this.test_type == 'qatest'){
        this.service.showError("Answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question")
      } else if( this.test_type == 'quiz'){
        this.service.showError('Select a answer for this question and then go to next question (or) You can click on the "See Answer" button, see answer and mark it then goto next question')
      } else {
        this.service.showError("Select a answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question")
      }
    } else {
      var Temp_AnswerLaterQuestion = JSON.parse(JSON.stringify(this.AnswerlaterQuistions));
      Temp_AnswerLaterQuestion.forEach((x) => {
        if( this.selectcertificateData.student_answer[x.indexval] != '' ){
          this.removeArrayValues(x.indexval);
        }
      })
      if( ! this.AnswerlaterQuistions.length ){
        this.quistions = this.selectcertificateData.question;
      }
      this.questionNumber = this.quistions.length
      this.startcount = this.quistions.length
      this.verification = true;
      this.isAnslaterView = false;
    }
  }

  timeoutfinish(){
    this.questionNumber = this.quistions.length
    this.startcount = this.quistions.length
    this.verification = true;
  }

  answerLaterClick(quistion, num) {

    this.website = [];
    this.selectcertificateData.student_answer[num] = ""
    quistion.options_keyval.forEach(x => {
      if (x.cheked && x.cheked == true) {
        x.cheked = false
      }
    })
    let alreadyinarray = this.AnswerlaterQuistions.find(y => y.question == quistion.question)

    if (alreadyinarray) {

    }
    else {
      this.AnswerlaterQuistions.push(quistion);
    }

    if (this.questionNumber < this.quistions.length - 1) {
      this.questionNumber = this.questionNumber + 1;
      this.startcount = this.startcount + 1;
    }
    if (this.nextbtn == false) {
      this.Finish(quistion,'answerLaterBtn');
    }

    if (this.questionNumber == this.quistions.length - 1) {
      this.nextbtn = false
    }
  }

  answerLaterQuestions() {
    this.showDialogNotAnswer = false;
    this.startcount = 1;
    this.lastcount = this.AnswerlaterQuistions.length;
    this.quistions = this.AnswerlaterQuistions;
    this.questionNumber = 0;
    this.verification = false;
    this.nextbtn = ( this.AnswerlaterQuistions.length > 1 ) ? true : false;
    this.isAnslaterView = false;
  }

  seeAnswer(data, num) {
    this.showAnswer = !this.showAnswer
  }
  payload = {};
  showDialogNotAnswer:boolean = false;
  showSubmitConfirmDialog : boolean = false;

  continueTest(){
    this.showDialogNotAnswer = false;
    this.showSubmitConfirmDialog = false;
  }

  removeArrayValues( index ){
    for( var i = 0; i < this.AnswerlaterQuistions.length; i++ ){
      if( this.AnswerlaterQuistions[i].indexval == index ){
        this.AnswerlaterQuistions.splice(i,1);
      }
    }
  }

  FinishTest() {
    var Temp_AnswerLaterQuestion = JSON.parse(JSON.stringify(this.AnswerlaterQuistions));
    Temp_AnswerLaterQuestion.forEach((x) => {
      if( this.selectcertificateData.student_answer[x.indexval] != '' ){
        this.removeArrayValues(x.indexval);
      }
    })
    window.scroll(0,0);
    if( this.AnswerlaterQuistions.length ){
      this.showDialogNotAnswer = true;
    } else {
      this.showSubmitConfirmDialog = true;
    }
  }
  
  submitTest(){
    let url = APP_URLS.studentsubmit;
      if(this.test_type == 'test'){
        this.payload = {
          coursetest: this.certificateforcourseid,
          student: this.studentId,
          student_answer: this.selectcertificateData.student_answer,
          selected_shuffled: this.selected_shuffled
        }
      }
      else{
        this.payload = {
          coursetest: this.certificateforcourseid,
          student: this.studentId,
          student_answer: this.selectcertificateData.student_answer
        }
      }
    
      this.service.putCallwithHeader(url, this.payload).subscribe((res: any) => {
        if (res.respCode) {
          clearInterval(this.interval);
          // this.getResult('submitTest');
          // this.FinishedtheTest = true

          if(this.test_type == 'test'){
            this.service.showSuccess('Your Test is submitted successfully. For the result see your test report below');
          } else if(this.test_type == 'qatest'){
            this.service.showSuccess('Your Test is submitted successfully. Course team will review and send you the test report through email and notification');
          } else if(this.test_type == 'quiz'){
            this.service.showSuccess('Your Quiz is submitted is successfully. For the result see your test report below.');
          }

          this.getSelectCertificate('submitTest');
        }
        else{
          this.service.showError(res.errorMessage) ;
        }
        this.showSubmitConfirmDialog = false;

      })
  }

  resendRequestCertificate(){
    this.service.showSuccess('Your request for the certificate is already sent and waiting for the approval from the course department. For further information, you may contact your admin');
    // this.getResult('resendRequestCertificate');
  }

  requestCertificate() {
    // let url = APP_URLS.chaptersteststudent + this.result._id;
    let url = APP_URLS.oldApi.CertificateRequest  + this.result._id;
    let Payload = {
      certificate_request: true
    }
    this.service.putCallwithHeader(url, Payload).subscribe((res: any) => {
      // if (res.respCode) {
      //   this.service.showSuccess(res.respMessage)
      //   this.getResult();
      // }
      if(typeof res.toast != 'undefined'){
        if(res.toast.type == 'success'){
          this.userSvc.showSuccess(res.toast.message);
          // this.getResult('requestCertificate');
          this.getSelectCertificate('requestCertificate');
        }
      }
      if(typeof res.errorMessage != 'undefined'){
        this.userSvc.showError(res.errorMessage);
      }
    })
  }

  data: any
  download() {
    var certificateType = '', st='';
    if( this.test_type == 'test'){
      certificateType = 'Test Certificate - Test'
    } else if( this.test_type == 'qatest' ){
      certificateType = 'Test Certificate - Test QA'
    }
    if(this.result){
      this.result?.all.forEach(data => {
        st = data.status_level;
      })
    }
    var obj = {
          studentname : this.DisplayName,
          course : this.selectcertificateData?.name,
          certificate_id : this.certificateforcourseid,
          updated : this.result?.test_submitted_date,
          signature : this.tenantSettingsData?.settings?.signature,
          certificate_type : certificateType,
          status_level: st || ''

    }
    localStorage.setItem('certificates',JSON.stringify(obj))
    this.router.navigate([]).then(result => {  window.open( `/opencertificate`, '_blank',); });
  }



}
