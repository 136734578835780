
import { Component, OnInit,Output, OnDestroy,ViewChild,ElementRef,EventEmitter  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../../Models/url';
import { CourseService } from '../../../services/course.service';
import { UserService } from '../../../services/user.service';
import { MyProfileService } from '../../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-qualification-modal',
  templateUrl: './add-qualification-modal.component.html',
  styleUrls: ['./add-qualification-modal.component.css']
})
export class AddQualificationModalComponent implements OnInit {

  @Output()
  notify:EventEmitter<string> = new EventEmitter<string>()


  @ViewChild('takeInput', {static: false})
  InputVar: ElementRef; 
  fileToUpload: any;
  fileName: any;
  fileSize: any;
  studentId: any;
  qualification = {
    name: "",
  }
  interval;

  timeout: boolean = false;
  waitingFlag:boolean=false
  constructor(private profileSvc: MyProfileService,private toast:UserService) { 
    
  }
  ngOnInit(): void {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.profileSvc.subClearAction().subscribe((status:any)=>{
      this.InputVar.nativeElement.value = ""; 
      this.fileName = "";
      this.qualification.name=""
    })
    
  }
  handleFileInput(files) {
    this.fileName =""
    this.waitingFlag=true
    this.fileSize = files[0].size
    const formData = new FormData();
    formData.append('images', files[0]);
    var url = APP_URLS.fileUpload + '&frmSrc=qualifiction'
    this.profileSvc.postCallImg(url, formData)
      .subscribe((data: any) => {
        this.waitingFlag=false
        if(data.errorMessage) {
          this.toast.showError(data.errorMessage)
        } else if(data.message){
          this.fileName = "";
          this.toast.showError(data.message)
        } else {
          if( data.length )
            this.fileName = data[0].name
          this.toast.showSuccess('Your chosen file is attached successfully. Now click on upload or upload & next button')
        }       
       
      }, err => {
        this.waitingFlag=false
        this.fileName=""
        this.toast.showError(err.errorMessage)
      })
  }

  reset()  
  { 
    this.InputVar.nativeElement.value = ""; 
  }
 async addQualification() {
    let payload = {
      attributes: {}
    };

    let attributes = {
      attachments: [this.fileName],
      attachments_size: this.fileSize,
      confidential: "N",
      document_name: "",
      name: this.qualification.name,
      formname: "qualification"
    }
    payload.attributes = attributes;

    let url = APP_URLS.addQualifications + `{"event_type":"ATTACHMENT","context_id":"` + this.studentId + `","context_type":"STUDENT"}`
   await this.profileSvc.postCall(url, payload)
      .subscribe((data: any) => {
         this.toast.showSuccess('Your attached file is uploaded successfully')
        this.notify.emit('Adding Qualification'); 
        this.profileSvc.setTab({ tabName : "Qualification", isRefreshPermission : false })
        this.qualification.name=""
        this.fileName = ""
        this.reset();
        this.profileSvc.setPopup(false);  
              
      },err=>{
        this.toast.showError(err.errorMessage)
      })

  }
  next(){
    this.addQualification()
    setTimeout(()=>{       
      this.profileSvc.setTab({ tabName : "Employement", isRefreshPermission : false })
      this.profileSvc.setPopup(false); 
     }, 3000);
  }

}
