import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-portfolio-evidence-submit',
  templateUrl: './portfolio-evidence-submit.component.html',
  styleUrls: ['./portfolio-evidence-submit.component.css']
})
export class PortfolioEvidenceSubmitComponent implements OnInit {

  Evidence = {
    name: '',
    src: ''
  }

  comment: '';
  formname: '';

  evidence_submit_attach_arr = []

  attachmentview: boolean = true;

  fileToUpload: any;
  fileName: any;
  fileSize: any;
  studentId: any;
  FILE_PATH_LINODE=APP_URLS.FILE_PATH_LINODE

  ID = "";
  p = 1;
  countPagination = 5;

  portfolioEvidence: any;
  status: any;
  assignmentDetail = []


  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(private profileSvc: MyProfileService, public service: CourseService, private toast: UserService) { }

  ngOnInit(): void {
    
    this.Evidence = {
      name: '',
      src: ''
    }
    this.service.getupdatepopup().subscribe(data => {

      this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
      this.portfolioEvidence = JSON.parse(localStorage.getItem('portfolioEvidence'))
     

      if (this.portfolioEvidence) {
        this.status = this.portfolioEvidence.evidence_status
        this.ID = localStorage.getItem('courseId')
      }

      if (this.portfolioEvidence.assignmentDetail) {
        this.assignmentDetail = this.portfolioEvidence.assignmentDetail
        this.p=1

      }

    })

  }

  handleFileInput(files) {
    this.fileSize = files[0].size
    const formData = new FormData();
   
    formData.append('images', files[0]);
    this.profileSvc.postCallImg(APP_URLS.evidence_fe, formData)
      .subscribe((data: any) => {
        if(data.errorMessage)
        {
          this.toast.showError(data.errorMessage)
        }
        this.formname = data[0].name,
        this.toast.showSuccess('Your chosen file is attached successfully. Now click on save button.')
      }, err => {
        this.toast.showError(err.errorMessage)
      })
  }

  addmore() {
    this.Evidence = {
      name: '',
      src: ''
    }
    this.attachmentview = !this.attachmentview
  }


  save() {
    let payload = {
      name: this.Evidence.name,
      src: this.formname
    }
    this.evidence_submit_attach_arr.push(payload)
    this.Evidence.name = ""
    this.Evidence.src = ""
    this.attachmentview = false;
  }

  edit(i) {
    this.attachmentview = true;
    this.Evidence = {
      name: this.evidence_submit_attach_arr[i].name,
      src: this.evidence_submit_attach_arr[i].src
    }
    this.evidence_submit_attach_arr.splice(i,1);
  }
  delete(i) {
    this.evidence_submit_attach_arr.splice(i,1);
  }

  submit() {
    if (this.evidence_submit_attach_arr.length == 0) {
      this.toast.showError("Minimum one attachment is Required")
      return;
    }
    if (this.Evidence.name  != '') {
      this.toast.showError("File uploaded, save it");
      return;
    }
    if (this.comment == '' || this.comment == undefined) {
      this.toast.showError("Comment is required")
      return;
    }
    let url = APP_URLS.AssignmentsApi;
    let payload = {
      test_submitted_date: new Date(),
      student: this.studentId,
      course: this.ID,
      student_comments: this.comment,
      exam_type: 'general_portfolio', 
      review_status: 'UR', 
      review_iv_status: 'UR', 
      general_portfolio_id: this.portfolioEvidence._id,
      general_portfolio_submit: this.evidence_submit_attach_arr

    }
    this.profileSvc.postCallwithHeaders(url, payload).subscribe((res: any) => {
    
      if (res.respCode) {
        this.toast.showSuccess('Your evidence is submitted successfully. Course team will review and update you through email and notification')
        this.formname=""
        this.service.setsubmitEvidencepopup("test")
        this.profileSvc.setPopup(false);
      }

    })
  }


}
