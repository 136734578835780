import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service'
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TenantId } from '../../Models/url';
@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  p: number = 1;
  ID: any;
  studentId: any
  progressionList: []
  testCourse: []
  portfolioDetails = [];
  sampleEvidence: boolean = false;
  sampleSubmit: boolean = false;
  selected: {};
  selectSubmit: {};
  assignment_report = [];
  evidenceSample:any
  evidence_samplehtml:any
  evidenceSamplepopupopen= 'none';

  showDialog: boolean = false;



  constructor(private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: MyProfileService,
    
    private cms: CommonService,
    private sanitizer: DomSanitizer
  ) {

    this.ID = localStorage.getItem('courseId')

  }

  ngOnInit(): void {
   
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
   
    this.service.getsubmitEvidencepopup().subscribe(data => { 
      this.getData();
    })
    this.getData();
  }
  evidenceSamplePopupOpen(data) {
   
    this.evidenceSamplepopupopen = 'block';
   
    this.evidenceSample = data
    this.evidence_samplehtml=this.sanitizer.bypassSecurityTrustHtml(data.evidence_sample);
  }
  evidenceSamplePopupClose() {
    this.evidenceSamplepopupopen = 'none'
  }

  getData() {

    let url = APP_URLS.chapters + '{"sortfield":"sort_position","direction":"asc","criteria":[{"key":"course","value":"' + this.studentId + '","type":"eq"},{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}';

    this.userSvc.getCallWithHeaders(url)
      .subscribe((res: any) => {
       
        this.getPortfolio()
      })

  }

  async getPortfolio() {
    
    let url = APP_URLS.portfolio_of_evidence + '[{"key":"courses_id","value":["' + this.ID + '"],"type":"in"},{"key":"tenantId","value":"' + TenantId + '","type":"eq"}]}'
    await this.service.getCallwithHeader(url).subscribe((res: any) => {
      
      this.portfolioDetails = res.generalportfolio_list;
      this.getAssignments();
    })
    
  }

  evidence(data) {
    this.sampleEvidence = !this.sampleEvidence;
    this.selected = data
  }

  submit(res) { 
    this.sampleSubmit = !this.sampleSubmit;
    this.sampleSubmit = res
  }

  getAssignments() {
    let url = APP_URLS.assignments + '[{"key":"student","value":"' + this.studentId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"},{"key":"tenantId","value":"'+ TenantId +'","type":"eq"}]}'
    this.service.getCallwithHeader(url)
      .subscribe((data: any) => {
        this.assignment_report = data.assignments;
        this.combineData();

      })
  }


  combineData() {
    
    this.portfolioDetails.forEach((x: any, i) => {
      var Combiledataarray = [];
      x['assignmentDetail'] = []
      x['evidence_status'] = "";
      this.assignment_report.forEach(y => {
        if (x._id == y.general_portfolio_id) {
          x.evidence_status = y.review_status
          Combiledataarray.push(y)
          x.assignmentDetail.push(y)
        }

      })

      if (Combiledataarray.length > 0) {

      }
      else {

        x.evidence_status = "pending";
      }

    })

   

  }


  poenPopup(data) {
    localStorage.setItem('portfolioEvidence', JSON.stringify(data))
     this.service.setupdatepopup("test")
    this.showDialog = !this.showDialog;
  }



}
