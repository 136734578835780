import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_URLS } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-student-channel-account',
  templateUrl: './student-channel-account.component.html',
  styleUrls: ['./student-channel-account.component.css']
})
export class StudentChannelAccountComponent implements OnInit {
  addChannelForm: FormGroup;
  channelList: any[] = [];
  isChannelListView = true;
  channelName = '';
  singleChannelDetails: any[] = [];
  isSingleChannelDetailView = false;
  isLoading = false;
  isSubmitted = false;


  totalItem = 0;
  countPagination = 10;
  page = 1;

  get addChannelControls(){
    return this.addChannelForm.controls;
  }

  constructor(private fb: FormBuilder, public service: MyProfileService,private sanitizer: DomSanitizer) {
    this.addChannelForm = this.fb.group({
      username: this.fb.control('', Validators.required),
      password: this.fb.control('', Validators.required),
      profile_link: this.fb.control(''),
      recovery_info: this.fb.control(''),
      operation_id: this.fb.control(''),
      channel_id: this.fb.control('')
    });
   }

  ngOnInit(): void {
    this.getChannelDetails();
  }

  getChannelDetails() {
    this.isLoading = true;
    this.isSingleChannelDetailView = false;
    this.isChannelListView = true;
    this.channelList = [];
    let url = APP_URLS.v1.channelList + '?filter={"page":'+ this.page + ',"limit":'+ this.countPagination+'}';
    this.service.getCallWithHeaders(url)
    .subscribe((res: any) => {
      this.channelList = res.list;
      if( res.pagination && res.pagination.totalRecords ){
        this.totalItem = res.pagination.totalRecords;
      }
      this.isLoading = false;
    });
  }

  changePage(e: any): void{
    document.documentElement.scrollTop = 0;
  }

  channelDetails = {
    _id : ''
  };

  viewChannelInfo = null;

  viewChannelInfoModalOpen( channel :any ) : void {
    this.channelName = channel.name
    this.viewChannelInfo = channel;
    this.viewChannelInfo.how_to_use = this.sanitizer.bypassSecurityTrustHtml(this.viewChannelInfo.how_to_use)
  }

  viewchannelDetail(channel): void{
    this.channelDetails = channel;
    this.isLoading = true;
    this.isChannelListView = false;
    this.isSingleChannelDetailView = true;
    this.channelName = channel.name
    this.getSingleChannelInfo(channel._id);
  }

  viewChannelOperation = null;
  viewChannelOperationInfoModal( sc :any ) : void {
    this.viewChannelOperation = sc;
  }

  getSingleChannelInfo( id:any ){
    let url = APP_URLS.v1.channelById + `?channel_id=${id}`;
    this.service.getCallWithHeaders(url)
    .subscribe((res: any) => {
      this.singleChannelDetails = res.list;
      this.isLoading = false;
    });
  }

  back(): void{
    this.isSingleChannelDetailView = false;
    this.isChannelListView = true;
    this.singleChannelDetails = [];
    this.getChannelDetails();
  }

  selectChannelAccData = {
    addorview: undefined
  };
  viewChannelModalOpen(data: any): void{
    this.selectChannelAccData = data;
  }

  isObject(val : any ): boolean {
    return typeof val.addorview === 'object' && val.addorview !== null;
  }

  addChannelModalOpen(sc: any): void{
    this.addChannelForm = this.fb.group({
      username: this.fb.control(''),
      password: this.fb.control(''),
      profile_link: this.fb.control(''),
      recovery_info: this.fb.control(''),
      operation_id: this.fb.control(sc._id),
      channel_id: this.fb.control(this.channelDetails?._id)
    });
  }

  addChannel(): void{
    this.isSubmitted = true;
    if (this.addChannelForm.invalid){
      return;
    }
    let url = APP_URLS.v1.channelCreate;
    this.service.postCallwithHeaders(url, this.addChannelForm.value)
    .subscribe((res: any) => {
      this.isLoading = true;
      this.getSingleChannelInfo(this.channelDetails?._id);
      document.getElementById('channelAccClose').click();
      this.addChannelForm.reset();
    });
    this.isSubmitted = false;
  }

  linkOpen(): void{}

}
