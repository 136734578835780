import { Component, OnInit, OnDestroy, Input, Output, OnChanges, EventEmitter, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../services/common.service'
import { TenantId } from '../../Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('f') form: any;
  tenantInfo:any = tenantInfo;
  showDialogReg = false;
  student_Id = '';
  activeToken: boolean = false;
  userRole:any;
  subscribe={
    email:""
  }
  emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/;

  constructor(private router: Router,
    private profileSvc:MyProfileService,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');

    var userDetails = localStorage.getItem('userDetails');
    if( userDetails != undefined && typeof userDetails == 'string' ){
      var profileData = JSON.parse(userDetails);
      this.student_Id = profileData._id;
    }

    if (localStorage.getItem('token') && this.student_Id ) {
      this.activeToken = true
    } else {
      this.activeToken = false
    }
  }

  submit(){
    let payload={
      email: this.subscribe.email,
      first_name: this.subscribe.email.split('@')[0],
      last_name: this.subscribe.email.split('@')[0],
      tenantId: TenantId, 
      from_place: 'newsletter'
    }
    let url = APP_URLS.newlatter;
    
    this.userSvc.postCall(url,payload)
    .subscribe((data:any)=>{
      this.userSvc.showSuccess(data.respMessage);
      this.form.resetForm();
    })
  }
  profile() {
    if( this.userRole == 'employer'){
      this.router.navigate(['/employer-profile/emp-profile-summary'])
    } else {
      this.router.navigate(['/profile'])
    }
  }
}
