import { Component, OnInit } from '@angular/core';
import { tenantInfo } from 'src/app/Models/url';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-home-students',
  templateUrl: './home-students.component.html',
  styleUrls: ['./home-students.component.css']
})
export class HomeStudentsComponent implements OnInit {
  tenantInfo: any = tenantInfo;
  showDialogReg = false;
  token:boolean=false;
  userDetail: any;
  constructor(private userSvc:UserService) { }

  ngOnInit(): void {


    this.userDetail=JSON.parse(localStorage.getItem('profilesummaryData'))
    this.userSvc.getIsLogin().subscribe(data => {
        this.token = data;

    })

    if(localStorage.getItem('token')){
      this.token=true

    }

  }

}
