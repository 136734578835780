import { Component, OnInit ,Input} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-evidence-sample-model',
  templateUrl: './evidence-sample-model.component.html',
  styleUrls: ['./evidence-sample-model.component.css']
})
export class EvidenceSampleModelComponent implements OnInit {
  @Input() details:any;
  evidence_sample:any
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.evidence_sample=this.sanitizer.bypassSecurityTrustHtml(this.details.evidence_sample);
  }

  
}
