import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { MyProfileService } from '../../services/my-profile.service';
import { CommonService } from '../../services/common.service'
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { TenantId } from '../../Models/url';
// import html2canvas from 'html2canvas';
// import * as jspdf from 'jspdf';
import * as moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-course-exam',
  templateUrl: './course-exam.component.html',
  styleUrls: ['./course-exam.component.css']
})
export class CourseExamComponent implements OnInit {

  @Input()testData : any;
  @Input()chapterDetails : any;
  ExamData :any
  certificateforcourseid = "";
  studentId = "";
  selectcertificateData: any;
  startcount = 0;
  lastcount = 0;
  show: boolean = false

  questionNumber = 0;
  start: boolean = false;
  QuestionAndAnswers = [];

  FinishedtheTest = false;
  test_type = "";

  test_time = {
    leftTime: 0
  }

  nextbtn = true

  verification = false;

  interval;

  timeout: boolean = false;

  resultaccordion = [];
  result: any;

  DisplayName = "";

  AnswerlaterQuistions = [];

  showAnswer: boolean = false;

  showStartButton: boolean = true;
  chapter_Id: any;
  ID: any;

  certificateTemplate: any;
  description: any;
  uuid: string;
  tenantSettingsData:any;
  isAnslaterView:boolean = true;
  loadingFlag : boolean = false;
  showResultSummaryDialog: boolean = false;
  detailRecivedSubscription: Subscription;
  courseIdFromRoute:any;

  constructor(private toastr: ToastrService, public profileservice: MyProfileService,
    public service: CourseService, private router: Router,
    private activeRoute: ActivatedRoute,private sanitizer: DomSanitizer) {

    this.courseIdFromRoute = this.activeRoute.snapshot.params.id;
    this.detailRecivedSubscription = this.service.getopenexam().subscribe(data => {
      if (data) {
        this.testData = data
        this.ngOnInit()
      }
    })

    this.getTenantSettings();
   }

  ngOnDestroy() {
    this.detailRecivedSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.loadingFlag = true;
    localStorage.setItem('uuid', this.testData.uuid)
    this.uuid = this.testData.uuid
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.DisplayName = JSON.parse(localStorage.getItem('profilesummaryData')).displayName;
    this.ID = localStorage.getItem('courseId')
    localStorage.setItem('certificateForCourseId',this.testData._id);
    this.certificateforcourseid = this.testData._id
    // this.certificateforcourseid = localStorage.getItem('certificateForCourseId');
    this.chapter_Id = localStorage.getItem('chapterID');

    this.getSelectCertificate('start');
    // this.getResult();

  }

  resultSummary:any;
  showResultSummary( resultData : any ){
    this.resultSummary  = [];
    window.scroll(0,0);
    this.resultSummary = resultData[this.test_type];
    this.showResultSummaryDialog = !this.showResultSummaryDialog;
  }

  async getTenantSettings() {
    let url = APP_URLS.tenantSettings
    await this.profileservice.getCallWithHeaders(url).subscribe((res: any) => {
       this.tenantSettingsData = res.details[0];
    })
  }

  begin(){
    this.quistions = [];
    this.answers = [];
    this.startcount = 0;
    this.lastcount = 0;
    this.questionNumber = 0;
    this.timeout = false
    this.start = false;
    this.nextbtn = true
    this.verification = false;
    this.FinishedtheTest = false;
  }

  toggle() {
    this.show = !this.show
  }


  getResult() {
    this.ExamData.report.sort((a, b) => {
      return <any>new Date(b.test_submitted_date) - <any>new Date(a.test_submitted_date);
    });
    this.resultaccordion = [];
    this.result = this.ExamData.report[0];
    this.ExamData.report.forEach((x, i) => {
      var uniqueID = Date.now();
      x['accordion'] = '#courseexamaccordion' + i + uniqueID;
      x['accordionID'] = 'courseexamaccordion' + i + uniqueID;
      this.resultaccordion.push(x);
    })
    this.loadingFlag = false;
  }



  resendRequestCertificate(){
    this.service.showSuccess('Your request for the certificate is already sent and waiting for the approval from the course department. For further information, you may contact your admin');
    // this.getResult();
  }

    retest() {
      this.quistions = [];
      this.answers = [];
      this.startcount = 0;
      this.lastcount = 0;
      this.questionNumber = 0;
      this.timeout = false
      this.start = false;
      this.nextbtn = true
      this.verification = false;
      this.FinishedtheTest = false;
      this.isAnslaterView = true;
      this.showAnswer = false;
      this.getSelectCertificate('retest');
      this.getResult();
    }


  startClick() {
    this.start = true;
    this.questionNumber = 0;
    this.startcount = 1;
    this.isAnslaterView = true;
    this.showAnswer = false;
    this.test_time.leftTime = (this.selectcertificateData.test_time) * 60;
    if(this.test_type != "quiz"){
      this.timer();
    }
  }

  timer() {
    this.interval = setInterval(() => {
      this.timeout = true;
      this.timeoutfinish()
  }, parseInt(this.selectcertificateData.test_time) * 60000)
  }


  RadioClick(data, val, i, mainindex) {
    this.selectcertificateData.student_answer[val.indexval] = data.key
    val.options_keyval[i]['cheked'] = true;
  }

  website = [];
  onCheckboxChange(e, val, data, i, mainindex) {
    if (e.target.checked) {
      this.website.push(e.target.value);
      val.options_keyval[i]['cheked'] = true;
    } else {
      var index = this.website.findIndex(x => x === e.target.value);
      val.options_keyval[index]['cheked'] = false;
      this.website.splice(index, 1);
    }


    this.website.sort();
    val.answerarry = this.website
    let answer = this.website.toString()
    this.selectcertificateData.student_answer[val.indexval] = answer

  }

  gotoInitSection(){
    this.FinishedtheTest = false;
    this.verification = false;
    this.start = false;
    // this.getResult();
  }
  termDetails : any;
  getSelectCertificate( type : any ) {
    let url;
    if( this.courseIdFromRoute != '' ){
      url = APP_URLS.lmsInner + this.uuid + '&course=' + this.courseIdFromRoute
    } else {
      url = APP_URLS.lmsInner + this.uuid
    }

    this.service.getCallwithHeader(url).subscribe(
      (res: any) => {
        this.quistions = [];
        this.termDetails = res.courseTermDetail;
        res.chapters_a.forEach(x=>{
          if(x.uuid == this.testData.uuid){
            this.selectcertificateData = x;
            this.ExamData = x;
            this.description =  this.sanitizer.bypassSecurityTrustHtml(this.ExamData.description)
            this.test_type = this.ExamData.chapter_type;
            this.lastcount = this.ExamData.question.length;
            // this.answers = this.ExamData.student_answer
            this.answers = this.ExamData.student_answer;
            this.ExamData.question.forEach((x, i) => {
              if( x ){
                x['indexval'] = i
                this.quistions.push(x)
              }
            })
          }
        })
        this.getResult();
        if( type == 'retest'){
          this.startClick()
        } else if( type == 'submitTest'){
          this.gotoInitSection();
        }
      },
      err => {

      })



  }


  getCertificate(data){
    let url = APP_URLS.certificateTemplate;
     this.service.getCallwithHeader(url).subscribe((res: any) => {
       var snippet = document.createElement("div");
       snippet.innerHTML = res.resTemp.template_text_view;
       Array.from(snippet.querySelectorAll('img')).forEach(function (ele) {

         var text = ele.src
         var text2=ele.src
         let logo = tenantInfo.logoUrl;
         let backgroundlogo = '../../../assets/images/certificatebg.png'
         let val = location.origin+"/[sitelogo]"
         let val2 = location.origin+"/[backgroundlogo]"
         if (ele.src == val) {
           text = text.replace(text, logo)
           ele.src = text

         }
         if (ele.src == val2) {
           text = text.replace(text2, backgroundlogo)
           ele.src = text

         }

       });
       Array.from(snippet.querySelectorAll('p')).forEach(function (ele) {
         var text = ele.textContent
         var text2 = ele.textContent
         var name = JSON.parse(localStorage.getItem('profilesummaryData')).displayName;
         const date = new Date()
         if (ele.textContent == "[studentname]") {
           text = text.replace(text, name)
           ele.textContent = text

         }
         if (ele.textContent == "[taskname] test" ) {
           text = text.replace(text2, data.name)
           ele.textContent = text

         }
       });
       Array.from(snippet.querySelectorAll('td')).forEach(function (ele) {
         var text3 = ele.textContent
         var text = ele.textContent
         const date = moment().format("YYYY-MM-DD")
         if (ele.textContent == "[date]") {
           text = text.replace(text3, date)
           ele.textContent = text
         }

       });
       res.resTemp.template_text_view = snippet.innerHTML
       this.certificateTemplate = this.sanitizer.bypassSecurityTrustHtml(res.resTemp.template_text_view);
     })
    }

  quistions = [];
  answers = [];

  nextQuestion(question, questionNumbernumber) {

    if (question.question_type == "radio" || question.question_type == "T/F") {
      if (this.selectcertificateData.student_answer[question.indexval] == "") {
        if( this.test_type == 'test'){
          this.service.showError("Select a answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question");
         } else {
           this.service.showError('Select a answer for this question and then go to next question (or) You can click on the "See Answer" button, see answer and mark it then goto next question');
         }
        return;
      }
    }

    if (question.question_type == "multiple") {
      if (this.selectcertificateData.student_answer[question.indexval] == "" || this.selectcertificateData.student_answer[question.indexval].length < 2) {
        this.service.showError("Select more than one answer and then go to next question");
        return;
      }
    }

    if( this.test_type == 'qatest'){
      if(this.selectcertificateData.student_answer[question.indexval]== "") {
        this.service.showError("Answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question");
        return;
      }
     }
    this.showAnswer = false;

    if (this.questionNumber < this.quistions.length - 1) {
      this.questionNumber = this.questionNumber + 1;
      this.startcount = this.startcount + 1;

    }
    this.website = [];
    if (this.questionNumber == this.quistions.length - 1) {
      this.nextbtn = false
    }
  }


  previouesQuestion(question) {

    if (this.questionNumber == this.quistions.length - 1) {
      this.nextbtn = true
    }

    if (this.questionNumber > 0 && this.verification == false && this.startcount > 0) {
      this.questionNumber = this.questionNumber - 1;
      this.startcount = this.startcount - 1;


    }
    if (this.verification == true) {
      this.questionNumber = this.quistions.length - 1;
      this.startcount = this.quistions.length;
      this.verification = false
    }

    if( this.selectcertificateData.student_answer[question.indexval - 1] ){
      this.website = this.selectcertificateData.student_answer[question.indexval - 1].split(',');
    }
  }

  Finish(data, frmContext) {
    // var isAnyNotAnswer = false;
    // for( var i = 0 ; i < this.selectcertificateData.student_answer.length; i++ ){
    //   if( this.selectcertificateData.student_answer[i] == ''){
    //     isAnyNotAnswer = true;
    //     break;
    //   }
    // }
    var isAnyNotAnswer = false;
    if( frmContext == 'finishBtn' ){
      if( this.selectcertificateData.student_answer[data.indexval] == ''){
        isAnyNotAnswer = true;
      }
    }
    if(isAnyNotAnswer){
      if( this.test_type == 'qatest'){
        this.service.showError("Answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question")
      } else if( this.test_type == 'quiz'){
        this.service.showError("Select a answer for this question and then go to next question (or) You can click on the 'See Answer' button, see answer and mark it then goto next question")
      } else {
        this.service.showError("Select a answer for this question and then go to next question (or) To skip this question for now, you can use Answer later option below the question and then go to next question")
      }
    } else{
      var Temp_AnswerLaterQuestion = JSON.parse(JSON.stringify(this.AnswerlaterQuistions));
      Temp_AnswerLaterQuestion.forEach((x) => {
        if( this.selectcertificateData.student_answer[x.indexval] != '' ){
          this.removeArrayValues(x.indexval);
        }
      })
      if( ! this.AnswerlaterQuistions.length ){
        this.quistions = this.selectcertificateData.question;
      }
      this.questionNumber = this.quistions.length
      this.startcount = this.quistions.length
      this.verification = true;
      this.isAnslaterView = false;
    }
  }


  timeoutfinish(){
    this.questionNumber = this.quistions.length
    this.startcount = this.quistions.length
    this.verification = true;
  }


  answerLaterClick(quistion, num) {
    this.website = [];
    this.selectcertificateData.student_answer[num] = ""
    quistion.options_keyval.forEach(x => {
      if (x.cheked && x.cheked == true) {
        x.cheked = false
      }
    })
    let alreadyinarray = this.AnswerlaterQuistions.find(y => y.question == quistion.question)

    if (alreadyinarray) {

    }
    else {
      this.AnswerlaterQuistions.push(quistion);
    }

    if (this.questionNumber < this.quistions.length - 1) {
      this.questionNumber = this.questionNumber + 1;
      this.startcount = this.startcount + 1;
    }
    if (this.nextbtn == false) {
      this.Finish(quistion,'answerLaterBtn');
    }

    if (this.questionNumber == this.quistions.length - 1) {
      this.nextbtn = false
    }
  }

  answerLaterQuestions() {
    this.showDialogNotAnswer = false;
    this.startcount = 1;
    this.lastcount = this.AnswerlaterQuistions.length;
    this.quistions = this.AnswerlaterQuistions;
    this.questionNumber = 0;
    this.verification = false;
    this.nextbtn = ( this.AnswerlaterQuistions.length > 1 ) ? true : false;
    this.isAnslaterView = false;
  }

  seeAnswer(data, num) {
    this.showAnswer = !this.showAnswer
  }

  continueTest(){
    this.showDialogNotAnswer = false;
    this.showSubmitConfirmDialog = false;
  }

  payload = {};
  showDialogNotAnswer:boolean = false;
  showSubmitConfirmDialog : boolean = false;

  removeArrayValues( index ){
    for( var i = 0; i < this.AnswerlaterQuistions.length; i++ ){
      if( this.AnswerlaterQuistions[i].indexval == index ){
        this.AnswerlaterQuistions.splice(i,1);
      }
    }
  }

  FinishTest() {
    var Temp_AnswerLaterQuestion = JSON.parse(JSON.stringify(this.AnswerlaterQuistions));
    Temp_AnswerLaterQuestion.forEach((x) => {
      if( this.selectcertificateData.student_answer[x.indexval] != '' ){
        this.removeArrayValues(x.indexval);
      }
    })
    window.scroll(0,0);
    if( this.AnswerlaterQuistions.length ){
      this.showDialogNotAnswer = true;
    } else {
      this.showSubmitConfirmDialog = true;
    }
  }

  submitTest(){
    let url = APP_URLS.lmsInnerSubmit;

      this.payload = {
        chapter: this.selectcertificateData._id,
        student: this.studentId,
        course: this.ID,
        exam_type: this.test_type,
        student_answer: this.selectcertificateData.student_answer,
      }

      this.service.putCall(url, this.payload).subscribe((res: any) => {
        if (res.respCode) {
          clearInterval(this.interval);
          this.service.showSuccess(res.respMessage)
          // this.getResult();

          if(this.test_type == 'test'){
            this.service.showSuccess('Your Test is submitted successfully. For the result see your test report below');
          } else if(this.test_type == 'qatest'){
            this.service.showSuccess('Your Test is submitted successfully. Course team will review and send you the test report through email and notification');
          } else if(this.test_type == 'quiz'){
            this.service.showSuccess('Your Quiz is submitted is successfully. For the result see your test report below.');
          }

          this.getSelectCertificate('submitTest')
          // this.FinishedtheTest = true;
        }
        else{
          this.service.showError(res.errorMessage) ;
        }
        this.showSubmitConfirmDialog = false;
      })
  }


  requestCertificate(data) {
    let url = APP_URLS.CertificateRequest+data._id+'?target=assignment';
    let Payload = {
      certificate_request: true
    }
    this.service.putCall(url, Payload).subscribe((res: any) => {
      if (res.respCode) {
        this.service.showSuccess(res.respMessage)
        localStorage.setItem('uuid',this.selectcertificateData.uuid)
        this.service.setexam(this.ExamData.serialnum)
        this.getSelectCertificate('finish')
      }
      else{

      }

      })
    }

    data: any
    download() {
      var certificateType = '';
      if( this.test_type == 'test'){
        certificateType = 'Chapter Certificate - Test'
      } else if( this.test_type == 'qatest' ){
        certificateType = 'Chapter Certificate - Test QA'
      }
      var obj = {
            studentname : this.DisplayName,
            course : this.selectcertificateData.name,
            certificate_id : this.certificateforcourseid,
            updated : this.result.test_submitted_date,
            signature : this.tenantSettingsData.settings.signature,
            certificate_type : certificateType,
            termName : this.termDetails[0].name,
            chapterName : this.chapterDetails
      }

      localStorage.setItem('certificates',JSON.stringify(obj))

      this.router.navigate([]).then(result => {  window.open( `/opencertificate`, '_blank',); });

    }
}
