import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventscarousel',
  templateUrl: './eventscarousel.component.html',
  styleUrls: ['./eventscarousel.component.css']
})
export class EventscarouselComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
