
import { Component, OnInit, OnDestroy,ViewChild,ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { APP_URLS } from '../../../Models/url';
import { UserService } from '../../../services/user.service';
import { MyProfileService } from '../../../services/my-profile.service';
import { TenantId } from '../../../Models/url';
import { CommonService } from '../../../services/common.service'
import { ModaldialogComponent } from '../../../pages/modaldialog/modaldialog.component'
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-support-tickect-compose',
  templateUrl: './support-tickect-compose.component.html',
  styleUrls: ['./support-tickect-compose.component.css']
})
export class SupportTickectComposeComponent implements OnInit {
  @ViewChild('f', {static: true}) f: NgForm; 
  @ViewChild('takeInputfile', {static: false})

  InputVar: ElementRef; 
 
  fileToUpload: any;
  fileName: any;
  fileSize: any;
  email: any
  studentId: any;
  userRole = '';
  Ticket = {
    subject: '',
    comment: '',
  };
 
  p: number = 1;
  search: string;

  constructor(
    private profileSvc: MyProfileService,
    private toast: UserService,
    private cms: CommonService,
    private modalCom: ModaldialogComponent,
    private changeDetectorRef: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.email = JSON.parse(localStorage.getItem('userDetails')).email;
    this.userRole = localStorage.getItem('role');

  }
  reset()
  {
    this.f.resetForm()
    this.InputVar.nativeElement.value = "";
  }
  handleFileInput(files) {
    this.fileSize = ( files.length ) ? files[0].size : 0
    const formData = new FormData();
    formData.append('images', files[0]);
    var url = APP_URLS.supportTicketUpload + '&frmSrc=support'
    this.profileSvc.postCallImg(url, formData)
      .subscribe((data: any) => {
        if(data.errorMessage){
          this.toast.showError(data.errorMessage)
        } else if(data.message){
          this.fileName = ""
          this.toast.showError(data.message)
        } else {
          if( data.length )
            this.fileName = data[0].name
          this.toast.showSuccess('Your chosen file is attached successfully. Now click on submit button')
        }

      }, err => {
        this.fileName=""
        this.toast.showError(err.errorMessage)
      })
  }
  submit() {
    // this.cms.loaderSet(true);
    var payload = {};
    let url = '';
    if( this.userRole == 'employer') {
      payload = {
        subject: this.Ticket.subject,
        message: this.Ticket.comment,
        attachments: [{
            name: this.fileName
        }]
      };
      url = APP_URLS.v1.createSupportTicket
      this.profileSvc.postCall(url, payload).subscribe(
        (res: any) => {
          if (res.respCode == 200 || res.respCode == 201) {
            // this.cms.loaderSet(false);
            this.toast.showSuccess(res.respMessage);
            this.Ticket.subject = '';
            this.Ticket.comment = '';
            this.profileSvc.setSupportCompose('data')
          }
          else {
            // this.cms.loaderSet(false);
            this.toast.showError(res.respMessage)
          }
          this.modalCom.close();
          this.reset();
        },
        err => {
          // this.cms.loaderSet(false);
          this.toast.showError(err.errorMessage)
        })
    } else {
      payload = {
        compose_mail: "2",
        context_id: { student: this.studentId },
        context_type: "STUDENT",
        from: this.email,
        inbox_type: "",
        open_closed_thread: "open",
        student: this.studentId,
        subject: this.Ticket.subject,
        threads: [
          {
            message: this.Ticket.comment,
            created_by: { student: this.studentId },
            from: this.email,
            to: ["crm@abacademy.co.uk"],
            attachments: [
              {
                name: this.fileName,
                path: ""
              }
            ]
          }
        ]
      }
      url = APP_URLS.v1.createSupportTicket
      this.profileSvc.postCall(url, payload).subscribe(
        (res: any) => {
          if (res.respCode == 200 || res.respCode == 201) {
            // this.cms.loaderSet(false);
            this.toast.showSuccess(res.respMessage);
            this.Ticket.subject = '';
            this.Ticket.comment = '';
            this.profileSvc.setSupportCompose('data')
          }
          else {
            // this.cms.loaderSet(false);
            this.toast.showError(res.respMessage)
          }
          this.modalCom.close();
          this.reset();
        },
        err => {
          // this.cms.loaderSet(false);
          this.toast.showError(err.errorMessage)
        })
    }

  }


}
