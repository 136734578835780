import { Component, OnInit } from '@angular/core';
import { APP_URLS,TenantId } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';
import {Router,ActivatedRoute} from '@angular/router';
import { UserService } from '../../services/user.service';

declare let $: any;
@Component({
  selector: 'app-view-all-courses',
  templateUrl: './view-all-courses.component.html',
  styleUrls: ['./view-all-courses.component.css']
})
export class ViewAllCoursesComponent implements OnInit {

  CoursesData = [];
  student_Id:any
  showDialogReg = false;

  constructor(public service: CourseService,
    private route : ActivatedRoute, 
    private toastr: UserService,
    private router:Router
    ) {
    this.getCourseList()
  }

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: true,
    dots: false,
    autoHeight: true,
    pullDrag: true,
    mouseDrag: true,
    autoWidth: true,
    navText: ['<i class="fa fa-chevron-left" aria-hidden="true"></i>', '<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
    responsive: {
      0: { items: 1 },
      400: { items: 2 },
      740: { items: 3 },
      940: { items: 3 },
      1000:{items:3}
    }, nav: true
  }


  ngOnInit() {
    
    this.student_Id= JSON.parse(localStorage.getItem('userDetails'))._id;
    $('.inx-owl-slide').owlCarousel({
      loop: true,
      margin: 5,
      nav: true,
      autoplay: true,
    });
    $(function () {
      $(".inx-owl-slide .owl-next").addClass("fas fa-chevron-right");
      $(".inx-owl-slide .owl-prev").addClass("fas fa-chevron-left");
    });
  }

  getCourseList() {
    let url = APP_URLS.Courses;
    this.service.getCall(url).subscribe((res: any) => {
      if(res.courses.length>0){
        res.courses.forEach(x => {
          if(this.student_Id){
         if(x.favorites.length>0){
           x.favorites.forEach(y => {
             if(y==this.student_Id)
             {
               x.Favouritebutton="Remove Favourite"
             }
             else{
               x.Favouritebutton="Add As Favourite"
             }
           });
          }
          else{
           x.Favouritebutton="Add As Favourite"
          }
           if (x.applications.length > 0) {
           x.applications.forEach(y => {
             if (y == this.student_Id) {
               x.Applybutton = "Applied"
             }
             else {
               x.Applybutton = "Apply"
             }
           });
         }
         else {
           x.Applybutton = "Apply"
         }
        }
        else{
          x.Favouritebutton="Add As Favourite"
          x.Applybutton = "Apply"
        }
        });
        this.CoursesData = res.courses;
        
      }
     
     
    })
  }
 
  selectCourse(data){
   this.service.selectCourseById(data);
   this.service.senddataToHeader(data);
    this.router.navigate(['/'+data.slug])
  }

  AddFav(data){
    let url = APP_URLS.applyAndFavourate+data._id+'?favorite=true';
    if(this.student_Id){
    this.service.putCall(url,{})
    .subscribe((data:any)=>{
    
      this.toastr.showSuccess(data.respMessage);
      this.getCourseList()
    },err=>{
      
    })
    }else{
      this.toastr.showError('Please register or login to continue');
       this.service.selectedcourse(data._id, "favourite")
      
        this.showDialogReg=true;
    }

  }

  AddApply(data){
    let url = APP_URLS.applyAndFavourate+data._id+'?applycourse=true';
   
    if(this.student_Id){
    this.service.putCall(url,{})
    .subscribe((data:any)=>{
     
      this.toastr.showSuccess(data.respMessage);
      this.getCourseList()
    },err=>{
     
    })
    }else{
      this.toastr.showError('Please register or login to continue');
      this.service.selectedcourse(data._id, "apply")
      this.showDialogReg=true;
    }

  }


}
