import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service'
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, TenantId } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  ID: string;
  AllChapters = [];
  chaptersData = [];
  Chapters = [];
  testdata = [];
  FinalChaptersArray = [];
  isNoTestData:boolean = false;
  parentAccordion : any;
  selectedexam: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: MyProfileService,
    private cms: CommonService,
  ) {
    this.ID = localStorage.getItem('courseId')
  }

  ngOnInit(): void {
    this.getTestData();
  }
  async getTestData() {

    let url = APP_URLS.newchapters + '{"criteria":[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"course","value":"' + this.ID + '","type":"eq"}]}'
    await this.service.getCallwithHeader(url).subscribe(
        (res: any) => {
          this.parentAccordion = res.independent[0].name
          let data = res.independent[0].child[0].test
          data.forEach((x,i)=>{
            x['accordion'] = '#mcktestaccordion' + i
            x['accordionID'] = 'mcktestaccordion' + i
            this.testdata.push(x);
          })
          this.selectedexam = this.testdata[0].uuid;
          this.isNoTestData = ( this.testdata.length ) ? false : true;
        })
  }

  openTest(data){
    this.selectedexam  = data
    this.service.setopenexam(data);
  }
 


}
