import { Component, OnInit } from '@angular/core';
import { MyProfileService } from 'src/app/services/my-profile.service';

@Component({
  selector: 'app-book-interview-tabs',
  templateUrl: './book-interview-tabs.component.html',
  styleUrls: ['./book-interview-tabs.component.css']
})
export class BookInterviewTabsComponent implements OnInit {

  constructor(public service: MyProfileService) { }

  ngOnInit(): void {
    
    this.service.setSidemenuTab("bookappontment");
  }

}
