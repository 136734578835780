import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { tenantInfo } from 'src/app/Models/url';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-home-business',
  templateUrl: './home-business.component.html',
  styleUrls: ['./home-business.component.css']
})
export class HomeBusinessComponent implements OnInit {

  tenantInfo: any = tenantInfo;
  token:boolean=false;
  userDetail: any;
  constructor(private route : ActivatedRoute,
 private userSvc:UserService) { }


  ngOnInit(): void {

    this.userDetail=JSON.parse(localStorage.getItem('profilesummaryData'))
    this.userSvc.getIsLogin().subscribe(data => {
        this.token = data;

    })

    if(localStorage.getItem('token')){
      this.token=true

    }
  }

}
