import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-portfolio-submit-model',
  templateUrl: './portfolio-submit-model.component.html',
  styleUrls: ['./portfolio-submit-model.component.css']
})
export class PortfolioSubmitModelComponent implements OnInit {
  @Input() details:any;

  data = this.details;
  constructor() { }

  ngOnInit(): void {
    this.data=this.details
   
  }

}
