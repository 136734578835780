import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { APP_URLS } from '../../Models/url';
import { FilterPipe } from '../../Models/filter.pipe';
import { ProgressionService } from '../../services/progression.service';
import { ExcelService } from '../../services/excel.service';
import * as jspdf from 'jspdf';
import * as _html2canvas from "html2canvas";
import { MyProfileService } from 'src/app/services/my-profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
// import 'rxjs/add/operator/switchMap';
const html2canvas: any = _html2canvas;


declare let $: any;
@Component({
  selector: 'app-progression',
  templateUrl: './progression.component.html',
  styleUrls: ['./progression.component.css'],
  providers:[FilterPipe]
})
export class ProgressionComponent implements OnInit {
  @ViewChild('div') div: ElementRef;
   p = 1;
  countPagination = 10;
  search: string;
  ProgressionList = []
  ProgressionListTemp = []
  studentId: any;
  sampleData = []
  displayName = ""
  config: any; 
  isProgressionInfo : boolean = false;

  progDownloadOption: string = 'current'

  constructor(public service: ProgressionService,
    public cms: CommonService,
    public profservice: MyProfileService,
    private excelService: ExcelService,
    private FilterSearch : FilterPipe,
    private renderer: Renderer2,
    private route: ActivatedRoute, private router: Router) { 
      this.config = {
        currentPage: 1,
        itemsPerPage: 2
    };

  
  // this.route.queryParamMap
  // .map(params => params.get('page'))
  //         .subscribe(page => this.config.currentPage = page);
    }

  ngOnInit(): void {
    this.profservice.setSidemenuTab("progression");
    let data = JSON.parse(localStorage.getItem('userDetails'))
    
    if (data) {
      this.studentId = data._id
      this.displayName = data.displayName
    }  
    this.getProgressionList()

  }

  onChangeEvent(event: any, findKey:any) {
    this.ProgressionListTemp = this.FilterSearch.transform(this.ProgressionList,event, findKey);
    this.ProgressionListTemp.forEach((y, i) => {
      y['sno'] = i + 1;
    })
    this.isProgressionInfo = ( this.ProgressionListTemp.length ) ? false : true;
  }

  pageChange(newPage: number) {
    this.router.navigate([''], { queryParams: { page: newPage } });
  }
  getProgressionList() {
    this.cms.loaderSet(true);
    let standards
    var number=0
    let newArray = []
    let url = APP_URLS.Progression + this.studentId;
   
    this.service.getCall(url)
    .subscribe((res: any) => {
      this.cms.loaderSet(false);
      this.ProgressionList = res.details.filter((x, i) => {

        // if (x.exam_type != "test" && x.exam_type != "general_portfolio") {
        if (x.exam_type != "general_portfolio") {
          if (x.course) {
            x['data'] = x.course
          }
          if (x.coursetest) {
            x['data'] = x.coursetest
          }
          // this.ProgressionList.push(x)
          //  this.ProgressionList.sort((a, b) => {
          //   return <any>new Date(b.created) - <any>new Date(a.created);
          // });
          return x;
        }
      })
      this.ProgressionList.sort((a, b) => {
        return <any>new Date(b.created) - <any>new Date(a.created);
      });
      this.ProgressionList.forEach((y, i) => {
        y['sno'] = i + 1;

        if( y.chapter && y.chapter.name ){

          if( y.exam_type == 'lessoncomplete' ){
            y['display_exam_type'] = 'My Course/lesson';
          } else if( y.exam_type == 'downloadcomplete' ){
            y['display_exam_type'] = 'My Course/download';
          } else {
            y['display_exam_type'] = 'My Course/'+ y.exam_type;
          }
          
        } else {
          y['display_exam_type'] = 'My Test/' + y.exam_type;
        }
        
        y.approveUIFlag = false;
        y.notApproveUIFlag = false;
        y.underReviewUIFlag = false;
        if( y.review_status || y.review_iv_status || y.review_three_status ){
          if( y.review_status == 'A' || y.review_iv_status == 'A' || y.review_three_status == 'A'){
            y.approveUIFlag = true;
            y.notApproveUIFlag = false;
            y.underReviewUIFlag = false;
          } else if( y.review_status == 'NA' || y.review_iv_status == 'NA' || y.review_three_status == 'NA'){
            y.approveUIFlag = false;
            y.notApproveUIFlag = true;
            y.underReviewUIFlag = false;
          } else if( y.review_status == 'UR' || y.review_iv_status == 'UR' || y.review_three_status == 'UR'){
            y.approveUIFlag = false;
            y.notApproveUIFlag = false;
            y.underReviewUIFlag = true;
          }
        }
      })
      this.ProgressionListTemp = JSON.parse(JSON.stringify(this.ProgressionList));
      this.isProgressionInfo = ( this.ProgressionListTemp.length ) ? false : true;
    },
      err => {
        this.cms.loaderSet(false);
      })
  }

  List = [];
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.List = pageOfItems;
  }
  exportAsXLSX(): void {
    let standards;
    let sourceData : any;
    if( this.search && this.search.length ){
      sourceData = this.ProgressionListTemp;
    } else {
      sourceData = this.ProgressionList;
    }
    this.sampleData = sourceData.map(x => {
      if (x.chapter) {
        if (x.chapter.standards.length > 1) {
          standards = Array.prototype.map.call(x.chapter.standards, s => s.competencestandard_title).toString();
        }
      }

      if (x.chapter) {
        return {
          Course_Name: x.data.name,
          Chapter_Name: x.chapter.name,
          Task_Type: x.exam_type,
          Standard_level: standards,
          Task_Status: x.review_iv_status
         
        }
      }
      if (!x.chapter) {
        return {
          Course_Name: x.data.name,
          Chapter_Name: "-",
          Task_Type: x.exam_type,
          Standard_level: standards,
          Task_Status: x.review_iv_status,

        }
      }
    });
    var excelName = this.displayName + '-Progression';
     this.excelService.exportAsExcelFile(this.sampleData, excelName);
    
  }


  public captureScreen() {
   
    var data = document.getElementById('contentToConvert');
    
    html2canvas(data).then(canvas => {
        
      var imgWidth = 165;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); 
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      var pdfName = this.displayName + '-Progression.pdf';
      pdf.save(pdfName);   
    });
  }
}

