import { Component, OnInit, OnDestroy, ViewChild, Renderer2, ElementRef, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TenantId } from '../../Models/url'
import { MyProfileService } from 'src/app/services/my-profile.service';
@Component({
  selector: 'app-individual-course',
  templateUrl: './individual-course.component.html',
  styleUrls: ['./individual-course.component.css']
})
export class IndividualCourseComponent implements OnInit, OnDestroy {

  public removeEventListener: () => void;
  courselist = {
    name: '',
    fullName: "",
    general_duration: "",
    coursefrontview: "",
    introduction: "",
    Favouritebutton: "",
    Applybutton: "",
    _id: ""
  };
  bannerImg: any
  coursetype = "";
  showDialogReg = false;
  courseID = "";

  student_Id = "";
  openregister: boolean = false
  activeToken: boolean = false
  constructor(private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,
    private cms: CommonService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2, private elementRef: ElementRef) {


    this.route.params.subscribe(params => {
      this.coursetype = params['id'];
      this.getList();
    });

  }


  ngOnInit() {
    this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      if (event.target instanceof HTMLAnchorElement) {

        this.handleAnchorClick(event);
      }
    });

    if (localStorage.getItem('token')) {
      this.student_Id = JSON.parse(localStorage.getItem('userDetails'))._id;
     
      this.activeToken = true
    }
    else {
      this.activeToken = false
    }

    // this.getList();
  }

  async getList() {
    this.cms.loaderSet(true);
   
    let url = APP_URLS.IndividuleCourse + this.coursetype + '?types=["all"]'
    await this.userSvc.getCallwithHeaders(url).subscribe(
      (res: any) => {
        this.cms.loaderSet(false);
        this.bannerImg = res.details.document_name
       
        if (this.activeToken) {
          if (res.details.favorites.length > 0) {
            res.details.favorites.forEach(y => {
              if (this.student_Id && y == this.student_Id) {

                res.details.Favouritebutton = "Remove Favourite"
              }
              else {
                res.details.Favouritebutton = "Add As Favourite"
              }
            });
          }
          else {
            res.details.Favouritebutton = "Add As Favourite"
          }
          if (res.details.applications.length > 0) {
            res.details.applications.forEach(y => {
              if (this.student_Id && y == this.student_Id) {
                res.details.Applybutton = "Applied"
              }
              else {
                res.details.Applybutton = "Apply"
              }
            });
          }
          else {
            res.details.Applybutton = "Apply"
          }

        }
        else {
          res.details.Favouritebutton = "Add As Favourite"
          res.details.Applybutton = "Apply"
        }
        var snippet = document.createElement("div");
        snippet.innerHTML = res.details.introduction;
        Array.from(snippet.querySelectorAll('a')).forEach(function (ele) {
          var text = ele.text
          var Favouritebutton = res.details.Favouritebutton
          var text2 = ele.text
          var Applybutton = res.details.Applybutton
          if (text == "Add As Favourite") {
            text = text.replace(text, Favouritebutton)
            ele.text = text
          }
          if (text == "Apply") {
            text = text.replace(text2, Applybutton)
            ele.text = text
          }
        });
        res.details.introduction = snippet.innerHTML
        this.courselist = res.details
       this.courseID=res.details._id
      },
      err => {

        this.cms.loaderSet(false);
      })

  }


  public ngOnDestroy() {
    if (this.removeEventListener) {
      this.removeEventListener();
    }
    localStorage.removeItem('coursetype')

  }



  public handleAnchorClick(event: Event) {

    event.preventDefault();
    const anchor = event.target as HTMLAnchorElement;
    const baseurl = anchor.baseURI
    if (anchor.href == baseurl + '#addasfav') {
      if (this.activeToken) {
        let url = APP_URLS.applyAndFavourate + this.courseID + '?favorite=true';
        this.service.putCall(url, {})
          .subscribe((data: any) => {
            this.userSvc.showSuccess(data.respMessage);
            this.getList()
          }, err => {

          })
      }
      else {
       
        this.service.selectedcourse(this.courselist._id, "favourite")

        this.openregister = true;
      }
    }


   
    if (anchor.href == baseurl + '#apply') {
      if (this.activeToken) {
        let url = APP_URLS.applyAndFavourate + this.courseID + '?applycourse=true';

        this.service.putCall(url, {})
          .subscribe((data: any) => {
            this.userSvc.showSuccess(data.respMessage);
            this.getList()
          }, err => {

          })
      }

      else {
      
        this.service.selectedcourse(this.courselist._id, "apply")
        this.openregister = true
      }
    }

   
    if (anchor.href == baseurl + '#book') {
      if (this.activeToken) {
        this.router.navigate(['/profile'])
      }
      else {
        this.openregister = true
      }

    }




  }



}