import { Component, OnInit } from '@angular/core';
import { APP_URLS, TenantId } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  studentId:  any ;
  profilePermissions: any
  notificationCount = 0;
  Opentickets = 0;

  isActive = 1;
    routesForMyWork = [
      {
        id: 1,
        title: "Profile Summary",
        value: "profile-summary",
      },
      {
        id: 2,
        title: "Admission Application",
        value: "admission",
      },
      {
        id: 3,
        title: "Book For Interview",
        value: "bookinterview",
      },
      {
        id: 4,
        title: "Action Center",
        value: "action-center",
      },
      {
        id: 5,
        title: "Progression",
        value: "progression",
      },
      {
        id: 6,
        title: "Certificates",
        value: "certificate",
      },
      {
        id: 7,
        title: "Support Tikcet",
        value: "support-ticket",
      },
      {
        id: 8,
        title: "Complaint",
        value: "complaint",
      },
      {
        id: 9,
        title: "Notifications",
        value: "notification",
      },
      {
        id: 10,
        title: "Feedbacks",
        value: "feedback",
      }
    ];

  constructor(
    public service: MyProfileService,
    private route: Router
  ) {

  }
  ngOnInit() {
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    // this.userSvc.getTicketCount()
    //   .subscribe((data: any) => {
    //     this.Opentickets = data;
    //   })
    // this.userSvc.getNotifyCount().subscribe((data:any)=>{
    //   this.notificationCount = data;
    // })
    this.getProfilePermissions();
    this.getSupportTicket();
    this.getNotificationList();
    this.route.navigate(['/profile/profile-summary'])
  }

  async getProfilePermissions() {
    let url = APP_URLS.profilePermissions
    await this.service.getCallWithHeaders(url).subscribe((res: any) => {
      this.profilePermissions = res.profile_permissions
      this.service.setPermissionsData(this.profilePermissions)
    }, err => {

    })
  }


  myWorkRoute(link) {
    if (this.isActive === link.id) {
      return;
    }
    this.isActive = link.id;
    this.route.navigate(["/profile", link.value]);
  }

  getSupportTicket() {
    let url = APP_URLS.supportTicket + `[{"key":"student","value":"` + this.studentId + `","type":"eq"},{"key":"tenantId","value":"` + TenantId + `","type":"eq"}]}`
    this.service.getCall(url)
      .subscribe((data: any) => {
        this.Opentickets = data?.inboxes?.length;
      })
  }

  getNotificationList() {
    let url = APP_URLS.notification + '[{"key":"tenantId","value":"' + TenantId + '","type":"eq"},{"key":"student","value":"' + this.studentId + '","type":"eq"},{"key":"active","value":"active","type":"eq"},{"key":"frontorback","value":"front","type":"or"},{"key":"frontorback","value":"frontandback","type":"or"}]}'
    this.service.getCallWithHeaders(url)
      .subscribe((res: any) => {
       this.notificationCount = res?.noti_new
      });
  }
}
