import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TenantId } from '../Models/url';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  private subject = new Subject<any>();

  
  token = "";
  Load : Subject<any>;

  config = {
    base_url: "http://localhost:3700/",
    host: "localhost",
    serve: "172.104.186.153",
    port: 3000,
    serverUrlCors: "http://localhost:3700/cors/",
    serverUrl: "http://localhost:3000/api/",
    serverUrlhttps: "http://localhost:3000/api/",
    socialUrl: "http://live.test.co.uk/api/",
    imagePathOld: "http://172.104.186.153/tenantServer/modules/upload",
    imagePath: "http://localhost:3000",
    tenantConfig:
    {
      tenantId: TenantId,
      url: "http://54.147.203.20:3001/#/",
      company: "ELearning tool",
      logo: "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"
    }
  }
  headers :any

  constructor(private http: HttpClient) {
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;
    this.Load = new Subject<any>();
    
  }

  getCall(url) {
    
    return this.http.get(url)
  }
  getCallWithHeaders(url) {
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "'+TenantId+'","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'
    }
    
    return this.http.get(url, {headers})
  }

  postCallImg(url, payload) {
  
    return this.http.post(url, payload)
  }

  putCall(url,payload) {
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig': '{ "tenantId": "'+TenantId+'","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

    }
    return this.http.put(url, payload, {headers})
  }

  setLoad(data){
    this.Load.next(data)
  }
   
  getLoad(){
    return this.Load.asObservable();
  }


}
