import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { CommonService } from '../../services/common.service'
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-employer-profile',
  templateUrl: './employer-profile.component.html',
  styleUrls: ['./employer-profile.component.css']
})
export class AppEmployerProfileComponent implements OnInit {

    studentId: any
    objLength: number;
    Opentickets = 0;
    profilePermissions: any;
    isActive = 1;
    supportTicketCount = 0;

    routesForMyWork = [
      {
        id: 1,
        title: "Profile Summary",
        value: "emp-profile-summary",
      },
      {
        id: 2,
        title: "Action Center",
        value: "emp-action-center",
      },
      {
        id: 3,
        title: "My Apprentice",
        value: "my-apprentice",
      },
      {
        id: 4,
        title: "Support Tickets",
        value: "emp-support-ticket",
      }
    ];

    constructor(
      public service: MyProfileService,
      public userSvc: UserService,
      private router: Router
    ) {}

    ngOnInit(): void {
      this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id
      this.userSvc.getTicketCount()
        .subscribe((data: any) => {
          this.supportTicketCount = data;
        })
      //this.userSvc.getNotifyCount().subscribe((data:any)=>{
        //this.notificationCount = data;
      //})
      this.router.navigate(["/employer-profile", "emp-profile-summary"]);
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          const thePath = val.url;
          const lastItem = thePath.substring(
            thePath.lastIndexOf("/") + 1
          );
          this.routesForMyWork.forEach((r) => {
            if (r.value == lastItem) {
              this.isActive = r.id;
              this.router.navigate(["/employer-profile", lastItem]);
            }
          });
        }
      });
      this.getProfilePermissions();
      this.getSupportTicketCount();
    }

    getSupportTicketCount(): void{
      let url = APP_URLS.v1.supportTicket + '?countOnly=yes';
      this.service.getCall(url).subscribe((res: any) => {
        this.supportTicketCount = res?.totalRecords;
      })
    }

    async getProfilePermissions() {

      let url = APP_URLS.profilePermissions

      await this.service.getCallWithHeaders(url).subscribe((res: any) => {
        this.objLength = Object.keys(res).length
        this.profilePermissions = res.profile_permissions

        this.service.setPermissionsData(this.profilePermissions)
      }, err => {

      })
    }

    myWorkRoute(link) {
      if (this.isActive === link.id) {
        return;
      }
      this.isActive = link.id;
      this.router.navigate(["/employer-profile", link.value]);
    }

  }
