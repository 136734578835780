import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { CommonService } from '../../services/common.service'
import { UserService } from '../../services/user.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
declare let $: any;
@Component({
  selector: 'app-lms-inner',
  templateUrl: './lms-inner.component.html',
  styleUrls: ['./lms-inner.component.css']
})
export class LmsInnerComponent implements OnInit {
  showDialogComplaint: boolean = false;
  showDialogFinishChapter: boolean = false;
  ID: any;
  uuid: any;
  chapters = [];
  isActive: any
  completedChapters = [];
  AllChapters = [];

  certificate_courseComplete_Status = 'Pending';
  courseFinishDetailRes:any;
  chapter_uuid_status:any;
  finish = 0;
  not_finish = 0;

  selectednum = 0;
  currentChapteruuid = ""
  chapterDescription = {
    name: "",
    description: "",
    chapter_type: ""
  }
  chapter_Id: any;
  studentId: any;
  chapter_type = ""
  selectedData: any;
  examtype: boolean = false
  finishAssignment = {

    chapter: "",
    student: "",
    course: "",
    exam_type: "",
    review_status: "",
    review_iv_status: "",
    test_submitted_date: new Date()

  }
  file: any
  cou_appID = ""
  IsprviouesChapterCompleted = 'no'
  fileUrl;
  downloads: any;
  TrainingName:any
  @ViewChild('images') images;
  imageLink = null;
  LessionDesrciption: any;
  DownloadDesrciption: any;
  coursename: string;

  courseheading = "";
  trainingname = "";

  menustatus = "active";
  courseIdFromRoute : any;

  finishButtion = false;
  finishButtonAllow: boolean = false;
  breadCrumb: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public service: CourseService,
    private cms: CommonService,
    private userSvc: UserService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
  ) {

    var routeData = this.route.snapshot.params.id;
    var routeArry = routeData.split('-');
    if( routeArry.length ){
      this.courseIdFromRoute = routeArry[0];
    }

    this.service.getexam().subscribe(data => {
      let filterdata
      this.uuid = localStorage.getItem('uuid')
      this.AllChapters = JSON.parse(localStorage.getItem('AllChapters'))
     
      if (data || data == 0) {
        this.selectednum = data;
        let lmsData = JSON.parse(localStorage.getItem('lmsInnerData'))
        if (lmsData.chapters_a.length > 0) {
          var parent = localStorage.getItem('parentuuid')
          filterdata = lmsData.chapters_a.filter(item => item.parent == parent)
        }
        if (!lmsData || filterdata.length == 0) {
          this.ngOnInit();
        }
        else {
          this.cou_appID = lmsData.cou_app._id
          
          lmsData.chapters_a.forEach(x => {
            x['isactive'] = "";
          })
          this.chapters = lmsData.chapters_a;
          lmsData.chapters_a.forEach((x, i) => {
            if (x.uuid == this.uuid) {
              this.selectednum = i;
              this.chapters[this.selectednum].isactive = "active"
              this.openChapter(this.chapters[this.selectednum], this.selectednum)
              this.service.setSelectedData(i)
            }

          })
        }
      }

    })
    this.userSvc.getAssignLater().subscribe(data => {
      if (data) {
        this.previous('n');
      }
    })
  }

  lastlessionID : any;
  queryParam : any;
  ngOnInit(): void {

    // if (!localStorage.getItem('foo1')) { 
    //   localStorage.setItem('foo1', 'no reload') 
    //   location.reload() 
    // } else {
    //   localStorage.removeItem('foo1') 
    // }

    this.ID = localStorage.getItem('courseId')
    this.uuid = localStorage.getItem('uuid')
    this.coursename = localStorage.getItem('coursename')
    this.chapter_Id = localStorage.getItem('chapterID')
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.trainingname =localStorage.getItem('TrainingName')
    this.courseheading = localStorage.getItem('courseheading')
    this.AllChapters = JSON.parse(localStorage.getItem('AllChapters'))
    let data = this.AllChapters[this.AllChapters.length - 1].child
    if(data && data[data.length - 1] && data[data.length - 1]._id){
      this.lastlessionID = data[data.length - 1]._id
    }

    // this.route.queryParams.subscribe(params => {
    //   this.queryParam = params;
    // });

    this.getLmsData()
    this.courseFinishDetail();
  }

  async courseFinishDetail(){
    this.chapter_uuid_status = {};
    this.finish = 0;
    this.not_finish = 0;
    var course = this.ID;
    let url = APP_URLS.v1.courseFinishDetail;
    url += '?filter={"criteria":[{"key":"course","value":"'+course+'","type":"eq"}]}';
    await this.service.getCallwithHeader(url).subscribe((res: any) => {
      if(typeof res.chapter_uuid_status != 'undefined'){
        this.courseFinishDetailRes = res; 
        this.chapter_uuid_status = res.chapter_uuid_status;
        this.finish = res.chapter_uuid_status.finish;
        this.not_finish = res.chapter_uuid_status.not_finish;
        if(typeof res.certificate_courseComplete!='undefined'){
          this.certificate_courseComplete_Status = res.certificate_courseComplete.status;
        } else {
          this.certificate_courseComplete_Status = 'Pending';
        }
        if(this.not_finish == 0){
          this.finishButtonAllow = true;
        } else {
          this.finishButtonAllow = false;
        }
      }
    });
  }

  navigateToHome() {
    this.router.navigate(['/Mycourses/', this.ID])
  }

  nextChapter(data, i) {
    if (i == 0) {
      this.IsprviouesChapterCompleted = this.chapters[i].iscompleted
    }
    if (i > 0) {
      var val = i - 1
      this.IsprviouesChapterCompleted = this.chapters[val].iscompleted
    }
    if( data && data.chapter_type == 'assignment'){
      if( data.termWiseShowHide && data.termWiseShowHide == 'hide'){
        this.userSvc.showError('Next chapter is not available now, contact your teacher');
        return false;
      }
    }

    if (i == 0 || this.IsprviouesChapterCompleted == 'yes' || ( val && this.chapters[val].upload_requireornot == "UL" ) ) {
    // if (i == 0 || this.IsprviouesChapterCompleted == 'yes' || this.chapters[val].upload_requireornot == "UL" ) {
      this.AllChapters.forEach((x, i) => {
        if (x._id == this.chapter_Id && this.AllChapters.length-1!=i ) {
          let nxtindex = i + 1;
          this.uuid = this.AllChapters[nxtindex].child[0].uuid;
          // this.chaptername = x.name
          this.courseheading = this.AllChapters[nxtindex].name
          localStorage.setItem('courseheading',this.AllChapters[nxtindex].name)
          localStorage.setItem('uuid', this.AllChapters[nxtindex].child[0].uuid);
          localStorage.setItem('chapterID', this.AllChapters[nxtindex].chapter_id)
        }
      })
      this.chapter_Id = localStorage.getItem('chapterID')
      this.getLmsData();
    } else {
      var Indexval = ( i ) ? i - 1 : i
      if(this.chapters[Indexval] && this.chapters[Indexval].chapter_type == 'quiz'){
        this.userSvc.showError("You must complete this Quiz and the result should be pass. Then you will be able to go next lesson");
      } else if( this.chapters[Indexval] && this.chapters[Indexval].chapter_type == 'test'){
        this.userSvc.showError("You must complete this Test and the result should be pass. Then you will be able to go next lesson");
      } else if( this.chapters[Indexval] && this.chapters[Indexval].chapter_type == 'qatest'){
        this.userSvc.showError("You must complete this Test and the result should be pass. Then you will be able to go next lesson");
      } else if( this.chapters[Indexval] && this.chapters[Indexval].chapter_type == 'assignment' && this.chapters[Indexval].assignment_upload == false && this.chapters[Indexval].report.length > 0){
        this.userSvc.showError("You have already submitted assignment and is under review. Once it is accepted then you will able to go next lesson");
      } else if( this.chapters[Indexval] && this.chapters[Indexval].chapter_type == 'assignment'){
        this.userSvc.showError("You have to submit your assignment and get accepted by your course department. Once accepted you can goto next lesson.")
      } else {
        this.userSvc.showError("You must complete this lesson, then you be will able to go to next lesson.  After learning this lesson, go to bottom of the page and click 'Finish this lesson' button to complete this lesson");
      }
    }
  }

  previousChapter() {
    this.AllChapters.forEach((x, i) => {
      if (x._id == this.chapter_Id && i > 0) {
        let nxtindex = i - 1
        this.uuid = this.AllChapters[nxtindex].child[0].uuid
        this.courseheading = this.AllChapters[nxtindex].name
        localStorage.setItem('courseheading',this.AllChapters[nxtindex].name)
        localStorage.setItem('uuid', this.AllChapters[nxtindex].child[0].uuid);
        localStorage.setItem('chapterID', this.AllChapters[nxtindex].chapter_id)
      }
    })
    this.chapter_Id = localStorage.getItem('chapterID')
    this.getLmsData();
  }


 async getLmsData(fromPlace=null) {
    let url;
    if( this.courseIdFromRoute != '' ){
      url = APP_URLS.lmsInner + this.uuid + '&course=' + this.courseIdFromRoute
    } else {
      url = APP_URLS.lmsInner + this.uuid
    }

    // if( this.queryParam && this.queryParam.pou && this.queryParam.pou == 'parent' ){
    //   url = APP_URLS.lmsInnerParent + this.uuid
    // }
    
    await this.service.getCallwithHeader(url).subscribe(
      (res: any) => {
        // this.cms.loaderSet(false);
        this.cou_appID = res.cou_app._id
        res.chapters_a.forEach(y => {
          y['isactive'] = "";
        })
        this.chapters = res.chapters_a;

        // if( this.queryParam && this.queryParam.pou && this.queryParam.pou == 'parent' ){
        //   this.uuid = res.chapters_a[0].uuid;
        // }
        
        if(fromPlace!='lastChapter'){
          this.AllChapters.forEach(x=>{
            if(x.uuid == res.chapters_a[0].parent){
              this.courseheading = x.name;
              localStorage.setItem('courseheading',x.name)
            }
          })
          localStorage.setItem('lmsInnerData', JSON.stringify(res))
          this.chapters.forEach((x, i) => {
            if (x.uuid == this.uuid) {
              this.selectednum = i;
              if( this.chapters[this.selectednum].chapter_type == 'assignment'){
                if( this.chapters[this.selectednum].termWiseShowHide && this.chapters[this.selectednum].termWiseShowHide == 'hide'){
                  this.selectednum = ( this.selectednum ) ? this.selectednum - 1 : this.selectednum;
                  this.chapters[this.selectednum].isactive = "active"
                  this.userSvc.showError('Next chapter is not available now, contact your teacher');
                }
              }
              this.chapters[this.selectednum].isactive = "active"
              this.openChapter(this.chapters[this.selectednum], this.selectednum)
              this.service.setSelectedData(i);
            }
          })
        } else {
          this.selectednum = this.selectednum-1;
          this.openChapter(this.chapters[this.selectednum], this.selectednum);
          this.finishButtion = true;
          this.courseFinishDetail();
        }
      },
      err => {
        // this.cms.loaderSet(false);
      })
  }

  previous(data) {
    if (data == 'n') {
      if (this.selectednum < this.chapters.length-1) {
        this.selectednum = this.selectednum + 1;
        this.openChapter(this.chapters[this.selectednum], this.selectednum)
      }
      else if(this.selectednum == this.chapters.length-1){
        if (this.chapters[this.selectednum-1].iscompleted == 'yes') {
          this.selectednum = this.selectednum + 1;
          this.nextChapter(this.chapters[this.selectednum], this.selectednum-1);
        }
      }
      else {
        this.nextChapter(this.chapters[this.selectednum], this.selectednum)
      }
    }

    if (data == 'p') {
      if (this.selectednum > 0) {
        this.selectednum = ( this.selectednum ) ? this.selectednum - 1 : this.selectednum
        this.openChapter(this.chapters[this.selectednum], this.selectednum)
      }
      else {
        this.previousChapter()
      }
    }
  }

  openChapter(data, i) {
    if (i == 0) {
      this.IsprviouesChapterCompleted = this.chapters[i].iscompleted
    }
    if (i > 0) {
      var val = ( i ) ? i - 1 : i
      this.IsprviouesChapterCompleted = this.chapters[val].iscompleted
    }
    if( data.chapter_type == 'assignment'){
      if( data.termWiseShowHide && data.termWiseShowHide == 'hide'){
        this.userSvc.showError('This chapter not available now, contact your teacher');
        return false;
      }
    }
    
    if (i == 0 || this.IsprviouesChapterCompleted == 'yes' || ( val && this.chapters[val].upload_requireornot == "UL" ) || data.chapter_type == "chapter") {
      
      // customized breadCrumb 
      this.breadCrumb = this.trainingname+' / '+ this.courseheading+' / '+data.name

      this.finishButtion = false;
      this.finishButtonAllow = false;
      if(data._id == this.lastlessionID) {
        this.finishButtion = true;
        if(this.not_finish==0 || (this.not_finish==0 && this.chapter_uuid_status.not_finish_uuid[0]==data._id)) {
          this.finishButtonAllow = true;
        }
      }

      this.selectednum = i;
      this.courseFinishDetail();
      this.chapters.forEach((x, index) => {
        if (i == index) {
          x.isactive = "active"
          data["serialnum"] = i
          this.examtype = false;
          localStorage.setItem('uuid', data.uuid)
          this.service.setSelectedData(i)
          if (data.chapter_type == "lesson") {
            this.chapter_type = "lesson"
            this.chapterDescription.name = data.name
            this.chapterDescription.chapter_type = data.chapter_type
            this.chapterDescription.description = data.description
            this.LessionDesrciption = this.sanitizer.bypassSecurityTrustHtml(data.description);
          }

          if (data.chapter_type == "chapter") {
            localStorage.setItem('uuid', data.parent)

            this.uuid = data.parent
            this.router.navigate(['/Mychapter/', this.ID + "-" + data.parent, this.coursename.replace(/\s/g, '-')])
            this.chapterDescription = {
              name: "",
              description: "",
              chapter_type: ""
            }
            this.LessionDesrciption = "";
            this.ngOnInit()
          }

          this.currentChapteruuid = data.uuid
          if (data.chapter_type == 'downloads') {
            this.chapterDescription.name = data.name
            this.chapter_type = "downloads"
            this.DownloadDesrciption = this.sanitizer.bypassSecurityTrustHtml(data.description);
            this.downloads = data.downloads

            this.downloads.forEach(y => {
              y['downloadfile'] = "";
              y.downloadfile = APP_URLS.imagePath + x.file_path + '/' + y.file
            });
          }

          if (data.chapter_type == "assignment") {
            this.chapter_type = "assignment"
            this.selectedData = data;
            this.service.setAssignment(data)
          }

          if (data.chapter_type == "qatest") {
            this.examtype = true
            this.chapter_type = "qatest"
            this.selectedData = data
            this.service.setExamtype(data)
          }
          if (data.chapter_type == "test") {
            this.examtype = true
            this.chapter_type = "test"
            this.selectedData = data
            this.service.setExamtype(data)
          }
          if (data.chapter_type == "quiz") {
            this.examtype = true
            this.chapter_type = "quiz"
            this.selectedData = data
            this.service.setExamtype(data)
          }

          if( data.chapter_type == "certificate"){
            this.chapter_type = 'certificate';
            this.examtype = false
            this.selectedData = data
            
          }
          this.resumeChapter(data);
        }
        else {
          x.isactive = ""
        }
      })
    } else {
      if( this.chapters[i] ){
        this.chapters[i].isactive = "";
      }

      var Indexval = ( i ) ? i - 1 : i;
      if(this.chapters[Indexval] && this.chapters[Indexval].chapter_type == 'quiz'){
        this.userSvc.showError("You must complete this Quiz and the result should be pass. Then you will be able to go next lesson");
      } else if( this.chapters[Indexval] && this.chapters[Indexval].chapter_type == 'test'){
        this.userSvc.showError("You must complete this Test and the result should be pass. Then you will be able to go next lesson");
      } else if( this.chapters[Indexval] && this.chapters[Indexval].chapter_type == 'qatest'){
        this.userSvc.showError("You must complete this Test and the result should be pass. Then you will be able to go next lesson");
      } else if( this.chapters[Indexval] && this.chapters[Indexval].chapter_type == 'assignment' && this.chapters[Indexval].report.length != 0 && this.chapters[Indexval].assignment_upload != true){
        this.userSvc.showError("You have already submitted assignment and is under review. Once it is accepted then you will able to go next lesson");
      } else if( this.chapters[Indexval] && this.chapters[Indexval].chapter_type == 'assignment'){
        this.userSvc.showError("You have to submit your assignment and get accepted by your course department. Once accepted you can goto next lesson.")
      } else {
        this.userSvc.showError("You must complete this lesson, then you be will able to go to next lesson.  After learning this lesson, go to bottom of the page and click 'Finish this lesson' button to complete this lesson");
      }
      this.selectednum = ( this.selectednum ) ? this.selectednum - 1 : this.selectednum
    }
  }

  getSubChapterData(data) {
    // this.cms.loaderSet(true);
    let url = APP_URLS.lmsInnersubchapter + data.parent
    this.service.getCallwithHeader(url).subscribe(
      (res: any) => {
        // this.cms.loaderSet(false);
        this.cou_appID = res.cou_app._id
        res.chapters_a.forEach(x => {
          x['isactive'] = "";
        })
        this.chapters = res.chapters_a;
        this.chapters.forEach((x, i) => {
          if (x.uuid == this.uuid) {
            this.selectednum = i;
            this.chapters[this.selectednum].isactive = "active"
            this.openChapter(this.chapters[this.selectednum], this.selectednum)
          }

        })
      },
      err => {
        // this.cms.loaderSet(false);
      })
  }

  LessionIframe = []

  getVedio(data) {
    var Iframes
    let snippet = document.createElement("div");
    snippet.innerHTML = data;
    Array.from(snippet.querySelectorAll('iframe')).forEach(function (ele) {
    });
    this.LessionIframe = Iframes

  }

  resumeChapter(data) {
    // this.cms.loaderSet(true);
    let url = APP_URLS.resumeChapter + this.cou_appID;
    let payload = {
      resume_chapter: data.uuid
    }
    this.service.putCall(url, payload).subscribe(
      (res: any) => {
        if (res.respCode) {
          // this.cms.loaderSet(false);        
        }
        else {
          // this.cms.loaderSet(false);
        }
      },
      err => {
        // this.cms.loaderSet(false);
        this.userSvc.showError('Failed')
      })

  }


  download() {
    this.chapters.forEach((x, index) => {
      if (x.chapter_type == "downloads") {
        if (x.iscompleted == 'no' && x._id == this.chapters[this.selectednum]._id) {
          this.finishLesson('downloadcomplete')
        }
      }
    })
  }

  finishLesson(data) {
    // this.cms.loaderSet(true);
    let url = APP_URLS.lmsInnerSubmit
    let payload = {
      chapter: this.chapters[this.selectednum]._id,
      student: this.studentId,
      course: this.ID,
      exam_type: data,
      review_status: "A",
      review_iv_status: "A",
      test_submitted_date: new Date()
    }
    this.userSvc.putCallwithHeaders(url, payload)
      .subscribe((res: any) => {
        if (res.respCode) {
          // this.cms.loaderSet(false);
          window.scroll(0,0);
          this.userSvc.showSuccess(res.respMessage);
          this.selectednum = this.selectednum + 1;

          if(typeof this.chapters[this.selectednum]!='undefined'){
            if(typeof this.chapters[this.selectednum].uuid!='undefined'){
              this.uuid = this.chapters[this.selectednum].uuid;
              localStorage.setItem('uuid', this.uuid);
              this.getLmsData();
            }
          }

          this.showDialogComplaint = false;
          if(this.selectednum > this.chapters.length){
            this.selectednum = this.chapters.length;
          }
          
          if(this.selectednum == this.chapters.length){
            this.chapter_Id = localStorage.getItem('chapterID');
            var lastIndex = this.AllChapters.length-1;
            var lasChapId = this.AllChapters[lastIndex].chapter_id;

            if(this.chapter_Id!=lasChapId) {
                this.nextChapter(this.chapters[this.selectednum], this.selectednum-1);
            } else {
              this.getLmsData('lastChapter');
            }
          }
        }
        // else {
        //   this.cms.loaderSet(false);
        //   this.userSvc.showError(res.respMessage)
        // }
      },
      (error) => {
        // this.cms.loaderSet(false);
        this.userSvc.showError('applicaion failed');
      })
  }

  ComposeClick() {
    if (this.chapters[this.selectednum].iscompleted == 'no') {
      this.showDialogComplaint = true;
      return false;
    }
    if (this.chapters[this.selectednum].iscompleted == 'yes') {

      this.selectednum = this.selectednum + 1;
      if( this.chapters[this.selectednum].chapter_type == 'assignment'){
        if( this.chapters[this.selectednum].termWiseShowHide && this.chapters[this.selectednum].termWiseShowHide == 'hide'){
          this.selectednum = ( this.selectednum ) ? this.selectednum - 1 : this.selectednum;
          this.userSvc.showError('Next chapter is not available now, contact your teacher');
          return false;
        }
      }
      window.scroll(0,0);
      if(this.selectednum == this.chapters.length){
        this.nextChapter(this.chapters[this.selectednum], this.selectednum)
      } else {
        this.openChapter(this.chapters[this.selectednum], this.selectednum)
      }
    }
  }

  menuButton() {
    this.menustatus = localStorage.getItem("menustatus")
    if (this.menustatus == "active") {
      this.menustatus = "";
      localStorage.setItem("menustatus", this.menustatus)
      this.service.setmenu(this.menustatus)
      return;
    }
    if (this.menustatus == "") {
      this.menustatus = "active"
      localStorage.setItem("menustatus", this.menustatus)
      this.service.setmenu(this.menustatus)
      return;
    }

  }
  async requestCertificate(){
    let url = APP_URLS.oldApi.CertificateRequest+this.ID+'?target=request-course-completion-certificate';
    let Payload = {
      certificate_request: true
    };
    await this.userSvc.putCallwithHeaders(url, Payload).subscribe((res: any) => {
      if(typeof res.toast != 'undefined'){
        if(res.toast.type == 'success'){
          this.userSvc.showSuccess(res.toast.message);
          this.router.navigate(['/Mycourses/', this.ID])
        }
      }
      if(typeof res.errorMessage != 'undefined'){
        this.userSvc.showError(res.errorMessage);
      }
    });
  }

  ContinueLession(){
    this.showDialogComplaint = false;
  }

  FinishChapters(){
    if (this.selectednum == 0) {
      this.IsprviouesChapterCompleted = this.chapters[this.selectednum].iscompleted
    }
    if (this.selectednum > 0) {
      var val = ( this.selectednum ) ? this.selectednum - 1 : this.selectednum
      this.IsprviouesChapterCompleted = this.chapters[val].iscompleted
    }
    if(this.finishButtonAllow==false){
      this.userSvc.showError('To complete the course, finish all the topics in each chapter and finally get green ticks for all chapters');
    } else if ( this.IsprviouesChapterCompleted == 'yes' || this.chapters[val].upload_requireornot == "UL") {
      //implement code here
      this.showDialogFinishChapter = true;
      
    } else{
      this.userSvc.showError('You must complete the previous section and get Approval');
    } 
  }
}


