import { Component, OnInit } from '@angular/core';
import { APP_URLS } from '../../Models/url';
import { MyProfileService } from '../../services/my-profile.service';
import { CommonService } from '../../services/common.service'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserService } from '../../services/user.service';
import { CourseService } from '../../services/course.service';

@Component({
  selector: 'app-employer-my-work',
  templateUrl: './employer-my-work.component.html',
  styleUrls: ['./employer-my-work.component.css']
})
export class EmployerMyWorkComponent implements OnInit {


    isActive = 1;
    routesForMyWork = [
      {
        id: 1,
        title: "My Project",
        value: "emp-my-project",
      },
      {
        id: 2,
        title: "My Task",
        value: "emp-my-task",
      }
    ];

    constructor(
      public service: MyProfileService,
      private router: Router,
    ) {}

    ngOnInit(): void {
      this.router.navigate(["/employer-mywork", "emp-my-project"]);
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          const thePath = val.url;
          const lastItem = thePath.substring(
            thePath.lastIndexOf("/") + 1
          );
          this.routesForMyWork.forEach((r) => {
            if (r.value == lastItem) {
              this.isActive = r.id;
              this.router.navigate(["/employer-mywork", lastItem]);
            }
          });
        }
      });
    }


    myWorkRoute(link) {
      if (this.isActive === link.id) {
        return;
      }
      this.isActive = link.id;
      this.router.navigate(["/employer-mywork", link.value]);
    }

  }

