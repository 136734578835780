import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TenantId } from '../Models/url';
@Injectable({ providedIn: 'root' })
export class UserService {

  private Registersubject = new Subject<any>();
  private heroesUrl = 'api/heroes';  
  popup: Subject<any>;
  TickentCount:Subject<any>;
  NotifyCount:Subject<any>;
  token = "";
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  tenantConfig =
  {
      "tenantId": 372877,
      "url": "https://www.besttrainings.co.uk/",
      "email": "contact@besttrainings.co.uk",
      "company": "ELearning tool",
      "domain": "Best-Training-London",
      "logo": "besttrainings.svg"
  }
  RegisterMessage: any;

  islogin :  Subject<any> ;
  AssignLater : Subject<any> ;

  constructor( private http: HttpClient,
    private toastr: ToastrService,) {
    this.islogin = new Subject<any>();
    this.popup = new Subject<any>();
    this.AssignLater = new Subject<any>();
    this.TickentCount= new Subject<any>();
    this.NotifyCount= new Subject<any>();
    var gaipp_token = localStorage.getItem('token')
    this.token = 'Bearer ' + gaipp_token;

   }
   showSuccess(message) {
    this.toastr.success(message)
  }

  showError(message) {
    this.toastr.error(message)
  }
   setIsLogin(value) {
    this.islogin.next(value)
  }

  getIsLogin() {
    return this.islogin.asObservable();
  }

  setAssignLater(value) {
    this.AssignLater.next(value)
  }

  getAssignLater() {
    return this.AssignLater.asObservable();
  }

  setTicketCount(value) {
    this.TickentCount.next(value)
  }

  getTicketCount() {
    return this.TickentCount.asObservable();
  }
  setNotifyCount(value) {
    this.NotifyCount.next(value)
  }

  getNotifyCount() {
    return this.NotifyCount.asObservable();
  }

  getCall(url){
     return this.http.get(url)
  }
  getCallwithHeaders(url){
    let headers = {
      'Authorization': this.token,
      'Content-Type': 'application/json',
      'tenantConfig':JSON.stringify(this.tenantConfig)  //'{ "tenantId": "'+TenantId+'","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'
  
    }
    return this.http.get(url,{headers})
 }



  postCall(url,payload){
    return this.http.post(url,payload)
  }

postCallwithHeaders(url,payload){
  let headers = {
    'Authorization': this.token,
    'Content-Type': 'application/json',
    'tenantConfig':JSON.stringify(this.tenantConfig) //'{ "tenantId": "'+TenantId+'","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

  }
  return this.http.post(url,payload,{headers})
}
putCall(url,payload){
  return this.http.put(url,payload)
}
putCallwithHeaders(url,payload){
  let headers = {
    'Authorization': this.token,
    'Content-Type': 'application/json',
    'tenantConfig': JSON.stringify(this.tenantConfig)  //'{ "tenantId": "'+TenantId+'","url": "http://54.147.203.20:3001/#/","company": "ELearning tool","logo": "http://139.59.34.68:2000/modules/core/client/img/ictc3.png"}'

  }
  return this.http.put(url,payload,{headers})
}

 setRegister(data){
   return this.RegisterMessage=data;
 }
 
 senddataToHeader(message: string) {
  this.Registersubject.next({ text: message });
}

clearMessage() {
  this.Registersubject.next();
}

getRegister(): Observable<any> {
  return this.Registersubject.asObservable();
}


}
