import { Component, OnInit } from '@angular/core';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { APP_URLS } from 'src/app/Models/url';
import { FilterPipe } from '../../Models/filter.pipe';

@Component({
  selector: 'app-my-apprentice',
  templateUrl: './my-apprentice.component.html',
  styleUrls: ['./my-apprentice.component.css'],
  providers:[FilterPipe]
})
export class MyApprenticeComponent implements OnInit {

  popupopen = 'none';
  apprenticeDeatils = [];
  isNoApprentice : boolean = false;
  isLoading = false;
  // List = [];
  // ListTemp = [];
  constructor( public service: MyProfileService, private FilterSearch : FilterPipe,) { }

  ngOnInit(): void {
    this.service.setSidemenuTab("Apprentice");
    this.getApprenticeDetails();
  }

  // onChangePage(pageOfItems: Array<any>) {
  //   // update current page of items
  //   this.List = pageOfItems;
  // }

  // onChangeEvent(event: any) {
  //   this.ListTemp = this.FilterSearch.transform(this.apprenticeDeatils,event,'');
  //   this.ListTemp.forEach((y, i) => {
  //     y['aindex'] = i + 1;
  //   })
  //   this.isNoApprentice = ( this.ListTemp.length ) ? false : true;
  // }

  progressDetails = [];
  overAllProgress:any ;
  selectStudentName = '';
  courseName = '';
  async getProgession( data : any ){
    this.isLoading = true;
    this.popupopen = 'block';
    this.courseName = '';
    let url = APP_URLS.v1.getMyApprentice + '?target=progression&courseApplicationId=' + data._id;
    await this.service.getCallWithHeaders(url).subscribe((res: any) => {
      if (res.overAllProgress) {
        this.courseName = (data.courseAs[0] ) ? data.courseAs[0].name : '';
        this.overAllProgress = res.overAllProgress !== undefined ? res.overAllProgress: {} ;
        this.progressDetails = res.progressRes?.length > 0 ? res.progressRes : [];
        this.isLoading = false;
      }
    })

  }

  selectedApprenticeData:any;
  onChangeFun( studentID : any ){
    this.selectedApprenticeData = [];
    this.apprenticeDeatils.forEach((y, i) => {
      if( y._id.student == studentID ){
        this.selectedApprenticeData = y.applications;
      }
    })
  }

  popupClose() {
    this.popupopen = 'none'
  }

  studentList = [];
  getApprenticeDetails() {

    let url = APP_URLS.v1.getMyApprentice + '?target=courseApplicationList';
    this.service.getCallWithHeaders(url).subscribe((res: any) => {
      if (res.courseApplicationList) {
        this.apprenticeDeatils = res.courseApplicationList;
        this.studentList = res.myUserList;
        // this.apprenticeDeatils.sort((a, b) => {
        //   return <any>new Date(b.created) - <any>new Date(a.created);
        // });
        this.apprenticeDeatils.forEach((y, i) => {
          y['aindex'] = i + 1;
        })
        // this.ListTemp = JSON.parse(JSON.stringify(this.apprenticeDeatils));
        // this.isNoApprentice = (this.ListTemp.length ) ? false : true; //need to check if agreement is available/not
      }
    })
  }

}
