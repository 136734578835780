import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import {MyProfileService} from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
import { ModaldialogComponent } from '../../pages/modaldialog/modaldialog.component'
import { CommonService } from '../../services/common.service'
import { TenantId } from '../../Models/url';
import { from } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Input('header') isHeader = 'header' ;
  @Input() emailId = '';

  @ViewChild('sf') studentform: any;
  @ViewChild('ef') employerform: any;

  Login = {
    email: this.emailId || '',
    password: '',
    role : ''
  }
  activeTab = 'student';
  courseid = "";
  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private router: Router,
    private modalCom: ModaldialogComponent,
    private route: ActivatedRoute,
    public service: CourseService,
    private userSvc: UserService,
    private toastr: ToastrService,
    private profileSvc:MyProfileService,
    private cms: CommonService) {
      this.courseid = this.service.courceid;
     }

  ngOnInit(): void {

  }

  selectTab( currentTab : string ){

    switch (currentTab) {
      case 'employer':
        this.activeTab = 'employer';
        this.employerform.resetForm();
      break;

      default:
        this.activeTab = 'student';
        this.studentform.resetForm();
    }
  }

  getDropdowns() {
		let url = APP_URLS.v1.settingsData;
		this.service.getCall(url).subscribe((res: any) => {
      localStorage.setItem(
        "settingsData",
        JSON.stringify(res)
      );
		});
	}

  getProfileDetails() {

		let url = APP_URLS.v1.userDetails + "?apifrom=profile_summary";
		this.profileSvc.getCallWithHeaders(url).subscribe(
			(res: any) => {
				localStorage.setItem(
					"profilesummaryData",
					JSON.stringify(res.userDetails)
				);
        this.userSvc.setIsLogin(true)
          if( this.activeTab == 'student'){
            if(this.isHeader === 'checkout'){
              this.router.navigate(['checkout']);
              window.location.reload();
            }else{
              if(this.service.courceid != "" && this.service.courceid != undefined){
                this.router.navigate(['/mytraining']);
              }
              else{
                this.router.navigate(['/profile']);
              }
            }
          } else {
            this.router.navigate(['/employer-profile']);
          }
          this.getDropdowns();
			},
			(err) => {
				//this.cms.loaderSet(false);
			}
		);
	}

  login() {
    // this.cms.loaderSet(true);
    if(this.Login.email !="" && this.Login.password !="" && this.activeTab !=""){
    let url = APP_URLS.v1.login;
    let payload = {
      email: this.Login.email,
      password: this.Login.password,
      tenantId: TenantId,
      role: this.activeTab
    }
    this.userSvc.postCall(url, payload).subscribe(
      (res: any) => {
        if (res.respCode == 200 || res.respCode == 201 || res.respCode == 204) {
          this.userSvc.showSuccess(res.respMessage);
          localStorage.setItem('token', res.userDetails.access_token)
          localStorage.setItem('userDetails',JSON.stringify(res.userDetails));
          localStorage.setItem('role', this.activeTab);
	  this.getProfileDetails()
          this.modalCom.close();
          // this.cms.loaderSet(false);
        }
        else {
          // this.cms.loaderSet(false);
          this.userSvc.showError(res.errorMessage)
        }
      },
      err => {
        // this.cms.loaderSet(false);
        this.userSvc.showError('Login Failed')
      })
    }
  }

  forgotpwd(){
    this.profileSvc.setForgotPop('forgot')

  }
  registerpopup(){
    this.profileSvc.setForgotPop('register')
  }

}
