import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-qualifications',
  templateUrl: './qualifications.component.html',
  styleUrls: ['./qualifications.component.css']
})
export class QualificationsComponent implements OnInit {
  tenantInfo:any = tenantInfo;
  p = 1;
  countPagination = 10;
  showDialogQua:boolean=false
  studentId: any;
  QualificationData=[];
  isNoQualificationData:boolean = false;
  isLoading:boolean = true;
  arrayData=[];

  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  constructor(private profileSvc: MyProfileService,private toast:UserService) { }

  ngOnInit(): void {
    this.profileSvc.setTab({ tabName : "Qualification", isRefreshPermission : false })
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id;
    this.getQualification();

  }
  changePage(e){
    if(!e){
      e=1
    }
    this.p=e;
  }
  parentMethod(data){
    if(data){
      this.QualificationData=[];
      this.isNoQualificationData = false;
      this.showDialogQua = false;
      this.getQualification();
    }
  }


  getQualification(){
    this.isLoading = true;
    let url=APP_URLS.getQualificationandEmployementandPersonl+`{"key":"attributes.context_id","value":"`+this.studentId+`","type":"eq"}]}`
    this.profileSvc.getCall(url)
    .subscribe((data:any)=>{
      this.arrayData=data.events;
      this.arrayData.forEach(y => {
        if(y.attributes.formname=="qualification"){
          this.QualificationData.push(y.attributes);
        }
      });
      this.isLoading = false;
      this.isNoQualificationData = this.QualificationData.length ? false : true;
    })
  }

}
