import { Component, OnInit ,OnDestroy,Input} from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { APP_URLS } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-selectcoursecarousel',
  templateUrl: './selectcoursecarousel.component.html',
  styleUrls: ['./selectcoursecarousel.component.css']
})
export class SelectcoursecarouselComponent implements OnInit {
  @Input() selectCourse: any;
  selectCourseIdData: any;

  constructor(private router:Router,
    private route : ActivatedRoute,
    public service: CourseService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
   

  }

  

}
