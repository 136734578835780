import { Component, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { CommonService } from './services/common.service'
import { fromEvent, Observable, Subscription } from 'rxjs';
import { tenantInfo } from './Models/url';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit , OnDestroy {
  loader = false;
  name = 'Angular2 Cookie Law on Angular 7';
  cookieLawSeen: boolean=false
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  tenantInfo: any = tenantInfo;

  connectionStatusMessage:any;
  connectionStatus:any;
  errorpage = "";
  
  constructor(
    private cms: CommonService) {
      this.errorpage =  window.location.pathname;

  }
  @ViewChild('cookieLaw')
  cookieLawEl: any;
  ngOnInit(): void {
    this.cms.LoaderGet().subscribe((status: any) => {
      this.loader = status.status;
    
    })
    
   this.onlineEvent = fromEvent(window, 'online');
   this.offlineEvent = fromEvent(window, 'offline');
   this.subscriptions.push(this.onlineEvent.subscribe(e => {
     this.connectionStatusMessage = 'Back to online';
     this.connectionStatus = 'online';
    
   }));

   this.subscriptions.push(this.offlineEvent.subscribe(e => {
     this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
     this.connectionStatus = 'offline';
   }));

  }
  ngOnDestroy(): void {
   
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
  public seen(evt: any) {
    this.cookieLawSeen = evt;
  }
  dismiss(): void {   
    this.cookieLawEl.dismiss()
  }
}
