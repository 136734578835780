import { Component, OnInit,ViewChild,ElementRef  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APP_URLS, tenantInfo } from '../../Models/url';
import { CourseService } from '../../services/course.service';
import { UserService } from '../../services/user.service';
import { MyProfileService } from '../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-personal-statement',
  templateUrl: './personal-statement.component.html',
  styleUrls: ['./personal-statement.component.css']
})
export class PersonalStatementComponent implements OnInit {
  tenantInfo:any = tenantInfo;
  p = 1;
  countPagination = 10;
  @ViewChild('takeInputper', {static: false})

  InputVar: ElementRef; 
  fileToUpload: any;
  fileName: any;
  fileSize: any;
  studentId: any;
  personalStatment={
    description : "",
    is_sop:false
  }
  waitingFlag:boolean=false;
  isLoading:boolean = true;

    
  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;
  PersonalStatements = [];
  isNoPersonalStmnt: boolean = false;

  showDialogPer: boolean = false;
  constructor(private profileSvc: MyProfileService, private toast: UserService) { }

  ngOnInit(): void {
    this.profileSvc.setTab({ tabName : "Personal", isRefreshPermission : false })
    this.profileSvc.getPopup().subscribe(data=>{
      this.PersonalStatements=[];
      this.isNoPersonalStmnt = false;
      this.getProfileDetails();
    })
    this.studentId = JSON.parse(localStorage.getItem('userDetails'))._id
    this.getProfileDetails();
  }

  isUploadDoc(){
    if( this.personalStatment.is_sop ){
      this.personalStatment.description = '';
    } else {
      this.fileName = '';
    }
  }
  changePage(e){
    if(!e){
      e=1;
    }
    this.p=e;
  }

  reset(){ 
    if(this.InputVar){
      if(this.InputVar.nativeElement){
        this.InputVar.nativeElement.value = ""; 
      }
    }

    this.personalStatment = {
      description : "",
      is_sop:false
    }
  } 

  handleFileInput(files) {
    this.waitingFlag=true
    this.fileSize = files[0].size
    const formData = new FormData();
    
    formData.append('images', files[0]);
    var url = APP_URLS.fileUpload + '&frmSrc=per_stmnt'
    this.profileSvc.postCallImg(url, formData)
      .subscribe((data: any) => {
        this.waitingFlag=false
        if(data.errorMessage){
          this.toast.showError(data.errorMessage)
        } else if(data.message){
          this.fileName=""
          this.toast.showError(data.message)
        } else {
          if( data.length )
            this.fileName = data[0].name
          this.toast.showSuccess('Your chosen file is attached successfully. Now click on add or add & next button')
        }
         
      }, err => {
        this.waitingFlag=false
        this.fileName=""
        this.toast.showError(err.errorMessage)
      })
  }

  payload: any;

 async add( source:any) {

    let url = APP_URLS.profileDetails + this.studentId;

    if (this.personalStatment.is_sop == true) {
      if(this.fileName == undefined || this.fileName == ""){
        this.toast.showError('Please select proper Document');
        return;
      }
      this.payload = {
        personal_statements: [
          {
            created_from: "Website",
            description: "",
            is_sop: this.personalStatment.is_sop,
            operation: 0,
            sop_attachment: this.fileName,
            statement: "SD"
          }
        ]
      };
    }

    else if (this.personalStatment.is_sop == false) {
      if(this.personalStatment.description == ""){
        this.toast.showError('Please Enter Description');
        return;
      }
      this.payload = {
        personal_statements: [
          {
            created_from: "Website",
            description:this.personalStatment.description,
            operation: 0,
            statement: "SD"
          }
        ]
      };
    }
   await this.profileSvc.putCall(url, this.payload)
      .subscribe((data: any) => {
        if (data.respCode) {
          if (this.personalStatment.is_sop == false){
            this.toast.showSuccess('Your personal statement is added succcessfully');
          } else {
            this.toast.showSuccess('Your uploaded personal statement is added successfully');
          }
          if( source != 'next')
            this.profileSvc.setTab({ tabName : "Personal", isRefreshPermission : true })
            
          this.getProfileDetails();
          this.fileName=""
          this.reset();
        }
        if (data.errorCode) {
          this.toast.showError('Try Again')
        }
      }, err => {
        this.toast.showError(err.errorMessage) 
      })
  }

  next(){
    this.add('next');
    this.profileSvc.setTab({ tabName : "Additional", isRefreshPermission : true })
  }

  getProfileDetails() {
    this.isLoading = true;
    let url = APP_URLS.profileDetails+this.studentId;
    this.profileSvc.getCallWithHeaders(url).subscribe((res: any) => {
      this.PersonalStatements = res.details.personal_statements;
      this.PersonalStatements.sort((a, b) => {
        return <any>new Date(b.created) - <any>new Date(a.created);
      });
      this.isLoading = false;
      this.isNoPersonalStmnt = this.PersonalStatements.length ? false : true;
      this.showDialogPer = false;
      this.reset();
    })
  }

}



