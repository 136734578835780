import { Component, OnInit, OnDestroy,ViewChild,ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { APP_URLS } from '../../../Models/url';
import { CourseService } from '../../../services/course.service';
import { UserService } from '../../../services/user.service';
import { MyProfileService } from '../../../services/my-profile.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-offthejob-modal',
  templateUrl: './add-offthejob-modal.component.html',
  styleUrls: ['./add-offthejob-modal.component.css']
})
export class AddOffthejobModalComponent implements OnInit {
  @ViewChild('f', {static: true}) f: NgForm; 
  @ViewChild('takeComposeInput', {static: false})

  InputVar: ElementRef;
  fileSize: any;
  fileName: any;
  SubjectCategeory = [
    { id: 1, value: 'Attendance at College/Centre' },
    { id: 2, value: 'Learning activity/Assessment(Project)' },
    { id: 3, value: "Behaviour and Skills Workshops" },
    { id: 4, value: 'Competition' },
    { id: 5, value: 'In-house CPD' },
    { id: 6, value: 'Mentoring' },
    { id: 7, value: 'Online Learning' },
    { id: 8, value: 'Reflective Account' },
    { id: 9, value: 'Research online /offline for the projects' },
    { id: 10, value: 'Skype/ Conference Call/ Zoom' },
    { id: 11, value: 'Synoptic Project' },
    { id: 12, value: "Work Shadowing" },
    { id: 13, value: "Register Session" },
  ]

  Training = [
    { id: 1, value: 'Main Provider' },
    { id: 2, value: 'Training Provider' },
    { id: 3, value: "Employer" },
    { id: 4, value: 'All Parties' },
    { id: 5, value: 'External' }

  ]

  offthejobsObj = {
    subcategory: "",
    category: "",
    attachment: "",
    hours: "",
    minutes: "",
    start_date: "",
    start_time: "",
    student:"",
    whatislearned:""
  }
  studentId: any;
  constructor(private profileSvc: MyProfileService,
    private courseSvc: CourseService,
    private toast: UserService) { 
    
    }

  ngOnInit(): void {
    this.studentId=JSON.parse(localStorage.getItem('userDetails'))._id;
    
    
  }
  reset()  
  { 
    this.f.resetForm()
    this.InputVar.nativeElement.value = ""; 
  }
  handleFileInput(files) {
    this.fileSize = files[0].size
    const formData = new FormData();
    formData.append('images', files[0]);
    this.profileSvc.postCallImg(APP_URLS.fileUpload, formData)
      .subscribe((data: any) => {
        if(data.errorMessage){
          this.toast.showError(data.errorMessage)
        } else {
          if( data.length )
            this.fileName = data[0].name;
          this.toast.showSuccess('Success')
        }
        
      }, err => {
        this.toast.showError('Error')
      })
  }

  submitOffthejobs(){
    
    let url=APP_URLS.postOffTheJobs
    this.offthejobsObj.attachment=this.fileName;
    this.offthejobsObj.student=this.studentId;
    this.courseSvc.postCallwithHeaders(url,this.offthejobsObj)
    .subscribe((data:any)=>{        
      this.toast.showSuccess('Success')     
      this.profileSvc.setPopup(false);  
      this.reset();  
    },err=>{
    
      this.toast.showError('Error')
    })
    
  }

  keyPress(event: any) {
     const pattern = /[0-9\+\:\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();

    }       
  }
}
