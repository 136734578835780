import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_URLS } from 'src/app/Models/url';
import { MyProfileService } from 'src/app/services/my-profile.service';
import { UserService } from 'src/app/services/user.service';


interface KYC_Details{
  account_name: string;
  account_number: string;
  active: boolean;
  address: string;
  bank_name: string;
  created: string;
  idproof: string;
  ifsc_code: string;
  notes: string;
  passbook: string;
  status: string;
  student_id: string;
  _id: string;
}
@Component({
  selector: 'app-student-kyc',
  templateUrl: './student-kyc.component.html',
  styleUrls: ['./student-kyc.component.css']
})
export class StudentKycComponent implements OnInit {
  isLoading = true;
  isSubmitted = false;
  kycDetails: KYC_Details;
  isKYCSubmit = false;
  kycForm: FormGroup;
  idProof: any;
  passbook:any;
  isAadharUpload = false;
  isPbUpload = false;

  FILE_PATH_LINODE = APP_URLS.FILE_PATH_LINODE;

  get kycControls(){
    return this.kycForm.controls;
  }

  constructor(public service: MyProfileService, private fb: FormBuilder, private profileSvc: MyProfileService, private toast: UserService) {
    this.kycForm = this.fb.group({
      bankname: this.fb.control('', Validators.required),
      accountname: this.fb.control('', Validators.required),
      accountnumber: this.fb.control('', Validators.required),
      ifsccode: this.fb.control('', Validators.required),
      passbook: this.fb.control('', Validators.required),
      idProof: this.fb.control('', Validators.required),
      address: this.fb.control('', Validators.required),
      notes: this.fb.control(''),
    })
  }

  ngOnInit(): void {
    this.getKYCDetails();
  }

  getKYCDetails() {
    this.isLoading = true;

    let url = APP_URLS.v1.kyc;
    this.service.getCallWithHeaders(url)
    .subscribe((res: any) => {
      if (res.respCode === 202){
        this.isLoading = false;
        if(res.findRes === null){
          this.isKYCSubmit = false;
          return;
        }
        this.kycDetails = res.findRes;
        this.isKYCSubmit = true;
      }

    });
  }

  submit(): void{
    this.isSubmitted = true;
    const data = {
      bank_name: this.kycForm.get('bankname').value,
      account_name: this.kycForm.get('accountname').value,
      account_number: this.kycForm.get('accountnumber').value,
      ifsc_code: this.kycForm.get('ifsccode').value,
      passbook: this.kycForm.get('passbook').value,
      idproof: this.kycForm.get('idProof').value,
      address: this.kycForm.get('address').value,
      notes: this.kycForm.get('notes').value
    }

    if(this.kycForm.invalid){
      return
    }

    let url = APP_URLS.v1.kycSubmit;
    this.service.postCallwithHeaders(url, data)
    .subscribe((res: any) => {
      if(res.alertType === "success"){
        this.toast.showSuccess(res.alertMessage)
        this.isKYCSubmit = true;
        this.isSubmitted = false;
        this.kycDetails = res.kycData;
        this.kycForm.reset();
        document.getElementById('clear').click();
      }
    });


  }

  passbookFileChange(e:any){
    const formData = new FormData();
    formData.append('images', e.target.files[0]);
    this.isPbUpload = true
    this.profileSvc.postCallImg(APP_URLS.attachmentUrl + '?from=my_work_kyc_passbook', formData)
      .subscribe((data: any) => {
        if(data.errorMessage){
          this.toast.showError(data.errorMessage)
        } else {
          if( data.length )
            this.passbook = data[0].name;
            this.kycForm.get('passbook').setValue(this.passbook);
          this.toast.showSuccess('Your chosen file is attached successfully. Now click on submit button')
          this.isPbUpload = false;
        }
      }, err => {
        this.toast.showError(err.errorMessage);
        this.kycForm.get('passbook').setValue('');
      })
  }

  idProofFileChange(e:any){
    const formData = new FormData();
    formData.append('images', e.target.files[0]);
    this.isAadharUpload = true;
    this.profileSvc.postCallImg(APP_URLS.attachmentUrl + '?from=my_work_kyc_idproof', formData)
      .subscribe((data: any) => {
        if(data.errorMessage){
          this.toast.showError(data.errorMessage)
        } else {
          if( data.length )
            this.idProof = data[0].name;
            this.kycForm.get('idProof').setValue(this.idProof);
          this.toast.showSuccess('Your chosen file is attached successfully. Now click on submit button')
          this.isAadharUpload = false;
        }
      }, err => {
        this.toast.showError(err.errorMessage)
        this.kycForm.get('idProof').setValue('');
      })
  }


  clear(): void{
    this.isSubmitted = false;
    this.kycForm.reset();
  }
}
